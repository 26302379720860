// header, sidebarより内側のコンテンツのテンプレート

import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  deleteNotification,
  resetDeleteNotification,
  startBatchProccessedDate,
  hideCommonDialog,
  actions as uiConfigActions,
} from '../../../modules/uiConfig';
import { getIpadWidth, isBatchFailed } from '../../../helpers/util';
import { verylightgray } from '../../../constants/colors';
import { sideNavWidth } from '../../../constants/size';
import Info from '../../../icons/blueInfo.svg';
import { State as ReduxState } from '../../../modules';
import { CommonDialogSetting, CommonToast, CommonLoading } from '../../../modules/uiConfig';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import Toast from '../molecules/Airkit/AirToast';
import Dialog from '../molecules/Airkit/AirDialog';
import ActivityIndicatorStatic from '../../../components/common/ActivityIndicatorStatic';
import SideNav from '../SideNav';
import { Logger } from '../../../typedef/logger';
import { startFetchUnreadCountData } from '../../../modules/dailyReport/unreadCount';
import { actions as NoticeAndTodoActions } from '../../../modules/noticeAndTodo';
import { unreadCountSelector } from '../../../selectors/dailyReportListSelector';
import { StoresData } from '../../../modules/user';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import { TUTORIAL_GOAL_STARTED_ID } from '../../../constants/LocalStorage';
import { MclDayjs, mclDayjs, formatter } from '../../../helpers/mclDate';
import { BatchStatusResponse } from '../../../typedef/api/Batch';
import { ApiState, API_STATE_COMPLETED } from '../../../typedef/api/Utility';

type StateProps = {
  readonly batchStartTime: string | null | undefined;
  readonly batchFinishTime: string | null | undefined;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly batchStatus: ApiState<BatchStatusResponse>;
  readonly didDeleteNotification: boolean;
  readonly commonToast: CommonToast;
  readonly commonDialog: { readonly isShowCommonDialog: boolean } & CommonDialogSetting;
  readonly commonLoading: CommonLoading;
  readonly dailyReportUnreadCount: number;
  readonly stores: ReadonlyArray<StoresData>;
  readonly isSideNavFold: boolean;
};

type PassedProps = {
  readonly children: React.ReactNode;
  readonly showSidebar: boolean;
  readonly pathName: string;
  readonly deleteNotification: () => unknown;
  readonly resetDeleteNotification: () => unknown;
  readonly startBatchProccessedDate: () => unknown;
  readonly startFetchUnreadCountData: typeof startFetchUnreadCountData;
  readonly logging: (logger: Logger) => void;
  readonly hideCommonDialog: typeof hideCommonDialog;
  readonly updateOnboardingIdList: typeof NoticeAndTodoActions.updateOnboardingIdList;
  readonly setLoadedTutorial: typeof NoticeAndTodoActions.setLoadedTutorial;
  readonly className?: string;
  readonly isWithToolbar?: boolean;
  readonly statusBar?: {
    readonly hide?: boolean;
    readonly oneday?: boolean;
  };
  readonly hideScrollBar: boolean;
  readonly changeSideNavFold: typeof uiConfigActions.changeSideNavFold;
};

type State = {
  now: MclDayjs;
};

interface Props extends StateProps, PassedProps {}

class BodyWrapper extends React.PureComponent<Props, State> {
  state = {
    now: mclDayjs(),
  };

  componentDidMount() {
    const {
      resetDeleteNotification,
      startBatchProccessedDate,
      startFetchUnreadCountData,
      changeSideNavFold,
    } = this.props;
    resetDeleteNotification();
    startBatchProccessedDate();
    startFetchUnreadCountData();
    if (window.innerWidth <= getIpadWidth() && this.props.pathName === 'monthly_lookback') {
      changeSideNavFold(true);
    }
  }

  // オンスタの関数がglobalでactionを呼べないため非表示ボタン・localStorage経由で実装
  _handleClickTutorialInit = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    this.props.setLoadedTutorial();
    this.props.updateOnboardingIdList();
  };

  // オンスタの関数がglobalでactionを呼べないため非表示ボタン・localStorage経由で実装
  _handleClickTutorialGoalStarted = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation();
    const goalId = localStorage.getItem(TUTORIAL_GOAL_STARTED_ID);
    if (goalId != null) {
      this.props.updateOnboardingIdList(goalId);
    }
  };

  render() {
    const {
      children,
      showSidebar,
      batchProcessedDate,
      batchFinishTime,
      className,
      statusBar,
      commonToast,
      pathName,
      commonDialog,
      commonLoading,
      hideCommonDialog,
      hideScrollBar,
      dailyReportUnreadCount,
      stores,
      isSideNavFold,
      changeSideNavFold,
      batchStatus,
    } = this.props;
    const isOESActive = stores.some(store => store.isHandyActive);
    const showOESAlertPaths = ['store', 'all_index', 'daily_report_list', 'labor_cost_analysis'];
    return (
      <StyledBodyWrapper showSidebar={showSidebar} isNoScroll={pathName === 'year_lookback2023'}>
        {showSidebar && (
          <SideNav
            path={pathName}
            folded={isSideNavFold}
            logging={this.props.logging}
            changeSideNavFold={changeSideNavFold}
            dailyReportUnreadCount={dailyReportUnreadCount}
            stores={stores}
          />
        )}
        <StyledMainContent isSidebarFolded={isSideNavFold} className={className} showSidebar={showSidebar}>
          {batchFinishTime &&
          (!statusBar || !statusBar.hide) &&
          isBatchFailed(batchFinishTime, this.state.now) ? (
            // リアルタイム画面では現在時刻を利用するので混乱を避けるためバッチ遅れエラーは出さない
            pathName !== 'realtime' && (
              <ErrorStatusBar isSidebarFolded={isSideNavFold}>
                <Info />
                <Text>
                  {batchProcessedDate.month}/{batchProcessedDate.day}
                  {(!statusBar || !statusBar.oneday) && 'まで'}
                  のデータを表示しています。最新のデータ更新までしばらくお待ち下さい。
                </Text>
              </ErrorStatusBar>
            )
          ) : (
            <React.Fragment>
              {batchStatus.type === API_STATE_COMPLETED &&
                !batchStatus.payload.batchStatus.oes.isCompleted &&
                isOESActive &&
                showOESAlertPaths.some(path => path === pathName) && (
                  // OES連携完了フラグがfalse かつ OES利用がある場合、OESとのデータ連携遅延アラートを表出する(既存のデータ連携優先)
                  <ErrorStatusBar isSidebarFolded={isSideNavFold}>
                    <Info />
                    <Text>
                      {`「Airレジ オーダー」からのデータ連携に遅れが発生し、${mclDayjs(
                        batchStatus.payload.batchStatus.oes.latestDate
                      )
                        .add(1, 'day') // 最後にデータ連携された日に+1する
                        .format(
                          formatter.monthDay
                        )}以降のデータが反映されていません。更新までしばらくお待ちください。`}
                    </Text>
                  </ErrorStatusBar>
                )}
            </React.Fragment>
          )}
          <Container
            isBatchFailed={batchFinishTime && isBatchFailed(batchFinishTime, this.state.now)}
            isSidebarFolded={isSideNavFold}
            showSidebar={showSidebar}
            id="scrollable"
            hideScrollBar={hideScrollBar}
            pathName={pathName}
          >
            {children}
          </Container>
          {commonToast.isShowCommonToast && <Toast message={commonToast.message} />}
          {commonDialog.isShowCommonDialog && (
            <Dialog
              title={commonDialog.title}
              onClose={() => hideCommonDialog()}
              actions={commonDialog.actions}
            >
              {commonDialog.message}
            </Dialog>
          )}
          {commonLoading.isLoading && (
            <ActivityIndicatorStatic bgColor={commonLoading.bgColor} zIndex={commonLoading.zIndex} />
          )}
        </StyledMainContent>
        {/* オンスタの関数がglobalでactionを呼べないため、非表示ボタンを設置してオンスタからclickさせる */}
        <div id="tutorial_init" onClick={this._handleClickTutorialInit} style={{ display: 'none' }} />
        <div
          id="tutorial_goal_started"
          onClick={this._handleClickTutorialGoalStarted}
          style={{ display: 'none' }}
        />
      </StyledBodyWrapper>
    );
  }
}

const StyledBodyWrapper = styled.div<{ showSidebar: boolean; isNoScroll: boolean }>`
  display: flex;
  height: calc(100% - ${props => (props.showSidebar ? '81' : '0')}px);
  width: 100%;
  ${props => props.isNoScroll && 'overflow: hidden;'}
`;

const StyledMainContent = styled.div<{ isSidebarFolded: boolean; showSidebar: boolean }>`
  width: ${props =>
    !props.showSidebar
      ? '100%;'
      : `calc(100% - ${props.isSidebarFolded ? sideNavWidth.fold : sideNavWidth.global}px)`};
  height: 100%;
  position: relative;
  transition: width 0.3s ease 0s;
  ${props => props.showSidebar && 'overflow-x: scroll;'}
`;

const ErrorStatusBar = styled.div<{ isSidebarFolded: boolean }>`
  height: 40px;
  width: 100%;
  background-color: ${verylightgray};
  display: flex;
  padding: 12px 16px;
  align-items: center;
`;

const Container = styled.div<{
  isBatchFailed: boolean | '' | null | undefined;
  isSidebarFolded: boolean;
  showSidebar: boolean;
  hideScrollBar: boolean;
  pathName: string;
}>`
  height: ${props => (props.isBatchFailed && props.pathName !== 'realtime' ? 'calc(100% - 40px)' : '100%')};
  min-width: calc(1024px - ${props => (props.isSidebarFolded ? sideNavWidth.fold : sideNavWidth.global)}px);
  ${props => props.showSidebar && 'overflow-y: scroll;'}
  /* ScrollBar 表示切り替え */
  ${props => props.hideScrollBar && props.showSidebar && '-ms-overflow-style: none; scrollbar-width: none;'}
  ::-webkit-scrollbar {
    ${props => props.hideScrollBar && props.showSidebar && 'display: none;'}
  }
  position: relative;
`;

const Text = styled.p`
  margin-left: 12px;
  margin-right: 12px;
  font-size: 12px;
  line-height: 1.4;
`;

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    batchStartTime: state.uiConfig.batchProcessLastStartDatetime,
    batchFinishTime: state.uiConfig.batchProcessLastFinishDatetime,
    batchProcessedDate: state.uiConfig.batchProcessedDate,
    batchStatus: state.uiConfig.batchStatus,
    didDeleteNotification: state.uiConfig.didDeleteNotification,
    commonToast: state.uiConfig.commonToast,
    commonDialog: state.uiConfig.commonDialog,
    commonLoading: state.uiConfig.commonLoading,
    dailyReportUnreadCount: unreadCountSelector(state),
    stores: assignedStoresSelector(state),
    isSideNavFold: state.uiConfig.isSideNavFold,
  };
};

export default connect(mapStateToProps, {
  deleteNotification,
  resetDeleteNotification,
  startBatchProccessedDate,
  hideCommonDialog,
  startFetchUnreadCountData,
  updateOnboardingIdList: NoticeAndTodoActions.updateOnboardingIdList,
  setLoadedTutorial: NoticeAndTodoActions.setLoadedTutorial,
  changeSideNavFold: uiConfigActions.changeSideNavFold,
})(BodyWrapper);
