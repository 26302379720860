// 画面名：商品分析
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators, Action } from 'redux';
import { Dispatch } from 'redux';
import { Waypoint } from 'react-waypoint';
import MenuSearchForm from './MenuSearchForm';
import MenuTableTopArea from './MenuTableTopArea';
import MenuTable from './MenuTable';
import { ActivityIndicator } from '../../../components/common';
import TitleHeader from '../../../components/common/TitleHeader';
import Templates from '../../../components/common/templates';
import Button from '../../../components/common/molecules/Airkit/AirButton';
import { ErrorCommon as Error, ERROR_TYPE_FAILED } from '../../../components/common/templates/ErrorCommon';
import { AppealModal } from '../../../components/common/appealModal/AppealModal';
import { track } from '../../../modules/logging';
import { genGaLog } from '../../../gaLogger';
import { Logger } from '../../../typedef/logger';
import { State as ReduxState } from '../../../modules';
import { StoresData } from '../../../modules/user';
import { State as SearchState, setTableProperties, initialSearchMenu } from '../../../modules/allMenu/search';

import { State as SummaryState } from '../../../modules/allMenu/summary';
import { State as CategoriesState } from '../../../modules/allMenu/categories';
import { menuFaq } from '../../../constants/faqUrls';
import { returnCodes } from '../../../constants/mapi';
import { getReturnCode } from '../../../helpers/util';
import { getCookie } from '../../../helpers/cookieHelper';
import { BadgeAcquiredInfo } from '../../../typedef/api/Badge';
import { notifyAcquireBadge } from '../../../helpers/onboardingHelper';
import { actions as badgeListactions } from '../../../modules/badge';
import { BADGE_TYPE } from '../../../constants/onboarding';
import { MenuCategoryDetailResponse, MenuDetailResponse, SelectedType } from '../../../typedef/api/AllMenu';
import { actions as commonUiActions } from '../../../modules/uiConfig';
import { BADGE_CHECKED_INFO_KEY_NAME } from '../../../constants/LocalStorage';
import MenuAnalysisSample from '../../../icons/menuAnalysisSample.png';
import { airRegiTop } from '../../../constants/externalLink';
import { MENU_ANALYSIS_REGI_MODAL_BTN_LID_PARAMETER } from '../../../constants/externalLinkParameter';
import { CROSSSELL_PRODUCT_TYPE } from '../../../constants/crossSellProduct';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import { FROM, FROM_VALUE_FOR_MENU_ANALYSIS } from '../../../constants/requestParameter';

import { changeSelectedType } from '../../../../src/modules/allMenu/ui';

type DispatchProps = {
  readonly logger: typeof track;
  readonly setTableProperties: typeof setTableProperties;
  readonly postBadgeList: typeof badgeListactions.postBadgeList;
  readonly unCheckedBadge: typeof commonUiActions.unCheckedBadge;
  readonly initialSearchMenu: typeof initialSearchMenu;
  readonly changeSelectedType: typeof changeSelectedType;
};

type StateProps = {
  readonly summary: SummaryState;
  readonly search: SearchState;
  readonly categories: CategoriesState;
  readonly lastUpdateDate?: string | null;
  readonly badgeAcuiredInfo: BadgeAcquiredInfo | null;
  readonly tutorialLoadingFlag: boolean;
  readonly stores: ReadonlyArray<StoresData>;
  readonly selectedTypeState: SelectedType;
};

type State = {
  isShowModal: boolean;
  buttonText?: string;
  url?: string;
  isBadgeAcquiredFlag: boolean;
  tempSelectedItem: Set<string>;
};

type Props = Readonly<{} & DispatchProps & StateProps>;

class AllMenu extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowModal: false,
      isBadgeAcquiredFlag: false,
      tempSelectedItem: new Set(),
    };
  }

  componentDidMount() {
    const { changeSelectedType } = this.props;
    const log = _genComponentDidMountLog();
    this.props.logger(log);
    this.props.initialSearchMenu();

    /**
     * 以下の優先度で商品別 / カテゴリー別のタブの初期値を決定する
     * 1. クエリパラメータfromに特定の値が付与されている場合、カテゴリー別を初期表示にする
     * 2. localStorageにトグルの選択フラグがあればstateを更新する
     * */
    const url: URLSearchParams = new URLSearchParams(window.location.search);
    const fromParam: string | null = url.get(FROM);
    const selectedTypeForMenuAnalysis = localStorage.getItem('selectedTypeForMenuAnalysis');
    if (fromParam != null && Object.values(FROM_VALUE_FOR_MENU_ANALYSIS).includes(fromParam)) {
      changeSelectedType('category');
    } else if (
      selectedTypeForMenuAnalysis != null &&
      (selectedTypeForMenuAnalysis === 'menu' || selectedTypeForMenuAnalysis === 'category')
    ) {
      changeSelectedType(selectedTypeForMenuAnalysis);
    }
  }

  componentDidUpdate() {
    const {
      search,
      badgeAcuiredInfo,
      postBadgeList,
      unCheckedBadge,
      tutorialLoadingFlag,
      selectedTypeState,
    } = this.props;
    // オンスタ読み込み後にバッジ獲得処理
    if (!tutorialLoadingFlag && search.loadStatus.type === 'loaded') {
      this._handleMenuTableLoaded(
        badgeAcuiredInfo,
        postBadgeList,
        selectedTypeState !== 'category'
          ? search.loadStatus.payload.totalizationResults
          : search.loadStatus.payload.totalizationResultsByCategory,
        unCheckedBadge
      );
    }
  }

  _handleStorageLoaded = () => {
    // 未読フラグ(バッジ獲得済み かつ バッジ獲得一覧画面を見ていない)をローカルストレージに格納する
    const storage = localStorage.getItem(BADGE_CHECKED_INFO_KEY_NAME);
    if (storage != null) {
      const storageList = JSON.parse(storage);
      const unCheckedBadgeList = storageList.map(item => {
        if (item.badgeType === BADGE_TYPE.menuAnalysis.key) {
          return {
            badgeType: item.badgeType,
            isBadgeAcquired: true,
            isBadgeChecked: false,
          };
        } else {
          return item;
        }
      });
      localStorage.setItem(BADGE_CHECKED_INFO_KEY_NAME, JSON.stringify(unCheckedBadgeList));
    }
  };

  _setTempSelectedItem = items => {
    this.setState(() => ({ tempSelectedItem: new Set(items) }));
  };

  _handleMenuTableLoaded(
    badgeAcuiredInfo: BadgeAcquiredInfo | null,
    postBadgeList: typeof badgeListactions.postBadgeList,
    menuData: readonly MenuDetailResponse[] | readonly MenuCategoryDetailResponse[],
    unCheckedBadge: () => void
  ) {
    const isEmpty = menuData.length < 1; // 商品データが空かどうか判定
    const menuAnalysisInfo = badgeAcuiredInfo?.badgeAcquiredInfo.badgeList.find(
      item => item.badgeType === BADGE_TYPE.menuAnalysis.key
    );
    const { isBadgeAcquiredFlag } = this.state;
    // バッジ未獲得 かつ　商品データ1件以上 かつ バッジ獲得フラグが立っていない場合バッジ獲得処理
    if (!menuAnalysisInfo?.isBadgeAcquired && !isEmpty && !isBadgeAcquiredFlag) {
      this.setState({ isBadgeAcquiredFlag: true });
      setTimeout(() => {
        notifyAcquireBadge('「商品分析画面を確認する」', '今月の売れ筋は？のバッジ', 'をゲットしました');
        this.props.logger(_genOnboardingLoadedLog());
        _genClickOnboardingNotifyLog(this.props.logger);
      }, 5000);
      postBadgeList(BADGE_TYPE.menuAnalysis.key);
      unCheckedBadge();
      this._handleStorageLoaded();
    }
  }

  render() {
    const {
      setTableProperties,
      search,
      categories,
      lastUpdateDate,
      stores,
      selectedTypeState,
      changeSelectedType,
    } = this.props;
    const { isShowModal, tempSelectedItem } = this.state;
    //グループ内で表示店舗設定がONの全ての店舗がAirレジ未利用場合に、Airレジクロスセル導線を表示する
    const isRegiAppeal = !stores.some(store => store.isRegiUse);
    const onSubmitUrl = `${airRegiTop}?lid=${MENU_ANALYSIS_REGI_MODAL_BTN_LID_PARAMETER}`;
    return (
      <Wrapper>
        <TitleHeader
          track={this.props.logger}
          title="商品分析"
          faqTitle="商品分析の使い方"
          faqLink={menuFaq}
          pageName="menu_analysis"
          lastUpdateDate={lastUpdateDate != null ? lastUpdateDate : undefined}
          lastUpdateDateLog={_genTooltipFaqLog()}
        />
        {isRegiAppeal ? (
          <React.Fragment>
            <Waypoint
              onEnter={() => {
                this.props.logger(_genRegiAppealImpressionLog());
              }}
            >
              <AppealWrapper>
                <AppealText>
                  Airレジをご利用いただくと、売上情報が自動連携されます。
                  <br />
                  売れ行きをカンタンに把握でき、商品内容の見直しに活かせます。
                </AppealText>
                <SampleImage src={MenuAnalysisSample} alt={'商品分析サンプル'} width={520} height={392} />
                <StyledButton
                  onClick={() => {
                    this.setState({ isShowModal: true });
                    this.props.logger(_genRegiAppealButtonClickLog());
                  }}
                  primary={true}
                >
                  Airレジについて詳しく見る
                </StyledButton>
              </AppealWrapper>
            </Waypoint>
            {isShowModal && (
              <AppealModal
                productType={'MENU_ANALYSIS_REGI'}
                onClick={() => {
                  this.setState({ isShowModal: false });
                  this.props.logger(_genAppealModalClickCloseLog());
                }}
                onSubmit={() => {
                  window.open(onSubmitUrl);
                  this.props.logger(_genAppealModalSubmitLog());
                }}
                buttonText={'いますぐAirレジの利用をはじめる'}
              />
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MenuSearchForm />
            {categories.loadStatus.type === 'error' &&
            getReturnCode(categories.loadStatus.error) === returnCodes.replaceGroupId ? (
              <Templates.Center>
                <Error
                  type={ERROR_TYPE_FAILED}
                  msg={
                    '店舗グループ統廃合によるデータ移行処理中のため、\nデータを表示できません。\n　\nお手数ですが、時間をおいて再度お試しください。'
                  }
                />
              </Templates.Center>
            ) : (
              <React.Fragment>
                <MenuTableTopArea
                  selectedType={selectedTypeState}
                  searchState={search}
                  changeSelectedType={changeSelectedType}
                  setTableProperties={setTableProperties}
                  tempSelectedItem={tempSelectedItem}
                />
                {search.loadStatus.type === 'loaded' ? (
                  <React.Fragment>
                    <MenuTable
                      result={
                        selectedTypeState !== 'category'
                          ? search.loadStatus.payload.totalizationResults
                          : search.loadStatus.payload.totalizationResultsByCategory
                      }
                      searchState={search}
                      setTableProperties={setTableProperties}
                      logger={this.props.logger}
                      selectedType={selectedTypeState}
                      setTempSelectedItem={this._setTempSelectedItem}
                    />
                  </React.Fragment>
                ) : search.loadStatus.type === 'loading' || search.loadStatus.type === 'idle' ? (
                  <ActivityIndicator />
                ) : null}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    summary: state.allMenu.summary,
    search: state.allMenu.search,
    categories: state.allMenu.categories,
    lastUpdateDate: state.uiConfig.batchProcessLastFinishDatetime,
    badgeAcuiredInfo: state.badge.data,
    stores: assignedStoresSelector(state),
    tutorialLoadingFlag: state.noticeAndTodo.tutorialLoadingFlag,
    selectedTypeState: state.allMenu.ui.selectedType,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        logger: track,
        setTableProperties,
        initialSearchMenu,
        postBadgeList: badgeListactions.postBadgeList,
        unCheckedBadge: commonUiActions.unCheckedBadge,
        changeSelectedType: changeSelectedType,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllMenu);

const _genComponentDidMountLog = () => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }

  return genGaLog(
    'menu_analysis',
    'all_menu_form',
    'on_load',
    {},
    {},
    'load',
    undefined,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genTooltipFaqLog = () => {
  return genGaLog('menu_analysis', 'menu_analysis', 'open_tooltip_lastUpdatedDateLabel_faq', {}, {}, 'click');
};

const _genOnboardingLoadedLog = () => {
  return genGaLog('badge', 'notification', '', { badgeName: 'menu_analysis' }, {}, 'on_load');
};

const _genClickOnboardingNotifyLog = (logger: typeof track) => {
  const notifyElement = document.querySelector('.badgeNotice');
  notifyElement?.addEventListener('click', () => {
    logger(
      genGaLog('badge', 'notification', 'notification_click', { badgeName: 'menu_analysis' }, {}, 'click')
    );
  });
};

const _genRegiAppealImpressionLog = (): Logger => {
  return genGaLog(
    'menu_analysis',
    'menu_analysis_crossuse_panel',
    'impression',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.arg] },
    'impression'
  );
};

const _genRegiAppealButtonClickLog = (): Logger => {
  return genGaLog(
    'menu_analysis',
    'menu_analysis_crossuse_panel',
    'open',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.arg] },
    'click'
  );
};

const _genAppealModalSubmitLog = (): Logger => {
  return genGaLog(
    'menu_analysis',
    `menu_analysis_panel_${CROSSSELL_PRODUCT_TYPE.arg}_modal`,
    'submit',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.arg] },
    'click'
  );
};

const _genAppealModalClickCloseLog = (): Logger => {
  return genGaLog(
    'menu_analysis',
    `menu_analysis_panel_${CROSSSELL_PRODUCT_TYPE.arg}_modal`,
    'close',
    {},
    { type: [CROSSSELL_PRODUCT_TYPE.arg] },
    'click'
  );
};

const Wrapper = styled.div`
  padding: 24px;
`;

const AppealWrapper = styled.div`
  text-align: center;
  margin-top: 40px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const AppealText = styled.p`
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0px;
`;

const StyledButton = styled(Button)`
  margin-top: 24px;
  width: 320px;
  height: 44px;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
`;

const SampleImage = styled.img`
  margin-top: 19px;
`;
