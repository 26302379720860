export const commonTermForService = 'https://cdn.p.recruit.co.jp/terms/apf-t-1002/index.html'; // Airサービス共通利用約款
export const privacyPolicy = 'https://cdn.p.recruit.co.jp/terms/clu-t-1001/index.html'; // プライバシーポリシー
export const mateFaq = 'https://faq.mate.airregi.jp/hc/ja'; //AirメイトFAQ
export const mateRequest = 'https://jp.surveymonkey.com/r/NJ3WJ5C'; //Airメイトへのご要望
export const airShiftUrl = 'https://airshift.jp/sft/monthlyshift'; //AirShiftバックオフィス
export const airShiftUrlWeeklyShift = 'https://airshift.jp/sft/weeklyshift'; //AirShiftバックオフィス
export const airShiftUrlToAttendance = 'https://airshift.jp/sft/attendance/bymonth/'; //AirShiftバックオフィス
export const airShiftPromoUrl = 'https://airregi.jp/shift/'; //AirShiftプロモサイト
export const airShiftMonthly = 'https://airshift.jp/sft/monthlyshift/'; //AirShift月次
export const airPayUrl = 'https://merchant.airpayment.jp/rpycl/transfer/summary/'; //AirPayバックオフィス
export const airPayPromoUrl = 'https://airregi.jp/payment/'; //AirPayプロモサイト
export const airPayEntryUrl = 'https://airregi.jp/payment/rpy/contract/entry/'; //AirPay entry
export const airPayqrUrl = 'https://cl.mp.airregi.jp/summary/'; //AirPayQR
export const airPayqrEntryUrl = 'https://mp.airregi.jp/entry/about/'; //AirPayQR entry
export const airCashUrl = 'https://airregi.jp/cash/'; //Airキャッシュトップ
export const airCashLogin = 'https://member.r-cash.jp/mypage/'; //Airキャッシュログイン
export const airRegiTop = 'https://airregi.jp/CLP/view/top/'; //Airレジトップ
export const airRegiUrl = 'https://airregi.jp/CLP/view/salesList/'; //Airレジバックオフィス
export const airRegiUrlForSalesListByMenu = 'https://airregi.jp/CLP/view/salesListByMenu/'; //Airレジバックオフィス
export const airRegiPromoUrl = 'https://airregi.jp/'; //Airレジプロモサイト
export const airRegiLogin = 'https://airregi.jp/CLP/view/login/'; //Airレジログイン
export const airCardUrl = 'https://aircard.jp/myPage/'; //AirCardバックオフィス
export const airCardPromoUrl = 'https://airregi.jp/aircard/'; //AirCardプロモサイト
export const airMateAppStoreUrl = 'https://apps.apple.com/app/apple-store/id1343663166'; //AppStore
export const airMateGooglePlayUrl = 'https://play.google.com/store/apps/details'; //GooglePlay
export const airMateAppUrlFromSmartBanner =
  'https://apps.apple.com/app/apple-store/id1343663166?pt=21979&ct=amt_smartphone_web&mt=8';
export const airMateAppUrlFromRedirectAirMarket =
  'https://apps.apple.com/app/apple-store/id1343663166?pt=21979&ct=amt_airmarket_action_link&mt=8';
export const airMateAndroidUrl = 'https://play.google.com/store/apps/details?id=jp.airmate&hl=ja&gl=US';
export const airRegiOrderRequestInfo = 'https://airregi.jp/order/request_info/'; //Airレジ　資料請求・お問合せ
export const rbTop = 'https://restaurant-board.com/CLP/view/top/'; //RB トップ
export const rbIndex = 'https://restaurant-board.com/CLP/view/top/index'; //RB index
export const airPaySummary = 'https://merchant.airpayment.jp/rpycl/transfer/summary/'; //Airpay サマリー
export const airRegiOrderItem = 'https://cl.order.airregi.jp/#item/'; //Airレジオーダー　item
export const appAdjust = 'https://app.adjust.com/12fq4olw'; //app adjust
export const noParamAppAdjust = 'https://app.adjust.com/'; //appAdjust
export const airRegiAppAdjust = 'https://app.adjust.com/1ju2a1z3'; //Airレジ appAdjust
export const airMateAppUrlFromRedirectABT =
  'https://apps.apple.com/app/apple-store/id1343663166?pt=21979&mt=8'; //AppStore(ctなし)
export const rbPromoUrl = 'https://airregi.jp/restaurant-board'; //RBプロモサイト
export const airRegiOrderPromoUrl = 'https://airregi.jp/order/'; //Airレジオーダープロモサイト
export const airInvoiceUrl = 'https://airinvoice.jp'; //Airインボイスバックオフィス
export const airPayAdmin = 'https://merchant.airpayment.jp/rpycl/transfer/details/'; // Airペイ管理画面
export const spoCampaignUrl = 'https://airregi.jp/order/campaign/initial_cost/'; //Airレジ オーダープロモサイトのSPOキャンペーンページ
