import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@air-kit/air-kit';
import ClickOutside from 'react-click-outside';
import { airblue } from '../../../constants/colors';
import FilterIcon from '../../../icons/filter.svg';
import FilterBlueIcon from '../../../icons/filterBlue.svg';
import ZIndex from '../../../constants/z-index';
import { eqn } from '../../../helpers/util';

type Props = {
  readonly className?: string;
  readonly options: Map<string, string>;
  readonly selectedItems: Set<string>;
  readonly onChange: (a: Set<string>) => void;
  readonly onClick?: () => void;
  readonly onClose?: () => void;
  readonly height?: number;
  readonly setTempSelectedItem: (items: Set<string>) => void;
};

type State = {
  isOpen: boolean;
};

class FilterModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentWillUnmount() {
    this.setState({
      isOpen: false,
    });
  }

  _handleClickSelector(e: React.SyntheticEvent<HTMLElement>, key: string) {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    if (this.props.selectedItems.has(key)) {
      const tempSet = new Set(this.props.selectedItems);
      if (tempSet.delete(key)) {
        this.props.onChange(tempSet);
        this.props.setTempSelectedItem(tempSet);
      }
    } else {
      const tempSet = new Set(this.props.selectedItems);
      if (tempSet.add(key)) {
        this.props.onChange(tempSet);
        this.props.setTempSelectedItem(tempSet);
      }
    }
  }

  _handleToggleSelectBox = (e: React.SyntheticEvent<HTMLElement>, onClick?: Function | null) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    if (this.state.isOpen && this.props.onClose) {
      this.props.onClose();
    }

    this.setState({ ...this.state, isOpen: !this.state.isOpen });
    onClick && onClick();
    e.preventDefault();
  };
  _handleAllSelect = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.props.onChange(new Set(this.props.options.keys()));
    this.props.setTempSelectedItem(new Set(this.props.options.keys()));
  };
  _handleAllUnSelect = (e: React.SyntheticEvent<HTMLElement>) => {
    e.stopPropagation(); // select boxを押して閉じるときoutsideclickと衝突するため

    this.props.onChange(new Set());
    this.props.setTempSelectedItem(new Set());
  };
  _renderListItem = () => {
    const { height, options, selectedItems } = this.props;
    return (
      <CustomClickOutside onClickOutside={e => this._handleToggleSelectBox(e)}>
        <List height={height}>
          <Text>表示をフィルタリング</Text>
          <AllSelectItemArea>
            <AllSelectItem onClick={this._handleAllSelect}>すべて選択</AllSelectItem>
            <AllSelectItem onClick={this._handleAllUnSelect}>すべて解除</AllSelectItem>
          </AllSelectItemArea>
          {Array.from(options).map(option => (
            <React.Fragment key={option[0]}>
              <ListItem
                isSelected={selectedItems.has(option[0])}
                onClick={e => this._handleClickSelector(e, option[0])}
              >
                <Checkbox isChecked={selectedItems.has(option[0])} onClick={e => e.stopPropagation()} />
                <Label>{option[1]}</Label>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
        <BoxPointer />
      </CustomClickOutside>
    );
  };

  render() {
    const { onClick, options, selectedItems } = this.props;
    return (
      <React.Fragment>
        <FilterIconWrapper
          isFiltering={!eqn(options.size, selectedItems.size)}
          onClick={e => this._handleToggleSelectBox(e, onClick)}
        >
          {eqn(options.size, selectedItems.size) ? <FilterIcon /> : <FilterBlueIcon />}
        </FilterIconWrapper>
        {this.state.isOpen && this._renderListItem()}
      </React.Fragment>
    );
  }
}

const FilterIconWrapper = styled.div<{ isFiltering: boolean }>`
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-left: 4px;
  padding: 2.5px 3px;
  border: solid 1px ${props => (props.isFiltering ? airblue : '#999999')};
  border-radius: 4px;
  line-height: 15px;
  vertical-align: middle;
  cursor: pointer;
  ${props => props.isFiltering && 'background-color: #E1F0F8;'};
`;

const Text = styled.p`
  text-align: left;
  padding: 20px 20px 0px;
`;

const List = styled.div<{ height?: number }>`
  width: 280px;
  background-color: white;
  position: absolute;
  top: 24px;
  left: -110px;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll;
  height: ${props => props.height}px;
`;

const ListItem = styled.div<{ isSelected?: boolean }>`
  min-height: 40px;
  padding: 12px;
  background-color: ${props => props.isSelected && '#e1f0f8'};
  :not(:first-child) {
    margin-top: 1px;
  }

  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #e1f0f8;
  }
  word-break: break-all;
`;

const AllSelectItemArea = styled(ListItem)`
  align-items: center;
  justify-content: flex-end;
  font-size: 10px;
  cursor: default;
  background-color: initial;
  &:hover {
    background-color: initial;
  }
`;

const AllSelectItem = styled.p`
  color: ${airblue};
  cursor: pointer;
  display: inline-block;
  :not(:first-child) {
    margin-left: 10px;
  }
`;

const CustomClickOutside = styled(ClickOutside)`
  position: relative;
  z-index: ${ZIndex.selectBox};
`;

const BoxPointer = styled.div`
  top: 4px;
  left: -20px;
  width: 0;
  height: 0;
  border: solid 10px transparent;
  border-bottom-color: white;
  position: absolute;
  z-index: ${ZIndex.selectBox};
`;

const Label = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
  text-align: left;
`;

export default FilterModal;
