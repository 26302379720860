export const CROSSSELL_PRODUCT_TYPE = {
  arg: 'arg', //AirREGI
  sft: 'sft', //AirSHIFT
  apy: 'apy', //AirPAY
  mpa: 'mpa', //AirPAYQR
  ord: 'ord', //AirREGIオーダー
  rb: 'rb', //RestaurantBOARD
  acd: 'acd', //AirCARD
  awt: 'awt', //AirWAIT
  rsv: 'rsv', //AirRESERVE
  acs: 'acs', //AirCASH
  skm: 'skm', //すごい顧客マーケティング
  spo: 'spo', // SPO訴求バナー(キャンペーン終了後に除却)
  inv: 'inv', //AirINVOICE
  product_idea_list: 'product_idea_list', //連携サービス
};
