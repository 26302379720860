import { fork } from 'redux-saga/effects';
import { searchSaga, initialSearchSaga } from './search';
import { downloadMenuResults } from './csvDownload';
import { getCategoriesSaga } from './categories';
import { getAnalysisTagGroup } from './analysisTagGroup';

export default function* allMenuSaga() {
  yield fork(searchSaga);
  yield fork(initialSearchSaga);
  yield fork(downloadMenuResults);
  yield fork(getCategoriesSaga);
  yield fork(getAnalysisTagGroup);
}
