/**
 * 商品分析画面の商品別/カテゴリー別の選択状態を保持するstate
 * */

import { SelectedType } from '../../typedef/api/AllMenu';

export type State = {
  readonly selectedType: SelectedType;
};

export const CHANGE_SELECTED_TYPE = 'sys/SELECTED_TYPE';

export const types = {
  CHANGE_SELECTED_TYPE,
};

export type changeSelectedTypeAction = {
  readonly type: typeof CHANGE_SELECTED_TYPE;
  readonly payload: SelectedType;
};

export type Action = changeSelectedTypeAction;

export const changeSelectedType = (selectedType: SelectedType): changeSelectedTypeAction => {
  return {
    type: CHANGE_SELECTED_TYPE,
    payload: selectedType,
  };
};

export const actions = {
  changeSelectedType,
};

export const initialState: State = {
  selectedType: 'menu',
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case CHANGE_SELECTED_TYPE:
      return {
        ...state,
        selectedType: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
