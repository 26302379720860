// 訴求共通コンポーネントの2番目のヘッダー(ご利用の流れ)

import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { lightBlue } from '../../../../constants/colors';
import { AppealModal } from '../AppealModal';
import { PRODUCT_TYPE } from '../../../../constants/appealModal';
import { ServiceFlowContent } from './ServiceFlowContent';

type Props = ComponentProps<typeof AppealModal>;

export const ServiceFlow = (props: Pick<Props, 'productType'>) => {
  const { productType } = props;
  switch (productType) {
    case PRODUCT_TYPE.MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_MODAL_SHIFT:
    case PRODUCT_TYPE.DAILYREPORT_INPUT_MODAL_SHIFT:
    case PRODUCT_TYPE.DAILYREPORT_CARD:
    case PRODUCT_TYPE.DAILYREPORT_SHIFT:
    case PRODUCT_TYPE.PRODUCTLIST_CARD:
    case PRODUCT_TYPE.PRODUCTLIST_OES:
    case PRODUCT_TYPE.PRODUCTLIST_RB:
    case PRODUCT_TYPE.PRODUCTLIST_REGI:
    case PRODUCT_TYPE.PRODUCTLIST_SHIFT:
    case PRODUCT_TYPE.DAYOFWEEKHOURLY_REGI_NOT_USE:
    case PRODUCT_TYPE.MENU_ANALYSIS_REGI:
    case PRODUCT_TYPE.LABORCOSTANALYSIS_SHIFT:
    case PRODUCT_TYPE.LABORCOSTANALYSIS_OES:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CARD:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_OES:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_RB:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_SHIFT:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_SHIFT_SUMMARY:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CARD_SUMMARY:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CASH:
    case PRODUCT_TYPE.REALTIME_REGI:
    case PRODUCT_TYPE.REALTIME_OES:
    case PRODUCT_TYPE.REALTIME_SHIFT:
    case PRODUCT_TYPE.REALTIME_RB:
    case PRODUCT_TYPE.STORE_MONTHLY_INDICES_SHIFT:
    case PRODUCT_TYPE.STORE_MONTHLY_INDICES_OES:
    case PRODUCT_TYPE.STORE_INDICES_SHIFT:
    case PRODUCT_TYPE.STORE_INDICES_OES:
    case PRODUCT_TYPE.DAILYDETAILPAST_REGI:
    case PRODUCT_TYPE.DAILYDETAILPAST_SHIFT:
    case PRODUCT_TYPE.DAILYDETAILPAST_OES:
    case PRODUCT_TYPE.DAILYDETAILPAST_RB:
    case PRODUCT_TYPE.DAILYDETAILFUTURE_SHIFT:
    case PRODUCT_TYPE.DAILYDETAILFUTURE_RB:
    case PRODUCT_TYPE.PRODUCTLIST_PAY:
    case PRODUCT_TYPE.PRODUCTLIST_MPA:
    case PRODUCT_TYPE.PRODUCTLIST_INVOICE:
    case PRODUCT_TYPE.DAILYREPORT_EMPTYSTATE_REGI:
    case PRODUCT_TYPE.MONTHLYLOOKBACK_EMPTYSTATE_REGI:
      return (
        <React.Fragment>
          <ProductDescriptionHeader>ご利用の流れ</ProductDescriptionHeader>
          <ServiceFlowContent productType={productType} />
        </React.Fragment>
      );
    default:
      return null;
  }
};

const ProductDescriptionHeader = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 32px 32px 0;
  padding: 2px 0 0 12px;
  background-color: ${lightBlue};
`;
