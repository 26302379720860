// 入金情報
import React from 'react';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import TitleHeader from '../../../components/common/TitleHeader';
import { State as ReduxState } from '../../../modules';
import { track } from '../../../modules/logging';
import styled from 'styled-components';
import SelectBox from '../../../components/common/atoms/SelectBox';
import { StoresData } from '../../../modules/user';
import {
  changePeriod,
  changeViewType,
  initialFetch,
  actions as paymentUiActions,
  selectStore,
} from '../../../modules/payment/ui';
import {
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  ApiState,
} from '../../../typedef/api/Utility';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import { PaymentMaster } from '../../../typedef/api/Payment/PaymentMaster';
import Templates from '../../../components/common/templates';
import { ActivityIndicator } from '../../../components/common';
import { assertNever } from '../../../helpers/util';
import ApiError from '../../../components/common/templates/ApiError';
import { textLinkColor } from '../../../constants/colors';
import {
  PaymentSummary,
  TransferInfoSummary,
  GopTransferInfoSummary,
} from '../../../typedef/api/Payment/PaymentSummary';
import {
  actions as paymentApiActions,
  startFetchPaymentSummary,
  startFetchPayDetail,
  startFetchPayQrDetail,
  startFetchGopDetail,
  startFetchPaymentCalendar,
} from '../../../modules/payment/api';
import Summary from './components/Summary';
import SummaryTable from './components/SummaryTable';
import CsvDownloadModal from './components/CsvDownloadModal';
import PayDetailModal from './components/PayDetailModal';
import { storesWithTransferInfoSummary, transferYearMonthList } from '../../../selectors/paymentSelectors';
import OpenLinkIcon from '../../../icons/openLinkBlue.svg';
import DownloadIcon from '../../../icons/download.svg';
import { airPayEntryUrl, airPayUrl, airPayqrEntryUrl, airPayqrUrl } from '../../../constants/externalLink';
import {
  TRANSFER_APY_QR_USE_ACT_BTN_LID_PARAMETER,
  TRANSFER_BOTTOM_APY_TEXTLINK_ACT_BTN_LID_PARAMETER,
  TRANSFER_MPA_APY_USE_ACT_BTN_LID_PARAMETER,
} from '../../../constants/externalLinkParameter';
import { transferFaq } from '../../../constants/faqUrls';
import AirPayAppeal from './components/AirPayAppeal';
import { Waypoint } from 'react-waypoint';
import { genGaLog } from '../../../gaLogger';
import { PAYMENT_VIEW_TYPE, PRODUCT_CODE } from './transferConstants';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import { getCookie } from '../../../helpers/cookieHelper';
import { LocalYearMonthObj, formatter, mclDayjs, parser } from '../../../helpers/mclDate';
import Toggle from './components/Toggle';
import TransferCalendarContainer from './TransferCalendarContainer';
import { PAYMENT_VIEW_TYPE_KEY_NAME, TRANSFER_FIRST_VIEW } from '../../../constants/LocalStorage';
import { FROM, FROM_VALUE } from '../../../constants/requestParameter';

type DispatchProps = {
  readonly logging: typeof track;
  readonly selectStore: typeof selectStore;
  readonly initialFetch: typeof initialFetch;
  readonly changePeriod: typeof changePeriod;
  readonly startFetchPaymentSummary: typeof startFetchPaymentSummary;
  readonly fetchPayDetail: typeof startFetchPayDetail;
  readonly fetchPayQrDetail: typeof startFetchPayQrDetail;
  readonly fetchGopDetail: typeof startFetchGopDetail;
  readonly changeViewType: typeof changeViewType;
  readonly fetchPaymentCalendar: typeof startFetchPaymentCalendar;
};
type StateProps = {
  readonly stores: ReadonlyArray<StoresData>;
  readonly selectedStores: Set<string>;
  readonly paymentMasterState: ApiState<PaymentMaster>;
  readonly yearMonthList?: ReadonlyArray<string> | undefined;
  readonly period: {
    year: string;
    month: string;
  };
  readonly yearMonth: LocalYearMonthObj;
  readonly paymentSummaryState: ApiState<PaymentSummary>;
  readonly storesWithTransferInfoSummary: ReadonlyArray<
    (TransferInfoSummary | GopTransferInfoSummary) & StoresData
  >;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly isSpUse?: boolean;
  readonly selectedViewType: keyof typeof PAYMENT_VIEW_TYPE;
};

type State = {
  isOpenPaymentCalendarModal: boolean;
  isOpenDownloadModal: boolean;
  isOpenPayDetaildModal: boolean;
  isChangeStore: boolean;
  selectedProductCode?: string;
  selectedTransferDate: string;
  selectedAkrCode: string;
};

type Props = RouteComponentProps<{}> & StateProps & DispatchProps;

class DepositInfomation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenPaymentCalendarModal: false,
      isOpenDownloadModal: false,
      isOpenPayDetaildModal: false,
      isChangeStore: false,
      selectedProductCode: '',
      selectedTransferDate: '',
      selectedAkrCode: '',
    };
  }

  componentDidMount(): void {
    this.props.initialFetch();
    const viewType = localStorage.getItem(PAYMENT_VIEW_TYPE_KEY_NAME);
    const url: URLSearchParams = new URLSearchParams(window.location.search);
    const isTransferFirstView = localStorage.getItem(TRANSFER_FIRST_VIEW);
    const fromParam: string | null = url.get(FROM);
    // 入金カレンダーリリース後、初回入金情報画面を開いたか確認
    if (isTransferFirstView != null && isTransferFirstView === 'true') {
      //ローカルストレージに初期表示する表示タイプが存在する場合にそちらを初期表示する
      if (viewType != null) {
        this.props.changeViewType(viewType as keyof typeof PAYMENT_VIEW_TYPE);
      }
    } else {
      // メルパミからの初回流入の場合、カレンダーをデフォルト表示
      if (fromParam === FROM_VALUE.MP) {
        this.props.changeViewType('calendar');
      }
      localStorage.setItem(TRANSFER_FIRST_VIEW, 'true');
    }
  }

  selectDate = (value: string) => {
    const { changePeriod, startFetchPaymentSummary, fetchPaymentCalendar, selectedStores, selectedViewType } =
      this.props;
    changePeriod(value);
    if (selectedViewType === 'list') {
      startFetchPaymentSummary(
        mclDayjs(value, formatter.mapiDefaultYearMonthNotFixed).format(formatter.mapiYearMonth),
        [...selectedStores]
      );
    } else {
      fetchPaymentCalendar(
        mclDayjs(value, formatter.mapiDefaultYearMonthNotFixed).format(formatter.mapiYearMonth),
        [...selectedStores]
      );
    }
  };

  selectStores = (store: Set<string>) => {
    this.props.selectStore(store);
    this.setState({ isChangeStore: true });
  };

  onClose = () => {
    const { startFetchPaymentSummary, fetchPaymentCalendar, yearMonth, selectedStores, selectedViewType } =
      this.props;
    const stores = [...selectedStores];
    if (stores.length !== 0 && this.state.isChangeStore) {
      if (selectedViewType === 'list') {
        startFetchPaymentSummary(parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth), [
          ...selectedStores,
        ]);
      } else {
        fetchPaymentCalendar(parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth), [
          ...selectedStores,
        ]);
      }
    }
    this.setState({ isChangeStore: false });
  };

  _onClickPayDetailLink = (
    transferDate: string,
    termStartDate: string,
    termEndDate: string,
    akrCode: string,
    deadlineDate: string,
    productManagementId: string,
    productCode: string
  ) => {
    const { fetchPayDetail, fetchPayQrDetail, fetchGopDetail } = this.props;
    this.setState({
      isOpenPayDetaildModal: true,
      selectedProductCode: productCode,
      selectedTransferDate: transferDate,
      selectedAkrCode: akrCode,
    });
    if (productCode === 'apy') {
      fetchPayDetail(transferDate, termStartDate, termEndDate, akrCode, deadlineDate, productManagementId);
    } else if (productCode === 'mpa') {
      fetchPayQrDetail(transferDate, termStartDate, termEndDate, akrCode, deadlineDate, productManagementId);
    } else {
      fetchGopDetail(transferDate, termStartDate, termEndDate, productManagementId, akrCode);
    }
  };

  _closePayDetailModal = () => {
    this.setState({ isOpenPayDetaildModal: false });
    this.props.logging(_genCloseDetailModalLog());
  };

  _onClickDownloadLink = () => {
    this.setState({ isOpenDownloadModal: true });
    this.props.logging(_genClickCsvButtonLog());
  };

  _closeDownloadModal = () => {
    this.setState({ isOpenDownloadModal: false });
  };

  _changeListViewType = () => {
    const { startFetchPaymentSummary, changeViewType, logging, yearMonth, selectedStores } = this.props;
    changeViewType('list');
    localStorage.setItem(PAYMENT_VIEW_TYPE_KEY_NAME, 'list');
    startFetchPaymentSummary(parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth), [
      ...selectedStores,
    ]);
    logging(_genChangeViewTypeLog('list'));
  };

  _changeCalendarViewType = () => {
    const { fetchPaymentCalendar, changeViewType, logging, yearMonth, selectedStores } = this.props;
    changeViewType('calendar');
    localStorage.setItem(PAYMENT_VIEW_TYPE_KEY_NAME, 'calendar');
    fetchPaymentCalendar(parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth), [
      ...selectedStores,
    ]);
    logging(_genChangeViewTypeLog('calendar'));
  };

  render() {
    const {
      logging,
      stores,
      selectedStores,
      paymentMasterState,
      yearMonthList,
      period,
      yearMonth,
      paymentSummaryState,
      storesWithTransferInfoSummary,
      batchProcessedDate,
      isSpUse,
      selectedViewType,
    } = this.props;
    const isAirPayUse = stores.some(store => store.isAirPayUse);
    const isPayqrUse = stores.some(store => store.isPayqrUse);
    const isGopUse = stores.some(store => store.isGopUse);
    const isSingleStore = stores.length === 1;
    const isViewTypeCalendar = selectedViewType === 'calendar';
    switch (paymentMasterState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      case API_STATE_COMPLETED:
        return (
          <Wrapper>
            <Waypoint
              onEnter={() => {
                logging(_genLoadedLog(selectedViewType));
              }}
            />
            {isViewTypeCalendar && (
              <Waypoint
                onLeave={() => {
                  logging(_genOnScrollCalendarLog());
                }}
              />
            )}
            <TitleHeader
              track={logging}
              title="入金情報"
              faqTitle="入金情報の使い方"
              faqLink={transferFaq}
              pageName="transfer"
              selectedTransferType={selectedViewType}
            />
            <Lead>
              Airペイ、Airペイ QR、オンライン決済（ホットペッパーグルメのスマート支払い、Airレジ
              オーダーのオンライン決済）の前日分までの入金情報をまとめて確認できます。
              {(isAirPayUse || isPayqrUse || isGopUse) &&
                '最新のデータはそれぞれの管理画面でご確認ください。'}
            </Lead>
            {/* AirペイとAirペイQRとGOPのどれも未利用の場合、Airペイ訴求画面を表示 */}
            {!isAirPayUse && !isPayqrUse && !isGopUse ? (
              <Waypoint
                onEnter={() => {
                  logging(_genCrossuseApyNoMpaLog('impression', 'impression'));
                }}
              >
                <AppealWrapper>
                  <AirPayAppeal
                    onClick={() => {
                      logging(_genCrossuseApyNoMpaLog('click', 'open'));
                    }}
                  />
                </AppealWrapper>
              </Waypoint>
            ) : (
              <React.Fragment>
                {/* AirペイとAirペイQRのクロスセル */}
                {(!isAirPayUse || !isPayqrUse) && (
                  <LinkWrapper>
                    {!isAirPayUse && (
                      <Waypoint
                        onEnter={() => {
                          logging(_genCrossuseImpressionLog('apy'));
                        }}
                      >
                        <Link
                          href={`${airPayEntryUrl}?lid=${TRANSFER_APY_QR_USE_ACT_BTN_LID_PARAMETER}`}
                          target="_blank"
                          onClick={() => {
                            logging(_genCrossuseClickLog('apy'));
                          }}
                        >
                          Airペイでクレジットカード・電子マネー決済を導入する
                          <StyledOpenLinkIcon />
                        </Link>
                      </Waypoint>
                    )}
                    {!isPayqrUse && (
                      <Waypoint
                        onEnter={() => {
                          logging(_genCrossuseImpressionLog('mpa'));
                        }}
                      >
                        <Link
                          href={`${airPayqrEntryUrl}?lid=${TRANSFER_MPA_APY_USE_ACT_BTN_LID_PARAMETER}`}
                          target="_blank"
                          onClick={() => {
                            logging(_genCrossuseClickLog('mpa'));
                          }}
                        >
                          Airペイ QRでQRコード決済を導入する
                          <StyledOpenLinkIcon />
                        </Link>
                      </Waypoint>
                    )}
                  </LinkWrapper>
                )}
                <SelectBoxWrapper>
                  <Toggle
                    selectedViewType={selectedViewType}
                    changeListViewType={this._changeListViewType}
                    changeCalendarViewType={this._changeCalendarViewType}
                  />
                  {yearMonthList != null && (
                    <StyledDateSelectBox
                      options={yearMonthList.map(month => ({ key: month, value: month }))}
                      onChange={e => this.selectDate(e.value)}
                      onClick={() => {
                        logging(
                          _genSelectMonthLog(
                            parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth),
                            selectedViewType
                          )
                        );
                      }}
                      size="auto"
                      balloonSize="small"
                      placeholder={
                        period.year === ''
                          ? // 初期表示は今月、yearMonthListは最も過去月から来月までの降順なので２番目
                            { key: yearMonthList[1], value: yearMonthList[1] }
                          : {
                              key: parser
                                .fromYearMonthObject(yearMonth)
                                .format(formatter.mapiDefaultYearMonth),
                              value: parser
                                .fromYearMonthObject(yearMonth)
                                .format(formatter.mapiDefaultYearMonth),
                            }
                      }
                    />
                  )}
                  {!isSingleStore && (
                    <StyledStoreSelectBox
                      required={true}
                      options={
                        new Map(
                          stores.map(store => {
                            return [store.akrCode, store.storeName];
                          })
                        )
                      }
                      allSelectMessage={'全店舗'}
                      onChange={stores => {
                        this.selectStores(stores);
                      }}
                      onClose={this.onClose}
                      onClick={() => {
                        logging(_genSelectStoreLog(selectedStores, selectedViewType));
                      }}
                      selectedItems={selectedStores}
                    />
                  )}
                </SelectBoxWrapper>
                {isViewTypeCalendar ? (
                  <TransferCalendarContainer isAirPayUse={isAirPayUse} />
                ) : paymentSummaryState.type === API_STATE_COMPLETED ? (
                  <React.Fragment>
                    <Summary
                      paymentSummary={paymentSummaryState.payload}
                      isAirPayUse={isAirPayUse}
                      isPayqrUse={isPayqrUse}
                      isGopUse={isGopUse}
                      logging={logging}
                      isSpUse={isSpUse != null && isSpUse}
                      selectedViewType={selectedViewType}
                    />
                    <DownloadLinkWrapper>
                      <DownloadLink onClick={this._onClickDownloadLink}>
                        <StyledCSVDownloadIcon />
                        <DownloadLinkText>各種データのダウンロード</DownloadLinkText>
                      </DownloadLink>
                    </DownloadLinkWrapper>
                    <SummaryTable
                      storesWithTransferInfoSummary={storesWithTransferInfoSummary}
                      paymentSummary={paymentSummaryState.payload}
                      logging={logging}
                      onClickPayDetail={this._onClickPayDetailLink}
                      isSingleStore={isSingleStore}
                    />
                    {this.state.isOpenPayDetaildModal && (
                      <PayDetailModal
                        productCode={this.state.selectedProductCode}
                        transferDate={this.state.selectedTransferDate}
                        akrCode={this.state.selectedAkrCode}
                        onClose={this._closePayDetailModal}
                      />
                    )}
                    {this.state.isOpenDownloadModal && (
                      <CsvDownloadModal
                        closeModal={this._closeDownloadModal}
                        tracker={logging}
                        batchProcessedDate={batchProcessedDate}
                        stores={stores}
                        defaultDate={yearMonth}
                        defaultStore={Array.from(selectedStores)}
                        yearMonthList={yearMonthList}
                        isAirPayUse={isAirPayUse}
                        isPayqrUse={isPayqrUse}
                      />
                    )}
                  </React.Fragment>
                ) : (
                  <Templates.Center>
                    <ActivityIndicator />
                  </Templates.Center>
                )}
                {(isAirPayUse || isPayqrUse) && (
                  <LinkWrapper>
                    {isAirPayUse && (
                      <Link
                        href={`${airPayUrl}?lid=${TRANSFER_BOTTOM_APY_TEXTLINK_ACT_BTN_LID_PARAMETER}`}
                        target="_blank"
                        onClick={() => {
                          logging(_genOpenAirPayAndQrLog('apy', selectedViewType));
                        }}
                      >
                        Airペイ管理画面
                        <StyledOpenLinkIcon />
                      </Link>
                    )}
                    {isPayqrUse && (
                      <Link
                        href={`${airPayqrUrl}?lid=${TRANSFER_BOTTOM_APY_TEXTLINK_ACT_BTN_LID_PARAMETER}`}
                        target="_blank"
                        onClick={() => {
                          logging(_genOpenAirPayAndQrLog('mpa', selectedViewType));
                        }}
                      >
                        Airペイ QR管理画面
                        <StyledOpenLinkIcon />
                      </Link>
                    )}
                  </LinkWrapper>
                )}
              </React.Fragment>
            )}
          </Wrapper>
        );
      case API_STATE_FAILED:
        return (
          <Templates.Center>
            <ApiError />
          </Templates.Center>
        );
      default:
        return assertNever(paymentMasterState);
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  stores: assignedStoresSelector(state),
  selectedStores: state.payment.ui.selectedStores,
  paymentMasterState: state.payment.payment.paymentMasterState,
  yearMonthList: transferYearMonthList(state),
  period: state.payment.ui.period,
  yearMonth: state.payment.ui.yearMonth,
  paymentSummaryState: state.payment.payment.paymentSummaryState,
  storesWithTransferInfoSummary: storesWithTransferInfoSummary(state),
  batchProcessedDate: state.uiConfig.batchProcessedDate,
  isSpUse: state.user.data?.isSpUse,
  selectedViewType: state.payment.ui.selectedViewType,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        logging: track,
        selectStore: paymentUiActions.selectStore,
        initialFetch: paymentUiActions.initialFetch,
        changePeriod: paymentUiActions.changePeriod,
        startFetchPaymentSummary: paymentApiActions.startFetchPaymentSummary,
        fetchPayDetail: paymentApiActions.startFetchPayDetail,
        fetchPayQrDetail: paymentApiActions.startFetchPayQrDetail,
        fetchGopDetail: paymentApiActions.startFetchGopDetail,
        changeViewType: paymentUiActions.changeViewType,
        fetchPaymentCalendar: paymentApiActions.startFetchPaymentCalendar,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositInfomation);

const _genLoadedLog = (selectedTransferType: keyof typeof PAYMENT_VIEW_TYPE) => {
  const cookieData = getCookie('influxData');
  let vos: string | undefined;
  let lid: string | undefined;
  let viaPromoFlg: string | undefined;
  if (cookieData != null) {
    const cookieDataJson = JSON.parse(cookieData);
    vos = cookieDataJson.vos;
    lid = cookieDataJson.lid;
    viaPromoFlg = cookieDataJson.via_promo_flg;
  }
  return genGaLog(
    'transfer',
    'transfer',
    'on_load',
    {},
    { selected_transfer_type: selectedTransferType },
    'load',
    undefined,
    vos,
    lid,
    viaPromoFlg
  );
};

const _genSelectMonthLog = (date, selectedTransferType: keyof typeof PAYMENT_VIEW_TYPE) => {
  return genGaLog(
    'transfer',
    'top_menu',
    'select_month',
    date,
    { selected_transfer_type: selectedTransferType },
    'click'
  );
};

const _genSelectStoreLog = (akrCode, selectedTransferType: keyof typeof PAYMENT_VIEW_TYPE) => {
  return genGaLog(
    'transfer',
    'top_menu',
    'select_store',
    akrCode,
    { selected_transfer_type: selectedTransferType },
    'click'
  );
};

const _genCrossuseImpressionLog = (productCode: keyof typeof PRODUCT_CODE) => {
  return genGaLog(
    'transfer',
    `${productCode}_crossuse_panel`,
    'impression',
    {},
    {
      type: [productCode],
    },
    'impression'
  );
};

const _genCrossuseClickLog = (productCode: keyof typeof PRODUCT_CODE) => {
  return genGaLog(
    'transfer',
    `${productCode}_crossuse_panel`,
    'open',
    {},
    {
      type: [productCode],
    },
    'click'
  );
};

const _genCrossuseApyNoMpaLog = (event: 'click' | 'impression', func: 'impression' | 'open') => {
  return genGaLog('transfer', 'apy_nompa_crossuse_panel', func, {}, {}, event);
};

const _genOpenAirPayAndQrLog = (
  feature: keyof typeof PRODUCT_CODE,
  selectedTransferType: keyof typeof PAYMENT_VIEW_TYPE
) => {
  return genGaLog(
    'transfer',
    `open_${feature}`,
    'open',
    {},
    { selected_transfer_type: selectedTransferType },
    'click'
  );
};

const _genClickCsvButtonLog = () => {
  return genGaLog('transfer', 'top_menu', 'open_csv', {}, {}, 'click');
};

const _genCloseDetailModalLog = () => {
  return genGaLog('transfer', 'close_transfer_detail', 'close', {}, {}, 'click');
};

const _genChangeViewTypeLog = (selectedTransferType: keyof typeof PAYMENT_VIEW_TYPE) => {
  return genGaLog(
    'transfer',
    'select_toggle',
    'select_transfer_type',
    {},
    { selected_transfer_type: selectedTransferType },
    'click'
  );
};

const _genOnScrollCalendarLog = () => {
  return genGaLog('transfer', 'transfer_calender', 'scroll_transfer_calender', {}, {}, 'change');
};

const Wrapper = styled.div`
  padding: 16px 24px 24px;
  height: 100%;
`;

const Lead = styled.div`
  font-size: 14px;
  margin-top: 16px;
`;

const SelectBoxWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

const StyledStoreSelectBox = styled(SelectBox.multiple)`
  width: 320px;
`;
const StyledDateSelectBox = styled(SelectBox.normal)`
  width: 140px;
  margin: 0 16px;
`;

const Link = styled.a`
  color: ${textLinkColor};
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  margin-right: 26px;
`;

const StyledOpenLinkIcon = styled(OpenLinkIcon)`
  margin-left: 6px;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding-bottom: 16px;
`;

const DownloadLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 12px;
`;

const DownloadLink = styled.div`
  display: flex;
  cursor: pointer;
`;

const DownloadLinkText = styled.span`
  font-size: 14px;
  color: ${textLinkColor};
`;

const StyledCSVDownloadIcon = styled(DownloadIcon)`
  margin-right: 6px;
`;

const AppealWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 43px);
`;
