import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import Templates from '../components/common/templates';
import { Header } from '../components/common';
import AllIndex from './pages/AllIndex/AllIndexContainer';
import Store from './pages/Store/StoreContainer';
import Realtime from './pages/Realtime/RealtimeContainer';
import AllMenu from './pages/AllMenu/AllMenuContainer';
import DataOutput from './pages/DataOuput/DataOutputContainer';
import BasicSetting from './pages/BasicSetting/BasicSettingContainer';
import DailySales from './pages/DailySales/DailySalesContainer';
import CostItemSetting from './pages/CostItemSetting/CostItemSettingContainer';
import CustomizeDailyReport from './pages/CustomizeDailyReport/CustomizeDailyReportContainer';
import CostPredictionSetting from './pages/CostPredictionSetting/CostPredicitonSettingContainer';
import GoalSetting from './pages/TargetSetting/GoalSettingContainer';
import PastDataImport from './pages/PastDataImport/PastDataImportContainer';
import AssignedStoreSetting from './pages/AssignedStoreSetting/AssignedStoreSettingContainer';
import DailyReportList from './pages/DailyReportList/DailyReportListContainer';
import DailyCostList from './pages/DailyCostList/DailyCostListContainer';
import LaborCostAnalysis from './pages/LaborCostAnalysis/LaborCostAnalysisContainer';
import DayofweekHourly from './pages/DayofweekHourly/DayofweekHourlyContainer';
import CardSetting from './pages/CardSetting/CardSettingContainer';
import CardCostList from './pages/CardCostList/CardCostListContainer';
import InvoiceCostList from './pages/InvoiceCostList/InvoiceCostListContainer';
import Error from '../components/common/templates/UnavailableError';
import { LaunchView } from '../components/common';
import { track } from '../modules/logging';
import { State as UserState, StoresData } from '../modules/user';
import VoucherAnalysis from './pages/VoucherAnalysis/VoucherAnalysisContainer';
import { selectedStoreSelector } from '../selectors/storeSelector';
import SettingMonthlyLookbackContainer from './pages/MonthlyLookback/SettingMonthlyLookbackContainer';
import PrintMonthlyLookbackContainer from './pages/MonthlyLookback/PrintMonthlyLookbackContainer';
import SmartBanner from '../components/common/SmartBanner';
import ProductList from './pages/ProductList/ProductListContainer';
import Transfer from './pages/Transfer/TransferContainer';
import YearlyReport from './pages/YearlyReport/YearlyReportContainer';

const hideSideAndHeaderViewList = [
  'monthly_lookback_preview',
  'monthly_lookback_print',
  'assigned_store_setting',
  'error',
];

type DispatchProps = {
  readonly track: typeof track;
};
type StateProps = {
  readonly initialized: boolean;
  readonly user: UserState;
  readonly selectedStore?: StoresData;
};
type Props = Readonly<DispatchProps & StateProps & RouteComponentProps>;

class App extends React.Component<Props> {
  _renderError = () => <Error track={this.props.track} />;

  // BodyWrapperのスクロール量が引き継がれてしまうので戻す
  componentDidUpdate(prepProps: Props) {
    if (this.props.location !== prepProps.location) {
      const scrollable = document.getElementById('scrollable');
      scrollable && scrollable.scrollTo(0, 0);
    }
  }

  render() {
    if (
      !this.props.initialized ||
      this.props.user.loading ||
      this.props.user.data == null ||
      this.props.user.data.stores == null
    ) {
      return <LaunchView />;
    }

    // 店舗情報がなければエラー画面表示
    if (this.props.user.data.stores.length === 0) {
      return this._renderError();
    }

    const pathName = this.props.location.pathname.split('/')[1];

    // TODO: URL末尾のスラッシュがあったりなかったりバラバラ
    return (
      <Templates.PageOuter>
        <Templates.HeaderWrapper>
          <SmartBanner track={this.props.track} viewName={window.location.pathname} />
          {!hideSideAndHeaderViewList.includes(pathName) && <Header viewName={window.location.pathname} />}
        </Templates.HeaderWrapper>
        <Templates.BodyWrapper
          showSidebar={!hideSideAndHeaderViewList.includes(pathName)}
          logging={this.props.track}
          pathName={pathName}
          hideScrollBar={pathName === 'realtime'}
        >
          <Switch>
            <Route exact path="/">
              <Redirect to="/store" />
            </Route>
            <Route path="/all_index" component={AllIndex} />
            <Route path="/store" component={Store} />
            <Route path="/realtime" component={Realtime} />
            <Route path="/menu_analysis" component={AllMenu} />
            <Route path="/basic_setting" component={BasicSetting} />
            <Route path="/data_import_setting" component={DailySales} />
            <Route path="/voucher_analysis" component={VoucherAnalysis} />
            <Route path="/data_output" component={DataOutput} />
            <Route path="/monthly_lookback" component={SettingMonthlyLookbackContainer} />
            <Route path="/monthly_lookback_print" component={PrintMonthlyLookbackContainer} />
            <Route path="/cost_item_setting" component={CostItemSetting} />
            <Route path="/customize_daily_report" component={CustomizeDailyReport} />
            <Route path="/cost_prediction_setting" component={CostPredictionSetting} />
            <Route path="/set_target" component={GoalSetting} />
            <Route path="/past_data_import" component={PastDataImport} />
            <Route path="/assigned_store_setting" component={AssignedStoreSetting} />
            <Route path="/daily_report_list" component={DailyReportList} />
            <Route path="/daily_cost_list" component={DailyCostList} />
            <Route path="/labor_cost_analysis" component={LaborCostAnalysis} />
            <Route path="/dayofweek_hourly" component={DayofweekHourly} />
            <Route path="/card_setting" component={CardSetting} />
            <Route path="/card_cost_list" component={CardCostList} />
            <Route path="/invoice_cost_list" component={InvoiceCostList} />
            <Route path="/product_idea_list" component={ProductList} />
            <Route path="/transfer" component={Transfer} />
            <Route path="/year_lookback" component={YearlyReport} />
            <Route path="/error" render={this._renderError} />
          </Switch>
        </Templates.BodyWrapper>
      </Templates.PageOuter>
    );
  }
}

const mapStateToProps = state => {
  return {
    initialized: state.launch.initialized,
    user: state.user,
    selectedStore: selectedStoreSelector(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    track: bindActionCreators(track, dispatch),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
