import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import ArrowRight from '../../../../../../icons/arrow_right.svg';
import OpenLink from '../../../../../../icons/openLink.svg';
import MateKunWinkDaily from '../../../../../../icons/matekunWinkDaily.svg';
import {
  white,
  uploadBorderColor,
  airblue,
  airBoxShadow,
  black,
  verylightgray,
  airblueHover,
  verylightgrayBorder,
} from '../../../../../../../src/constants/colors';
import { AppealModal } from '../../../../../../components/common/appealModal/AppealModal';
import {
  airShiftUrlWeeklyShift,
  airCardPromoUrl,
  airRegiOrderRequestInfo,
  rbTop,
  airCashLogin,
  spoCampaignUrl,
} from '../../../../../../constants/externalLink';
import {
  STORE_CALENDAR_INDICES_CARD_MODAL_BTN_LID_PARAMETER,
  STORE_CALENDAR_INDICES_SHIFT_MODAL_BTN_LID_PARAMETER,
  STORE_CALENDAR_INDICES_OES_MODAL_BTN_LID_PARAMETER,
  STORE_CALENDAR_INDICES_RBMODAL_BTN_LID_PARAMETER,
  STORE_CALENDAR_INDICES_CARD_MODAL_BTN_CAMPAIGN_PARAMETER,
  STORE_DAILY_INDICES_SHIFT_MODAL_BTN_LID_PARAMETER,
  STORE_CALENDAR_INDICES_CASH_MODAL_BTN_LID_PARAMETER,
  STORE_CALENDAR_INDICES_SPO_BTN_LID_PARAMATER,
} from '../../../../../../constants/externalLinkParameter';
import { CROSSSELL_PRODUCT_TYPE } from '../../../../../../constants/crossSellProduct';
import { PRODUCT_TYPE } from '../../../../../../constants/appealModal';
import { track } from '../../../../../../modules/logging';
import { genGaLog } from '../../../../../../gaLogger';
import { Logger } from '../../../../../../typedef/logger';
import {
  BANNER_TYPE,
  BANNER_TYPE_SHIFT,
  BANNER_TYPE_RESTAURANT_BOARD,
  BANNER_TYPE_ORDER,
  BANNER_TYPE_CARD,
  BANNER_TYPE_IDEALIST,
  BANNER_TYPE_CASH,
  BANNER_TYPE_SPO,
  BANNER_TYPE_REGI_LINKAGE_PAYQR,
  INDICES_TYPE,
} from '../../../storesConstants';
import TitleLogoWrapper from './TitleLogoWrapper';
import { Link } from 'react-router-dom';
import IdeaListImage from '../../../../../../icons/ideaListImage.png';
import Hint from '../../../../../../icons/lightbulb.svg';
import Notice from '../../../../../../icons/Notice.svg';
import ArrowRightGray from '../../../../../../icons/arrow_gray_right.svg';
import SpoBannerCalendar from '../../../../../../icons/spoBannerCalendar.svg';
import SpoBannerDailyIndices from '../../../../../../icons/spoBannerDailyIndices.svg';

type Props = {
  tracking: typeof track;
  bannerType: BANNER_TYPE;
  from: 'calendar' | 'daily';
  readonly akrCode?: string;
};

const BannerContents = (props: Props) => {
  const { tracking, bannerType, from, akrCode } = props;
  const [showModal, setShowModal] = useState(false);

  const _getProductType = (
    bannerType: BANNER_TYPE,
    from?: string
  ): {
    productType: keyof typeof PRODUCT_TYPE;
    url?: string;
    buttonText?: string;
    isNoFooter?: boolean;
  } | null => {
    switch (bannerType) {
      case BANNER_TYPE_SHIFT:
        return {
          productType: 'STORE_CALENDAR_INDICES_SHIFT',
          url:
            airShiftUrlWeeklyShift +
            '?lid=' +
            `${
              from === 'daily'
                ? STORE_DAILY_INDICES_SHIFT_MODAL_BTN_LID_PARAMETER
                : STORE_CALENDAR_INDICES_SHIFT_MODAL_BTN_LID_PARAMETER
            }`,
          buttonText: 'いますぐAirシフトの利用をはじめる',
        };
      case BANNER_TYPE_CARD:
        return {
          productType: 'STORE_CALENDAR_INDICES_CARD',
          url:
            airCardPromoUrl +
            '?campaignCd=' +
            STORE_CALENDAR_INDICES_CARD_MODAL_BTN_CAMPAIGN_PARAMETER +
            '&lid=' +
            STORE_CALENDAR_INDICES_CARD_MODAL_BTN_LID_PARAMETER,
          buttonText: 'Airカードの申込みはこちら',
        };
      case BANNER_TYPE_ORDER:
        return {
          productType: 'STORE_CALENDAR_INDICES_OES',
          url: airRegiOrderRequestInfo + '?lid=' + STORE_CALENDAR_INDICES_OES_MODAL_BTN_LID_PARAMETER,
          buttonText: '資料請求（無料）・お問い合わせはこちら',
        };
      case BANNER_TYPE_RESTAURANT_BOARD:
        return {
          productType: 'STORE_CALENDAR_INDICES_RB',
          url: rbTop + '?lid=' + STORE_CALENDAR_INDICES_RBMODAL_BTN_LID_PARAMETER,
          buttonText: 'いますぐレストランボードの利用をはじめる',
        };
      case BANNER_TYPE_CASH:
        return {
          productType: 'STORE_CALENDAR_INDICES_CASH',
          url: airCashLogin + '?lid=' + STORE_CALENDAR_INDICES_CASH_MODAL_BTN_LID_PARAMETER,
          buttonText: 'ご利用可能金額を確認する',
        };
      case BANNER_TYPE_REGI_LINKAGE_PAYQR:
        return {
          productType: 'STORE_CALENDAR_INDICES_REGI_PAYQR',
          isNoFooter: true,
        };
      default:
        // モーダルを出さない場合はnullを返す
        return null;
    }
  };

  const appealModalData = _getProductType(bannerType, from);

  return (
    <React.Fragment>
      <Waypoint
        onEnter={() => {
          if (bannerType === BANNER_TYPE_REGI_LINKAGE_PAYQR) {
            tracking(_genShowRegiPanel(from, akrCode));
          } else {
            tracking(_genBannerLoaded(from, bannerType, akrCode));
          }
        }}
      >
        <Wrapper>
          {from === 'daily' && bannerType === BANNER_TYPE_SHIFT && (
            <MateWrapper from={from}>
              <div>シフトの作成や管理をカンタンにしませんか？</div>
              <MateKunWinkDaily />
            </MateWrapper>
          )}
          <Frame bannerType={bannerType} from={from}>
            <TitleLogoWrapper bannerType={bannerType} from={from} />
            {bannerType !== BANNER_TYPE_IDEALIST &&
              bannerType !== BANNER_TYPE_REGI_LINKAGE_PAYQR &&
              bannerType !== BANNER_TYPE_SPO && (
                <SubmitButton
                  onClick={() => {
                    setShowModal(true);
                    tracking(_genClickModal(from, bannerType, akrCode));
                  }}
                >
                  <ButtonText>詳しく見る</ButtonText>
                  <ArrowStyle src="#{arrow_right}" />
                </SubmitButton>
              )}
            {bannerType === BANNER_TYPE_IDEALIST && (
              <IdeaListBannerWrapper>
                <ImageWrapper>
                  <img width={64} height={68} src={IdeaListImage} alt="連携サービスのイメージ" />
                  <Circle text={'コスト'} position={'leftTop'} />
                  <Circle text={'会計'} position={'rightTop'} />
                  <Circle text={'集客'} position={'leftBottom'} />
                  <Circle text={'勤怠'} position={'rightBottom'} />
                </ImageWrapper>
                <p>お店の業務を、もっと便利に</p>
                <LinkButtonInternalLink
                  to={'/product_idea_list/'}
                  onClick={() => {
                    tracking(_genClickSubmit(from, bannerType, akrCode));
                  }}
                >
                  <ButtonText>連携サービスを見る</ButtonText>
                  <ArrowStyle src="#{arrow_right}" />
                </LinkButtonInternalLink>
              </IdeaListBannerWrapper>
            )}
            {bannerType === BANNER_TYPE_SPO && (
              <SpoBannerWrapper>
                {from === 'calendar' ? <StyledSpoBannerCalendar /> : <StyledSpoBannerDailyIndices />}
                <LinkButton
                  target="_blank"
                  href={spoCampaignUrl + '?lid=' + STORE_CALENDAR_INDICES_SPO_BTN_LID_PARAMATER}
                  onClick={() => {
                    tracking(_genClickSubmit(from, bannerType, akrCode));
                  }}
                  bannerType={bannerType}
                >
                  <StyledExternalButtonText>詳しくはこちら</StyledExternalButtonText>
                  <StyledOpenLinkIcon src="#{open_link_icon_white}" />
                </LinkButton>
                {from === 'daily' && (
                  <SpoAnnotation>
                    ※特典は数に限りがあります。本特典は予告なく変更、終了の可能性があります。
                  </SpoAnnotation>
                )}
              </SpoBannerWrapper>
            )}
            {bannerType === BANNER_TYPE_REGI_LINKAGE_PAYQR && (
              <RegiLinkagePayQRWrapper>
                <p>
                  {from === 'daily' ? <StyledNotice /> : <StyledHint />}
                  成績にすべての売上を表示するには
                </p>
                <SubmitButtonGray
                  from={from}
                  onClick={() => {
                    setShowModal(true);
                    tracking(_genClickAirPayLinkageBannerButtonLog(from, akrCode));
                  }}
                >
                  <span>
                    連携方法を見る
                    <StyledArrowRightGray from={from} />
                  </span>
                </SubmitButtonGray>
              </RegiLinkagePayQRWrapper>
            )}
          </Frame>
        </Wrapper>
      </Waypoint>
      {showModal && appealModalData != null && (
        <AppealModal
          productType={appealModalData.productType}
          onClick={() => {
            setShowModal(false);
            if (bannerType === BANNER_TYPE_REGI_LINKAGE_PAYQR) {
              tracking(_genCloseAirPayLinkageBannerModalLog(from, akrCode));
            } else {
              tracking(_genAppealModalClickCloseLog(from, bannerType, akrCode));
            }
          }}
          onSubmit={() => {
            window.open(appealModalData.url);
            tracking(_genAppealModalSubmitLog(from, bannerType, akrCode));
          }}
          buttonText={appealModalData.buttonText}
          isNoFooter={appealModalData.isNoFooter}
          tracker={tracking}
          akrCode={akrCode}
          from={from}
        />
      )}
    </React.Fragment>
  );
};

const Wrapper = styled.div`
  width: 200px;
  background-color: ${white};
  position: relative;
  z-index: 1;
`;

const Frame = styled.div<{ from?: string; bannerType?: BANNER_TYPE }>`
  border-radius: 5px;
  border: 1px solid ${uploadBorderColor};
  width: 198px;
  position: absolute;
  padding-bottom: ${props => (props.bannerType === BANNER_TYPE_SPO ? '12px' : '20px')};
  ${props => props.from === 'daily' && props.bannerType === BANNER_TYPE_SHIFT && 'top: 34px;'}
  ${props => props.from === 'daily' && props.bannerType === BANNER_TYPE_SPO && 'top: 12px;'}
  ${props =>
    props.from === 'daily' && props.bannerType === BANNER_TYPE_IDEALIST && 'top: 34px; height: 140px;'}
  ${props =>
    props.bannerType === BANNER_TYPE_REGI_LINKAGE_PAYQR && props.from === 'calendar'
      ? `
    border: none;
    border-left: 1px solid ${uploadBorderColor};
    border-radius: unset;
    `
      : props.bannerType === BANNER_TYPE_REGI_LINKAGE_PAYQR && props.from === 'daily'
      ? `
    margin-top: 18px;
  `
      : null}
`;

const SubmitButton = styled.a`
  color: ${white};
  background: ${airblue};
  border-radius: 4px;
  border: 1px solid ${airBoxShadow};
  box-shadow: 0px 1px 0px 0px ${airBoxShadow};
  height: 26px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 5px;
  margin: 8px auto 0px;
  cursor: pointer;
`;

const LinkButton = styled.a<{ bannerType?: BANNER_TYPE }>`
  color: ${white};
  background: ${airblue};
  border-radius: 4px;
  border: 1px solid ${airBoxShadow};
  box-shadow: 0px 1px 0px 0px ${airBoxShadow};
  height: 28px;
  width: ${props => (props.bannerType === BANNER_TYPE_SPO ? '174px' : '168px')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  margin: 8px auto 0px;
  cursor: pointer;
`;

const LinkButtonInternalLink = styled(Link)`
  color: ${white};
  background: ${airblue};
  border-radius: 4px;
  border: 1px solid ${airBoxShadow};
  box-shadow: 0px 1px 0px 0px ${airBoxShadow};
  height: 28px;
  width: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  margin: 8px auto 0px;
  cursor: pointer;
`;

const ButtonText = styled.span`
  font-size: 12px;
  text-align: center;
  width: 100%;
`;

const ArrowStyle = styled(ArrowRight)`
  margin-left: auto;
`;

const MateWrapper = styled.div<{ from: string }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 2;
  > div {
    color: ${black};
    font-size: 11px;
    border-radius: 7px;
    border: 1px solid ${uploadBorderColor};
    height: 64px;
    width: 112px;
    display: flex;
    align-items: center;
    padding: 8px;
    text-align: left;
    position: relative;
    background: ${white};
    z-index: 2;
    background: ${verylightgray};
    &:before,
    &:after {
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
      left: 100%;
      top: 50%;
    }
    &:before {
      left: 110px;
      top: 19px;
      border-style: solid;
      border-color: transparent transparent transparent ${uploadBorderColor};
      border-width: 6px 7px 6px 7px;
    }
    &:after {
      left: 110px;
      top: 20px;
      border-style: solid;
      border-color: transparent transparent transparent ${verylightgray};
      border-width: 5px 6px 5px 6px;
      z-index: 10;
    }
  }
`;

const IdeaListBannerWrapper = styled.div`
  p {
    color: ${airblue};
    font-size: 12px;
    font-weight: 600;
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  width: 144px;
  position: relative;
  margin: -22px auto 8px;
  display: flex;
  justify-content: center;
  background-color: ${white};
`;

const Circle = styled.div<{ text: string; position: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  background-color: ${airblueHover};
  &::before {
    content: '${props => props.text}';
    color: ${airblue};
    font-size: 10px;
    font-weight: 600;
  }
  position: absolute;
  ${props => props.position === 'leftTop' && 'top: 0; left:0;'}
  ${props => props.position === 'rightTop' && 'top: 0; right:0;'}
  ${props => props.position === 'leftBottom' && 'bottom:0; left: -16px;'}
  ${props => props.position === 'rightBottom' && 'bottom:0; right: -16px;'}
`;

const RegiLinkagePayQRWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  > p {
    font-size: 14px;
    line-height: 1.4;
    text-indent: -1.5em;
    width: 146px;
    margin-top: 5px;
    margin-left: 28px;
  }
`;

const SubmitButtonGray = styled.a<{ from?: string }>`
  width: 158px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${verylightgray};
  border: 1px solid ${verylightgrayBorder};
  border-bottom: 2px solid ${verylightgrayBorder};
  border-radius: 6px;
  padding: 8px 0;
  font-size: 12px;
  margin-top: 15px;
  cursor: pointer;
  > span {
    display: flex;
    ${props => props.from === 'calendar' && 'margin-left: 22px'}
  }
  ${props =>
    props.from === 'daily' &&
    `
    width: 166px;
    border-radius: 3px;
    padding: 10px 0 10px 10px;
    font-size: 14px;
    margin-top: 18px;
    `}
  ${props => props.from === 'calendar' && 'margin-left: 4px'}
`;

const StyledHint = styled(Hint)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const StyledNotice = styled(Notice)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  margin-bottom: -4px;
`;

const StyledArrowRightGray = styled(ArrowRightGray)<{ from?: string }>`
  width: 12px;
  ${props => (props.from === 'calendar' ? 'margin-left: 18px' : 'margin-left: 14px')};
`;

const SpoBannerWrapper = styled.div`
  margin: 0 12px;
`;

const StyledSpoBannerCalendar = styled(SpoBannerCalendar)`
  margin-bottom: -6px;
`;

const StyledSpoBannerDailyIndices = styled(SpoBannerDailyIndices)`
  margin-bottom: -6px;
`;

const SpoAnnotation = styled.p`
  width: 102%;
  margin-top: 8px;
  font-size: 7px;
  color: ${black};
`;
const StyledExternalButtonText = styled(ButtonText)`
  width: auto;
  padding-right: 4px;
`;

const StyledOpenLinkIcon = styled(OpenLink)`
  width: 8px;
  height: 8px;
`;

export default BannerContents;

const _genBannerLogText = (bannerType: BANNER_TYPE) => {
  switch (bannerType) {
    case BANNER_TYPE_SHIFT:
      return CROSSSELL_PRODUCT_TYPE.sft;
    case BANNER_TYPE_CARD:
      return CROSSSELL_PRODUCT_TYPE.acd;
    case BANNER_TYPE_ORDER:
      return CROSSSELL_PRODUCT_TYPE.ord;
    case BANNER_TYPE_RESTAURANT_BOARD:
      return CROSSSELL_PRODUCT_TYPE.rb;
    case BANNER_TYPE_IDEALIST:
      return CROSSSELL_PRODUCT_TYPE.product_idea_list;
    case BANNER_TYPE_CASH:
      return CROSSSELL_PRODUCT_TYPE.acs;
    // SPO訴求バナー(キャンペーン終了後に除却)
    case BANNER_TYPE_SPO:
      return CROSSSELL_PRODUCT_TYPE.spo;
    default:
      return '';
  }
};

const _genBannerLoaded = (indicesType: String, bannerType: BANNER_TYPE, akrCode?: string): Logger => {
  const bannerTypeLog = _genBannerLogText(bannerType);
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'impression',
    {},
    { selected_indices_type: indicesType, type: [bannerTypeLog] },
    'impression',
    akrCode
  );
};

const _genShowRegiPanel = (indicesType: String, akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_argappeal_panel',
    'impression',
    {},
    { type: '[apy]', akrCode: akrCode, selected_Indices_type: indicesType },
    'impression'
  );
};

const _genClickSubmit = (indicesType: String, bannerType: BANNER_TYPE, akrCode?: string): Logger => {
  const bannerTypeLog = _genBannerLogText(bannerType);
  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'submit',
    {},
    { selected_indices_type: indicesType, type: [bannerTypeLog] },
    'click',
    akrCode
  );
};

const _genClickModal = (indicesType: String, bannerType: BANNER_TYPE, akrCode?: string): Logger => {
  const bannerTypeLog = _genBannerLogText(bannerType);

  return genGaLog(
    'store_indices',
    'store_indices_crossuse_banner',
    'open',
    {},
    { selected_indices_type: indicesType, type: [bannerTypeLog] },
    'click',
    akrCode
  );
};

const _genAppealModalSubmitLog = (indicesType: String, bannerType: BANNER_TYPE, akrCode?: string): Logger => {
  const bannerTypeLog = _genBannerLogText(bannerType);

  return genGaLog(
    'store_indices',
    `store_indices_${bannerTypeLog}_modal`,
    'submit',
    {},
    { selected_indices_type: indicesType, type: [bannerTypeLog] },
    'click',
    akrCode
  );
};

const _genAppealModalClickCloseLog = (
  indicesType: String,
  bannerType: BANNER_TYPE,
  akrCode?: string
): Logger => {
  const bannerTypeLog = _genBannerLogText(bannerType);
  return genGaLog(
    'store_indices',
    `store_indices_${bannerTypeLog}_modal`,
    'close',
    {},
    { selected_indices_type: indicesType, type: [bannerTypeLog] },
    'click',
    akrCode
  );
};

const _genClickAirPayLinkageBannerButtonLog = (from: keyof typeof INDICES_TYPE, akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_argappeal_panel',
    'open',
    {},
    { type: '[apy]', akr_code: akrCode, selected_indices_type: from },
    'click'
  );
};

const _genCloseAirPayLinkageBannerModalLog = (from: keyof typeof INDICES_TYPE, akrCode?: string) => {
  return genGaLog(
    'store_indices',
    'store_indices_argappeal_modal',
    'close',
    {},
    { type: '[apy]', akr_code: akrCode, selected_indices_type: from },
    'click'
  );
};
