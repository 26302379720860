import { LocalYearMonthObj, mclDayjs } from '../../helpers/mclDate';
import { PAYMENT_VIEW_TYPE } from '../../ui/pages/Transfer/transferConstants';

export type State = {
  readonly selectedStores: Set<string>;
  readonly period: {
    year: string;
    month: string;
  };
  readonly yearMonth: LocalYearMonthObj;
  readonly yearMonthList?: Array<string>;
  readonly selectedViewType: keyof typeof PAYMENT_VIEW_TYPE;
  readonly selectedCalendarDetailDate: string | null;
};

const INITIAL_FETCH: 'payment/INITIAL_FETCH' = 'payment/INITIAL_FETCH';
const SELECT_STORE: 'payment/SELECT_STORE' = 'payment/SELECT_STORE';
const CHANGE_PERIOD: 'payment/CHANGE_PERIOD' = 'payment/CHANGE_PERIOD';
const SET_MONTH_LIST: 'payment/SET_MONTH_LIST' = 'payment/SET_MONTH_LIST';
const CHANGE_VIEW_TYPE: 'payment/CHANGE_VIEW_TYPE' = 'payment/CHANGE_VIEW_TYPE';
const CHANGE_CALENDAR_DETAIL_DATE: 'payment/CHANGE_CALENDAR_DETAIL_DATE' =
  'payment/CHANGE_CALENDAR_DETAIL_DATE';

export const types = {
  INITIAL_FETCH,
  SELECT_STORE,
  CHANGE_PERIOD,
  SET_MONTH_LIST,
  CHANGE_VIEW_TYPE,
  CHANGE_CALENDAR_DETAIL_DATE,
};

export type InitialFetchAction = {
  readonly type: typeof INITIAL_FETCH;
};

export type SelectStoreAction = {
  readonly type: typeof SELECT_STORE;
  readonly payload: Set<string>;
};

export type ChangePeriodAction = {
  readonly type: typeof CHANGE_PERIOD;
  readonly payload: {
    year: string;
    month: string;
    yearMonth: LocalYearMonthObj;
  };
};

export type SetMonthListAction = {
  readonly type: typeof SET_MONTH_LIST;
  readonly payload: Array<string>;
};

export type ChangeViewTypeAction = {
  readonly type: typeof CHANGE_VIEW_TYPE;
  readonly payload: keyof typeof PAYMENT_VIEW_TYPE;
};

export type ChangeCalendarDetailDateAction = {
  readonly type: typeof CHANGE_CALENDAR_DETAIL_DATE;
  readonly payload: string;
};

export type Action =
  | InitialFetchAction
  | SelectStoreAction
  | ChangePeriodAction
  | SetMonthListAction
  | ChangeViewTypeAction
  | ChangeCalendarDetailDateAction;

export const initialFetch = (): InitialFetchAction => {
  return {
    type: INITIAL_FETCH,
  };
};

/**
 * 店舗の変更
 * @param {Set<string>} store
 */
export const selectStore = (stores: Set<string>): SelectStoreAction => {
  return {
    type: SELECT_STORE,
    payload: stores,
  };
};

/**
 * 期間選択の変更
 * @param {string} yearMonth
 */
export const changePeriod = (yearMonth: string): ChangePeriodAction => {
  const year = yearMonth.split('/')[0].padStart(4, '0');
  const month = yearMonth.split('/')[1].padStart(2, '0');
  return {
    type: CHANGE_PERIOD,
    payload: {
      year,
      month,
      yearMonth: { year: +year, month: +month },
    },
  };
};

export const setMonthList = (yearMonthList: Array<string>): SetMonthListAction => {
  return {
    type: SET_MONTH_LIST,
    payload: yearMonthList,
  };
};

export const changeViewType = (type: keyof typeof PAYMENT_VIEW_TYPE): ChangeViewTypeAction => {
  return {
    type: CHANGE_VIEW_TYPE,
    payload: type,
  };
};

export const changeCalendarDetailDate = (date: string): ChangeCalendarDetailDateAction => {
  return {
    type: CHANGE_CALENDAR_DETAIL_DATE,
    payload: date,
  };
};

export const actions = {
  initialFetch,
  selectStore,
  changePeriod,
  setMonthList,
  changeViewType,
  changeCalendarDetailDate,
};

export const initialState: State = {
  selectedStores: new Set(),
  period: {
    year: '',
    month: '',
  },
  yearMonth: mclDayjs().toLocalYearMonthObj(),
  yearMonthList: undefined,
  // 表をデフォルトで表示
  selectedViewType: 'list',
  selectedCalendarDetailDate: null,
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case SELECT_STORE:
      return {
        ...state,
        selectedStores: action.payload,
      };
    case CHANGE_PERIOD:
      return {
        ...state,
        period: {
          year: action.payload.year,
          month: action.payload.month,
        },
        yearMonth: action.payload.yearMonth,
      };
    case SET_MONTH_LIST:
      return {
        ...state,
        yearMonthList: action.payload,
      };
    case CHANGE_VIEW_TYPE:
      return {
        ...state,
        selectedViewType: action.payload,
      };
    case CHANGE_CALENDAR_DETAIL_DATE:
      return {
        ...state,
        selectedCalendarDetailDate: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
