import * as React from 'react';
import styled from 'styled-components';
import {
  baseFontSize,
  prefixUnit,
  LocaleInteger,
  redBlack0,
} from '../../../../components/common/atoms/Number';
import Tooltip from '../../../../components/common/molecules/Tooltip';
import {
  uploadBorderColor,
  lightOrange,
  orangeBorder,
  airGray,
  disabledTextColor,
  white,
  gray,
  lightgray,
  black,
  textLinkColor,
} from '../../../../constants/colors';
import { GOP_TRANSFER_STATUS, TRANSFER_STATUS } from '../transferConstants';
import OpenLinkBlue from '../../../../icons/openLinkBlue.svg';
import { TransferInfoDetail } from '../../../../typedef/api/Payment/TransferInfoDetail';
import { StoresData } from '../../../../modules/user';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';
import { TRANSFER_BOTTOM_APY_TEXTLINK_ACT_BTN_LID_PARAMETER } from '../../../../constants/externalLinkParameter';
import { airPayUrl } from '../../../../constants/externalLink';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';
import { Waypoint } from 'react-waypoint';

type Props = {
  readonly transferInfoDetail: TransferInfoDetail;
  readonly stores: ReadonlyArray<StoresData>;
  readonly logging?: typeof track;
  readonly selectedCalendarDetailDate: string | null;
  readonly airPayStopStores: Array<StoresData>;
};

const FormatYen = baseFontSize(24)(redBlack0(prefixUnit('¥')(LocaleInteger)));
const FormatSmallYen = baseFontSize(18)(redBlack0(prefixUnit('¥')(LocaleInteger)));
const FormatTableYen = baseFontSize(16)(redBlack0(prefixUnit('¥')(LocaleInteger)));
const FormatTableSmallYen = baseFontSize(10)(redBlack0(prefixUnit('¥')(LocaleInteger)));

/**
 * 入金カレンダー詳細モーダルのコンテンツ
 */
class PayCalendarDetailContents extends React.Component<Props> {
  componentDidMount(): void {
    const { logging, selectedCalendarDetailDate } = this.props;
    if (logging != null && selectedCalendarDetailDate != null) {
      logging(_genOpenModalLog(selectedCalendarDetailDate));
    }
  }

  getTransferStatusName = (transferStatus: number): string => {
    switch (transferStatus) {
      case TRANSFER_STATUS.paid.key:
        return TRANSFER_STATUS.paid.fieldName;
      case TRANSFER_STATUS.paymentPlan.key:
        return TRANSFER_STATUS.paymentPlan.fieldName;
      case TRANSFER_STATUS.unfixed.key:
        return TRANSFER_STATUS.unfixed.fieldName;
      case TRANSFER_STATUS.transferStop.key:
        return TRANSFER_STATUS.transferStop.fieldName;
      default:
        return '';
    }
  };

  getGopTransferStatusName = (transferStatus: number): string => {
    switch (transferStatus) {
      case GOP_TRANSFER_STATUS.paid.key:
        return GOP_TRANSFER_STATUS.paid.fieldName;
      case GOP_TRANSFER_STATUS.paymentDuration.key:
        return GOP_TRANSFER_STATUS.paymentDuration.fieldName;
      case GOP_TRANSFER_STATUS.paymentPlan.key:
        return GOP_TRANSFER_STATUS.paymentPlan.fieldName;
      case GOP_TRANSFER_STATUS.beforeFileReceive.key:
        return GOP_TRANSFER_STATUS.beforeFileReceive.fieldName;
      case GOP_TRANSFER_STATUS.transferStop.key:
        return GOP_TRANSFER_STATUS.transferStop.fieldName;
      default:
        return '';
    }
  };

  render() {
    const { stores, logging, airPayStopStores } = this.props;
    const { transferInfoDetail } = this.props.transferInfoDetail;
    // その他入金
    const hasOthersPay = transferInfoDetail.totalAdjustment != null;
    // ペイQRに振込停止が含まれるか
    const isPayQrPaymentStop =
      transferInfoDetail.payqrTransferInfoList.length !== 0
        ? transferInfoDetail.payqrTransferInfoList.some(
            list => list.transferStatus === TRANSFER_STATUS.transferStop.key
          )
        : false;
    // GOPに振込停止が含まれるか
    const isGopPaymentStop =
      transferInfoDetail.gopTransferInfoList.length !== 0
        ? transferInfoDetail.gopTransferInfoList.some(
            list => list.transferStatus === GOP_TRANSFER_STATUS.transferStop.key
          )
        : false;
    const hasTransferPrice = transferInfoDetail.totalTransferPrice != null;
    // Airペイのリストに振込停止のデータ有りかつAirペイ契約中の店舗が存在するか
    const isAirPayContractAndPaymentStop = airPayStopStores.some(store => store.isAirPayUse === true);
    return (
      <Wrapper>
        <SummaryWrapper>
          <PaymentAmountWrapper hasTransferPrice={hasTransferPrice}>
            <PaymentAmountTitle>
              入金金額{' '}
              <StyledTitleTooltip
                viewName={'transfer_detail_modal'}
                feature={'depositAmount'}
                name={'depositAmount'}
              >
                「入金金額 = 合計決済金額 – 手数料」です。
                <br />
                <br />
                Airキャッシュを利用している場合は、「入金金額 = 合計決済金額 – 手数料 –
                自動引き落とし分」となります。
                <br />
                <br />
                ※入金金額に「〜」がつく場合は未確定の入金があります。
              </StyledTitleTooltip>
            </PaymentAmountTitle>
            <PaymentAmountValue>
              {hasTransferPrice ? <FormatYen value={transferInfoDetail.totalTransferPrice} /> : '¥-'}
              {hasTransferPrice && (transferInfoDetail.isPending || transferInfoDetail.isWaiting) && (
                <span>~</span>
              )}
              {(transferInfoDetail.isStopped || transferInfoDetail.isPartiallySuspended) && (
                <TransferStatusWrapper
                  transferStatus={TRANSFER_STATUS.transferStop.key}
                  isStopped={transferInfoDetail.isStopped}
                >
                  <p>{transferInfoDetail.isStopped ? '振込停止' : '一部振込停止'}</p>
                </TransferStatusWrapper>
              )}
            </PaymentAmountValue>
          </PaymentAmountWrapper>
          <TotalPaymentAmountWrapper hasOthersPay={hasOthersPay}>
            <p>
              合計決済金額
              <StyledTitleTooltip
                viewName={'transfer_detail_modal'}
                feature={'totalPaymentAmount'}
                name={'totalPaymentAmount'}
              >
                決済金額の合計です。
              </StyledTitleTooltip>
              <PaymentAmountValue>
                <FormatSmallYen value={transferInfoDetail.totalSales} />
              </PaymentAmountValue>
            </p>
            <p>
              合計手数料
              <StyledTitleTooltip
                viewName={'transfer_detail_modal'}
                feature={'commission'}
                name={'commission'}
              >
                【Airペイ／Airペイ QR】
                <br />
                「手数料 = 決済手数料 (合計決済金額 × 手数料率) + 手数料消費税」です。
                <br />
                <br />
                手数料率はAirペイやAirペイ QRの契約情報をご確認ください。
                <br />
                <br />
                手数料消費税は、決済方法により0円（非課税）の場合もあります。
                <br />
                <br />
                【オンライン決済】
                <br />
                「手数料 = 決済手数料 (合計決済金額 × 手数料率) + 振込手数料」です。
              </StyledTitleTooltip>
              <PaymentAmountValue>
                {transferInfoDetail.totalCommission != null ? (
                  <FormatSmallYen value={transferInfoDetail.totalCommission} />
                ) : (
                  '¥-'
                )}
                {transferInfoDetail.totalCommission != null &&
                  (transferInfoDetail.isPending || transferInfoDetail.isWaiting) && <span>~</span>}
              </PaymentAmountValue>
            </p>
            {hasOthersPay && (
              <p>
                その他合計金額(決済金額以外)
                <StyledTitleTooltip
                  viewName={'transfer_detail_modal'}
                  feature={'invoice_totalPaymentAmount'}
                  name={'invoice_totalPaymentAmount'}
                >
                  詳細は「Airペイ QR」の管理画面をご確認ください。
                </StyledTitleTooltip>
                <PaymentAmountValue>
                  <FormatSmallYen value={transferInfoDetail.totalAdjustment} />
                </PaymentAmountValue>
              </p>
            )}
          </TotalPaymentAmountWrapper>
        </SummaryWrapper>
        {transferInfoDetail.payTransferInfoList.length !== 0 && (
          <React.Fragment>
            <TableAttentionWrapper>
              Airペイ
              {/* Airペイのみ振込停止データ有りかつその店舗がAirペイ契約中の場合にAPY管理画面導線表示 */}
              {isAirPayContractAndPaymentStop && !isPayQrPaymentStop && !isGopPaymentStop && (
                <Waypoint
                  onEnter={() => {
                    logging != null && logging(_genPayStopLinkLog('apy_management', 'impression'));
                  }}
                >
                  <p>
                    ※振込停止の場合は
                    <a
                      href={`${airPayUrl}?lid=${TRANSFER_BOTTOM_APY_TEXTLINK_ACT_BTN_LID_PARAMETER}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => {
                        logging != null && logging(_genPayStopLinkLog('open', 'click'));
                      }}
                    >
                      「Airペイ」管理画面
                      <StyledOpenLinkBlue />
                    </a>
                    をご確認ください。
                  </p>
                </Waypoint>
              )}
            </TableAttentionWrapper>
            <TableWrapper>
              <Table>
                <thead>
                  <Tr>
                    <Th>店舗名</Th>
                    <Th width={174}>入金金額</Th>
                    <Th width={120}>合計決済金額</Th>
                    <Th width={100}>手数料</Th>
                    <Th width={136}>決済期間</Th>
                  </Tr>
                </thead>
                <tbody>
                  {transferInfoDetail.payTransferInfoList.map((item, index) => (
                    <Tr key={index}>
                      <Td hasBorder={true}>
                        <PaymentStoreName>
                          {stores.find(store => store.akrCode === item.akrCode)?.storeName}
                        </PaymentStoreName>
                      </Td>
                      <Td>
                        <Transfer isFontBold={true}>
                          <TransferStatusWrapper transferStatus={item.transferStatus} isStopped={true}>
                            <p>{this.getTransferStatusName(item.transferStatus)}</p>
                          </TransferStatusWrapper>
                          {item.transferPrice != null && (
                            <StyledFormatTableYen value={item.transferPrice}></StyledFormatTableYen>
                          )}
                        </Transfer>
                      </Td>
                      <Td>
                        <Transfer>
                          <StyledFormatTableYen value={item.sales} />
                        </Transfer>
                      </Td>
                      <Td>
                        {item.commission != null && (
                          <Transfer>
                            <StyledFormatTableYen value={item.commission} />
                          </Transfer>
                        )}
                      </Td>
                      <Td>
                        <PaymentPeriod>
                          {mclDayjs(item.termStartDate, formatter.mapiDate).format(
                            formatter.monthDayFixedWidth
                          )}
                          〜
                          {mclDayjs(item.termEndDate, formatter.mapiDate).format(
                            formatter.monthDayFixedWidth
                          )}
                        </PaymentPeriod>
                      </Td>
                    </Tr>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
          </React.Fragment>
        )}
        {transferInfoDetail.payqrTransferInfoList.length !== 0 && (
          <React.Fragment>
            <TableAttentionWrapper>Airペイ&ensp;QR</TableAttentionWrapper>
            <TableWrapper>
              <Table>
                <thead>
                  <Tr>
                    <Th>店舗名</Th>
                    <Th width={174}>入金金額</Th>
                    <Th width={120}>合計決済金額</Th>
                    <Th width={100}>手数料</Th>
                    <Th width={136}>決済期間</Th>
                  </Tr>
                </thead>
                <tbody>
                  {transferInfoDetail.payqrTransferInfoList.map((item, index) => (
                    <Tr key={index}>
                      <Td hasBorder={true}>
                        <PaymentStoreName>
                          {stores.find(store => store.akrCode === item.akrCode)?.storeName}
                        </PaymentStoreName>
                      </Td>
                      <Td>
                        <React.Fragment>
                          <Transfer isFontBold={true} hasOthersPay={false}>
                            <TransferStatusWrapper transferStatus={item.transferStatus} isStopped={true}>
                              <p>{this.getTransferStatusName(item.transferStatus)}</p>
                            </TransferStatusWrapper>
                            {item.transferPrice != null && (
                              <StyledFormatTableYen value={item.transferPrice} />
                            )}
                          </Transfer>
                          {item.totalAdjustment != null && (
                            <TransferLeadText>
                              その他合計金額(決済金額以外)
                              <FormatTableSmallYen value={item.totalAdjustment} />
                              を含む
                              <StyledTitleTooltip
                                viewName={'transfer_detail_modal'}
                                feature={'invoice_totalPaymentAmount'}
                                name={'invoice_totalPaymentAmount'}
                              >
                                詳細は「Airペイ QR」の管理画面をご確認ください。
                              </StyledTitleTooltip>
                            </TransferLeadText>
                          )}
                        </React.Fragment>
                      </Td>
                      <Td>
                        <Transfer>
                          <StyledFormatTableYen value={item.sales}></StyledFormatTableYen>
                        </Transfer>
                      </Td>
                      <Td>
                        {item.commission != null && (
                          <Transfer>
                            <StyledFormatTableYen value={item.commission} />
                          </Transfer>
                        )}
                      </Td>
                      <Td>
                        <PaymentPeriod>
                          {mclDayjs(item.termStartDate, formatter.mapiDate).format(
                            formatter.monthDayFixedWidth
                          )}
                          〜
                          {mclDayjs(item.termEndDate, formatter.mapiDate).format(
                            formatter.monthDayFixedWidth
                          )}
                        </PaymentPeriod>
                      </Td>
                    </Tr>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
          </React.Fragment>
        )}
        {transferInfoDetail.gopTransferInfoList.length !== 0 && (
          <React.Fragment>
            <TableAttentionWrapper>オンライン決済</TableAttentionWrapper>
            <TableWrapper>
              <Table>
                <thead>
                  <Tr>
                    <Th>店舗名</Th>
                    <Th width={174}>入金金額</Th>
                    <Th width={120}>合計決済金額</Th>
                    <Th width={100}>手数料</Th>
                    <Th width={136}>決済期間</Th>
                  </Tr>
                </thead>
                <tbody>
                  {transferInfoDetail.gopTransferInfoList.map((item, index) => (
                    <Tr key={index}>
                      <Td hasBorder={true}>
                        <PaymentStoreName>
                          {stores.find(store => store.akrCode === item.akrCode)?.storeName}
                        </PaymentStoreName>
                      </Td>
                      <Td>
                        <Transfer isFontBold={true}>
                          <GopTransferStatusWrapper transferStatus={item.transferStatus} isStopped={true}>
                            <p>{this.getGopTransferStatusName(item.transferStatus)}</p>
                          </GopTransferStatusWrapper>
                          {item.transferPrice != null ? (
                            <React.Fragment>
                              <StyledFormatTableYen value={item.transferPrice} />
                            </React.Fragment>
                          ) : (
                            // 入金金額がnullかつ締日後〜振込前 or 振込日、振込後〜（transferStatusが1 or 2）の場合、連携待ち表示
                            item.transferStatus !== GOP_TRANSFER_STATUS.paymentDuration.key && (
                              <WaitingCooperation>
                                連携待ち
                                <StyledTableTooltip
                                  viewName={'transfer_detail_modal'}
                                  feature={'gopwaiting_help_detail'}
                                  name={'gopwaiting_help_detail'}
                                >
                                  現在、入金金額・手数料の連携待ち中です。入金の翌月6日頃に連携されます。
                                </StyledTableTooltip>
                              </WaitingCooperation>
                            )
                          )}
                        </Transfer>
                      </Td>
                      <Td>
                        <Transfer>
                          <StyledFormatTableYen value={item.sales}></StyledFormatTableYen>
                        </Transfer>
                      </Td>
                      <Td>
                        <Transfer>
                          {item.commission != null ? (
                            <StyledFormatTableYen value={item.commission} />
                          ) : (
                            // 手数料がnullかつ締日後〜振込前 or 振込日、振込後〜（transferStatusが1 or 2）の場合、連携待ち表示
                            item.transferStatus !== GOP_TRANSFER_STATUS.paymentDuration.key && (
                              <WaitingCooperation>連携待ち</WaitingCooperation>
                            )
                          )}
                        </Transfer>
                      </Td>
                      <Td>
                        <PaymentPeriod>
                          {mclDayjs(item.termStartDate, formatter.mapiDate).format(
                            formatter.monthDayFixedWidth
                          )}
                          〜
                          {mclDayjs(item.termEndDate, formatter.mapiDate).format(
                            formatter.monthDayFixedWidth
                          )}
                        </PaymentPeriod>
                      </Td>
                    </Tr>
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
          </React.Fragment>
        )}
      </Wrapper>
    );
  }
}

export default PayCalendarDetailContents;

const Wrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  padding: 0 40px 20px;
`;

const SummaryWrapper = styled.div`
  display: flex;
  margin-top: 24px;
`;

const PaymentAmountWrapper = styled.div<{ hasTransferPrice: boolean }>`
  border-bottom: 1px solid ${uploadBorderColor};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 8px;
  ${props => !props.hasTransferPrice && 'min-width: 240px'};
`;

const PaymentAmountTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin-left: 8px;
  display: flex;
  align-items: center;
  color: ${black};
  min-width: 92px;
`;

const PaymentAmountValue = styled.div`
  text-align: right;
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
`;

const TotalPaymentAmountWrapper = styled.div<{ hasOthersPay: boolean }>`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  position: relative;
  p {
    display: flex;
    align-items: center;
    margin-left: 24px;
    ${props =>
      props.hasOthersPay &&
      `
        position: relative;
        top: -14px;
        :last-child {
          position: absolute;
          top: 32px;
        }
    `}
  }
`;

const TransferStatusWrapper = styled.div<{ transferStatus?: number; isStopped: boolean }>`
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  margin: 8px;
  ${props =>
    props.transferStatus === TRANSFER_STATUS.paymentPlan.key &&
    `background: ${lightOrange}; color: ${orangeBorder}; min-width: 36px;`}

  ${props =>
    props.transferStatus === TRANSFER_STATUS.unfixed.key &&
    `background: ${airGray}; color: ${disabledTextColor}; min-width: 48px;`}

  ${props =>
    props.transferStatus === TRANSFER_STATUS.transferStop.key &&
    `background: ${white}; color: ${disabledTextColor};border: 1px solid ${uploadBorderColor}; min-width: ${
      props.isStopped ? 62 : 86
    }px;`}
`;

const GopTransferStatusWrapper = styled.div<{ transferStatus?: number; isStopped: boolean }>`
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  margin: 8px;
  ${props =>
    props.transferStatus === GOP_TRANSFER_STATUS.paymentPlan.key &&
    `background: ${lightOrange}; color: ${orangeBorder}; min-width: 36px;`}

  ${props =>
    props.transferStatus === GOP_TRANSFER_STATUS.paymentDuration.key &&
    `background: ${airGray}; color: ${disabledTextColor}; min-width: 48px;`}

  ${props =>
    props.transferStatus === GOP_TRANSFER_STATUS.transferStop.key &&
    `background: ${white}; color: ${disabledTextColor};border: 1px solid ${uploadBorderColor}; min-width: ${
      props.isStopped ? 62 : 86
    }px;`}
`;

const StyledFormatTableYen = styled(FormatTableYen)`
  margin-left: auto;
`;

const StyledTitleTooltip = styled(Tooltip.UpperLeftPortal)`
  margin: 0 10px 0 4px;
  display: inline-grid;
`;

const StyledTableTooltip = styled(Tooltip.UpperLeftPortal)`
  margin-left: 4px;
`;

const TableAttentionWrapper = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-top: 32px;
  display: flex;
  align-items: center;
  color: ${black};
  p {
    margin-left: 24px;
    font-weight: 400;
    font-size: 14px;
    a {
      color: ${textLinkColor};
      background: none;
      border: none;
      cursor: pointer;
    }
  }
`;

const StyledOpenLinkBlue = styled(OpenLinkBlue)`
  margin: 0 4px;
`;

const TableWrapper = styled.div`
  margin-top: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`;

const Tr = styled.tr``;

const Th = styled.th<{ width?: number }>`
  font-size: 14px;
  font-weight: 600;
  background: ${gray};
  white-space: nowrap;
  color: ${black};
  height: 44px;
  position: sticky;
  top: 0;
  z-index: 1;
  min-width: ${props => (props.width != null ? `${props.width}px` : 'auto')};
  :first-child {
    border-right: 1px solid ${lightgray};
  }
`;

const Td = styled.td<{ hasBorder?: boolean }>`
  border-bottom: solid 1px ${lightgray};
  ${props => props.hasBorder && `border-right: solid 1px ${lightgray}`};
`;

const PaymentStoreName = styled.div`
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin: 0 auto 0 0;
  display: flex;
  align-items: center;
  padding: 16px 8px;
  font-size: 14px;
  color: ${black};
`;

const Transfer = styled.div<{ isFontBold?: boolean; hasOthersPay?: boolean }>`
  font-weight: ${props => (props.isFontBold != null ? 600 : 400)};
  line-height: 16px;
  letter-spacing: 0px;
  display: flex;
  align-items: center;
  padding: 8px;
  width: 100%;
  ${props =>
    props.hasOthersPay &&
    `
  flex-direction: column; 
  margin-top:6px;
  `}
`;

const TransferLeadText = styled.p`
  font-weight: 400;
  font-size: 10px;
  width: 100%;
  padding: 0 8px 8px;
  margin-top: -6px;
`;

const WaitingCooperation = styled.p`
  font-weight: 400;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 14px;
  color: ${disabledTextColor};
`;

const PaymentPeriod = styled.div`
  font-weight: 400;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  font-size: 16px;
  color: ${black};
`;

const _genPayStopLinkLog = (func: string, event: string) => {
  return genGaLog('transfer_detail_modal', 'apy_management', func, {}, {}, event);
};

const _genOpenModalLog = (date: string) => {
  return genGaLog(
    'transfer',
    'open_transfer_detail_modal',
    'open',
    {},
    { date: date, selected_transfer_type: 'calendar' },
    'click'
  );
};
