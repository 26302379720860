import { PaymentMaster } from '../../typedef/api/Payment/PaymentMaster';
import { PaymentSummary } from '../../typedef/api/Payment/PaymentSummary';
import { PayDetail } from '../../typedef/api/Payment/PayDetail';
import { PayQrDetail } from '../../typedef/api/Payment/PayQrDetail';

import { ApiState, apiState, ErrorType } from '../../typedef/api/Utility';
import { GopDetail } from '../../typedef/api/Payment/GopDetail';
import { MonthlyTransferInfo } from '../../typedef/api/Payment/Calendar';
import { TransferInfoDetail } from '../../typedef/api/Payment/TransferInfoDetail';

export type State = {
  readonly paymentMasterState: ApiState<PaymentMaster>;
  readonly paymentSummaryState: ApiState<PaymentSummary>;
  readonly payDetailState: ApiState<PayDetail>;
  readonly payQrDetailState: ApiState<PayQrDetail>;
  readonly gopDetailState: ApiState<GopDetail>;
  readonly paymentCalendarState: ApiState<MonthlyTransferInfo>;
  readonly PaymentTransferInfoDetailState: ApiState<TransferInfoDetail>;
};

export const START_FETCH_PAYMENT_MASTER = 'payment/START_FETCH_PAYMENT_MASTER';
export const SUCCESS_FETCH_PAYMENT_MASTER = 'payment/SUCCESS_FETCH_PAYMENT_MASTER';
export const FAIL_FETCH_PAYMENT_MASTER = 'payment/FAIL_FETCH_PAYMENT_MASTER';
export const START_FETCH_PAYMENT_SUMMARY = 'payment/START_FETCH_PAYMENT_SUMMARY';
export const SUCCESS_FETCH_PAYMENT_SUMMARY = 'payment/SUCCESS_FETCH_PAYMENT_SUMMARY';
export const FAIL_FETCH_PAYMENT_SUMMARY = 'payment/FAIL_FETCH_PAYMENT_SUMMARY';
export const START_FETCH_PAY_DETAIL = 'payment/START_FETCH_PAY_DETAIL';
export const SUCCESS_FETCH_PAY_DETAIL = 'payment/SUCCESS_FETCH_PAY_DETAIL';
export const FAIL_FETCH_PAY_DETAIL = 'payment/FAIL_FETCH_PAY_DETAIL';
export const START_FETCH_PAY_QR_DETAIL = 'payment/START_FETCH_PAY_QR_DETAIL';
export const SUCCESS_FETCH_PAY_QR_DETAIL = 'payment/SUCCESS_FETCH_PAY_QR_DETAIL';
export const FAIL_FETCH_PAY_QR_DETAIL = 'payment/FAIL_FETCH_PAY_QR_DETAIL';
export const START_FETCH_GOP_DETAIL = 'payment/START_FETCH_GOP_DETAIL';
export const SUCCESS_FETCH_GOP_DETAIL = 'payment/SUCCESS_FETCH_GOP_DETAIL';
export const FAIL_FETCH_GOP_DETAIL = 'payment/FAIL_FETCH_GOP_DETAIL';
export const START_FETCH_PAYMENT_CALENDAR = 'payment/START_FETCH_PAYMENT_CALENDAR';
export const SUCCESS_FETCH_PAYMENT_CALENDAR = 'payment/SUCCESS_FETCH_PAYMENT_CALENDAR';
export const FAIL_FETCH_PAYMENT_CALENDAR = 'payment/FAIL_FETCH_PAYMENT_CALENDAR';
export const START_FETCH_TRANSFER_INFO_DETAIL = 'payment/START_FETCH_TRANSFER_INFO_DETAIL';
export const SUCCESS_FETCH_TRANSFER_INFO_DETAIL = 'payment/SUCCESS_FETCH_TRANSFER_INFO_DETAIL';
export const FAIL_FETCH_TRANSFER_INFO_DETAIL = 'payment/FAIL_FETCH_TRANSFER_INFO_DETAIL';

export const types = {
  START_FETCH_PAYMENT_MASTER,
  SUCCESS_FETCH_PAYMENT_MASTER,
  FAIL_FETCH_PAYMENT_MASTER,
  START_FETCH_PAYMENT_SUMMARY,
  SUCCESS_FETCH_PAYMENT_SUMMARY,
  FAIL_FETCH_PAYMENT_SUMMARY,
  START_FETCH_PAY_DETAIL,
  SUCCESS_FETCH_PAY_DETAIL,
  FAIL_FETCH_PAY_DETAIL,
  START_FETCH_PAY_QR_DETAIL,
  SUCCESS_FETCH_PAY_QR_DETAIL,
  FAIL_FETCH_PAY_QR_DETAIL,
  START_FETCH_GOP_DETAIL,
  SUCCESS_FETCH_GOP_DETAIL,
  FAIL_FETCH_GOP_DETAIL,
  START_FETCH_PAYMENT_CALENDAR,
  SUCCESS_FETCH_PAYMENT_CALENDAR,
  FAIL_FETCH_PAYMENT_CALENDAR,
  START_FETCH_TRANSFER_INFO_DETAIL,
  SUCCESS_FETCH_TRANSFER_INFO_DETAIL,
  FAIL_FETCH_TRANSFER_INFO_DETAIL,
};

export type StartFetchPaymentMasterAction = {
  readonly type: typeof START_FETCH_PAYMENT_MASTER;
};

export type SuccessFetchPaymentMasterAction = {
  readonly type: typeof SUCCESS_FETCH_PAYMENT_MASTER;
  readonly payload: PaymentMaster;
};

export type FailFetchPaymentMasterAction = {
  readonly type: typeof FAIL_FETCH_PAYMENT_MASTER;
  readonly payload: ErrorType;
};

export type StartFetchPaymentSummaryAction = {
  readonly type: typeof START_FETCH_PAYMENT_SUMMARY;
  readonly payload: { date: string; storeList: ReadonlyArray<string> };
};

export type SuccessFetchPaymentSummaryAction = {
  readonly type: typeof SUCCESS_FETCH_PAYMENT_SUMMARY;
  readonly payload: PaymentSummary;
};

export type FailFetchPaymentSummaryAction = {
  readonly type: typeof FAIL_FETCH_PAYMENT_SUMMARY;
  readonly payload: ErrorType;
};

export type StartFetchPayDetailAction = {
  readonly type: typeof START_FETCH_PAY_DETAIL;
  readonly payload: {
    transferDate: string;
    termStartDate: string;
    termEndDate: string;
    akrCode: string;
    productManagementId: string;
    deadlineDate: string;
  };
};

export type SuccessFetchPayDetailAction = {
  readonly type: typeof SUCCESS_FETCH_PAY_DETAIL;
  readonly payload: PayDetail;
};

export type FailFetchPayDetailAction = {
  readonly type: typeof FAIL_FETCH_PAY_DETAIL;
  readonly payload: ErrorType;
};

export type StartFetchPayQrDetailAction = {
  readonly type: typeof START_FETCH_PAY_QR_DETAIL;
  readonly payload: {
    transferDate: string;
    termStartDate: string;
    termEndDate: string;
    akrCode: string;
    productManagementId: string;
    deadlineDate: string;
  };
};

export type SuccessFetchPayQrDetailAction = {
  readonly type: typeof SUCCESS_FETCH_PAY_QR_DETAIL;
  readonly payload: PayQrDetail;
};

export type FailFetchPayQrDetailAction = {
  readonly type: typeof FAIL_FETCH_PAY_QR_DETAIL;
  readonly payload: ErrorType;
};

export type StartFetchGopDetailAction = {
  readonly type: typeof START_FETCH_GOP_DETAIL;
  readonly payload: {
    transferDate: string;
    termStartDate: string;
    termEndDate: string;
    akrCode: string;
    productManagementId: string;
  };
};

export type SuccessFetchGopDetailAction = {
  readonly type: typeof SUCCESS_FETCH_GOP_DETAIL;
  readonly payload: GopDetail;
};

export type FailFetchGopDetailAction = {
  readonly type: typeof FAIL_FETCH_GOP_DETAIL;
  readonly payload: ErrorType;
};

export type StartFetchPaymentCalendarAction = {
  readonly type: typeof START_FETCH_PAYMENT_CALENDAR;
  readonly payload: { date: string; storeList: ReadonlyArray<string> };
};

export type SuccessFetchPaymentCalendarAction = {
  readonly type: typeof SUCCESS_FETCH_PAYMENT_CALENDAR;
  readonly payload: MonthlyTransferInfo;
};

export type FailFetchPaymentCalendarAction = {
  readonly type: typeof FAIL_FETCH_PAYMENT_CALENDAR;
  readonly payload: ErrorType;
};

export type StartFetchPaymentTransferInfoDetailAction = {
  readonly type: typeof START_FETCH_TRANSFER_INFO_DETAIL;
  readonly payload: { date: string; storeList: ReadonlyArray<string> };
};

export type SuccessFetchPaymentTransferInfoDetailAction = {
  readonly type: typeof SUCCESS_FETCH_TRANSFER_INFO_DETAIL;
  readonly payload: TransferInfoDetail;
};

export type FailFetchPaymentTransferInfoDetailAction = {
  readonly type: typeof FAIL_FETCH_TRANSFER_INFO_DETAIL;
  readonly payload: ErrorType;
};

export type Action =
  | StartFetchPaymentMasterAction
  | SuccessFetchPaymentMasterAction
  | FailFetchPaymentMasterAction
  | StartFetchPaymentSummaryAction
  | SuccessFetchPaymentSummaryAction
  | FailFetchPaymentSummaryAction
  | StartFetchPayDetailAction
  | SuccessFetchPayDetailAction
  | FailFetchPayDetailAction
  | StartFetchPayQrDetailAction
  | SuccessFetchPayQrDetailAction
  | FailFetchPayQrDetailAction
  | StartFetchGopDetailAction
  | SuccessFetchGopDetailAction
  | FailFetchGopDetailAction
  | StartFetchPaymentCalendarAction
  | SuccessFetchPaymentCalendarAction
  | FailFetchPaymentCalendarAction
  | StartFetchPaymentTransferInfoDetailAction
  | SuccessFetchPaymentTransferInfoDetailAction
  | FailFetchPaymentTransferInfoDetailAction;

// Action Creators
export const startFetchPaymentMaster = (): StartFetchPaymentMasterAction => {
  return {
    type: START_FETCH_PAYMENT_MASTER,
  };
};

export const successFetchPaymentMaster = (data: PaymentMaster): SuccessFetchPaymentMasterAction => {
  return {
    type: SUCCESS_FETCH_PAYMENT_MASTER,
    payload: data,
  };
};

export const failFetchPaymentMaster = (error: ErrorType): FailFetchPaymentMasterAction => {
  return {
    type: FAIL_FETCH_PAYMENT_MASTER,
    payload: error,
  };
};

export const startFetchPaymentSummary = (
  date: string,
  storeList: ReadonlyArray<string>
): StartFetchPaymentSummaryAction => {
  return {
    type: START_FETCH_PAYMENT_SUMMARY,
    payload: { date, storeList },
  };
};

export const successFetchPaymentSummary = (data: PaymentSummary): SuccessFetchPaymentSummaryAction => {
  return {
    type: SUCCESS_FETCH_PAYMENT_SUMMARY,
    payload: data,
  };
};

export const failFetchPaymentSummary = (error: ErrorType): FailFetchPaymentSummaryAction => {
  return {
    type: FAIL_FETCH_PAYMENT_SUMMARY,
    payload: error,
  };
};

export const startFetchPayDetail = (
  transferDate: string,
  termStartDate: string,
  termEndDate: string,
  akrCode: string,
  deadlineDate: string,
  productManagementId: string
): StartFetchPayDetailAction => {
  return {
    type: START_FETCH_PAY_DETAIL,
    payload: {
      transferDate,
      termStartDate,
      termEndDate,
      akrCode,
      deadlineDate,
      productManagementId,
    },
  };
};

export const successFetchPayDetail = (data: PayDetail): SuccessFetchPayDetailAction => {
  return {
    type: SUCCESS_FETCH_PAY_DETAIL,
    payload: data,
  };
};

export const failFetchPayDetail = (error: ErrorType): FailFetchPayDetailAction => {
  return {
    type: FAIL_FETCH_PAY_DETAIL,
    payload: error,
  };
};

export const startFetchPayQrDetail = (
  transferDate: string,
  termStartDate: string,
  termEndDate: string,
  akrCode: string,
  deadlineDate: string,
  productManagementId: string
): StartFetchPayQrDetailAction => {
  return {
    type: START_FETCH_PAY_QR_DETAIL,
    payload: {
      transferDate,
      termStartDate,
      termEndDate,
      akrCode,
      deadlineDate,
      productManagementId,
    },
  };
};

export const successFetchPayQrDetail = (data: PayQrDetail): SuccessFetchPayQrDetailAction => {
  return {
    type: SUCCESS_FETCH_PAY_QR_DETAIL,
    payload: data,
  };
};

export const failFetchPayQrDetail = (error: ErrorType): FailFetchPayQrDetailAction => {
  return {
    type: FAIL_FETCH_PAY_QR_DETAIL,
    payload: error,
  };
};

export const startFetchGopDetail = (
  transferDate: string,
  termStartDate: string,
  termEndDate: string,
  productManagementId: string,
  akrCode: string
): StartFetchGopDetailAction => {
  return {
    type: START_FETCH_GOP_DETAIL,
    payload: {
      transferDate,
      termStartDate,
      termEndDate,
      akrCode,
      productManagementId,
    },
  };
};

export const successFetchGopDetail = (data: GopDetail): SuccessFetchGopDetailAction => {
  return {
    type: SUCCESS_FETCH_GOP_DETAIL,
    payload: data,
  };
};

export const failFetchGopDetail = (error: ErrorType): FailFetchGopDetailAction => {
  return {
    type: FAIL_FETCH_GOP_DETAIL,
    payload: error,
  };
};

export const startFetchPaymentCalendar = (
  date: string,
  storeList: ReadonlyArray<string>
): StartFetchPaymentCalendarAction => {
  return {
    type: START_FETCH_PAYMENT_CALENDAR,
    payload: { date, storeList },
  };
};

export const successFetchPaymentCalendar = (data: MonthlyTransferInfo): SuccessFetchPaymentCalendarAction => {
  return {
    type: SUCCESS_FETCH_PAYMENT_CALENDAR,
    payload: data,
  };
};

export const failFetchPaymentCalendar = (error: ErrorType): FailFetchPaymentCalendarAction => {
  return {
    type: FAIL_FETCH_PAYMENT_CALENDAR,
    payload: error,
  };
};

export const startFetchPaymentTransferInfoDetail = (
  date: string,
  storeList: ReadonlyArray<string>
): StartFetchPaymentTransferInfoDetailAction => {
  return {
    type: START_FETCH_TRANSFER_INFO_DETAIL,
    payload: { date, storeList },
  };
};

export const successFetchPaymentTransferInfoDetail = (
  data: TransferInfoDetail
): SuccessFetchPaymentTransferInfoDetailAction => {
  return {
    type: SUCCESS_FETCH_TRANSFER_INFO_DETAIL,
    payload: data,
  };
};

export const failFetchPaymentTransferInfoDetail = (
  error: ErrorType
): FailFetchPaymentTransferInfoDetailAction => {
  return {
    type: FAIL_FETCH_TRANSFER_INFO_DETAIL,
    payload: error,
  };
};

export const actions = {
  startFetchPaymentMaster,
  successFetchPaymentMaster,
  failFetchPaymentMaster,
  startFetchPaymentSummary,
  successFetchPaymentSummary,
  failFetchPaymentSummary,
  startFetchPayDetail,
  successFetchPayDetail,
  failFetchPayDetail,
  startFetchPayQrDetail,
  successFetchPayQrDetail,
  failFetchPayQrDetail,
  startFetchGopDetail,
  successFetchGopDetail,
  failFetchGopDetail,
  startFetchPaymentCalendar,
  successFetchPaymentCalendar,
  failFetchPaymentCalendar,
  startFetchPaymentTransferInfoDetail,
  successFetchPaymentTransferInfoDetail,
  failFetchPaymentTransferInfoDetail,
};

export const initialState: State = {
  paymentMasterState: apiState.initial(),
  paymentSummaryState: apiState.initial(),
  payDetailState: apiState.initial(),
  payQrDetailState: apiState.initial(),
  gopDetailState: apiState.initial(),
  paymentCalendarState: apiState.initial(),
  PaymentTransferInfoDetailState: apiState.initial(),
};

const reducer = (state: State = initialState, action?: Action): State => {
  if (action == null) {
    return state;
  }

  switch (action.type) {
    case START_FETCH_PAYMENT_MASTER:
      return { ...state, paymentMasterState: apiState.started() };

    case SUCCESS_FETCH_PAYMENT_MASTER:
      return { ...state, paymentMasterState: apiState.completed(action.payload) };

    case FAIL_FETCH_PAYMENT_MASTER:
      return { ...state, paymentMasterState: apiState.failed(action.payload) };

    case START_FETCH_PAYMENT_SUMMARY:
      return { ...state, paymentSummaryState: apiState.started() };

    case SUCCESS_FETCH_PAYMENT_SUMMARY:
      return { ...state, paymentSummaryState: apiState.completed(action.payload) };

    case FAIL_FETCH_PAYMENT_SUMMARY:
      return { ...state, paymentSummaryState: apiState.failed(action.payload) };

    case START_FETCH_PAY_DETAIL:
      return { ...state, payDetailState: apiState.started() };

    case SUCCESS_FETCH_PAY_DETAIL:
      return { ...state, payDetailState: apiState.completed(action.payload) };

    case FAIL_FETCH_PAY_DETAIL:
      return { ...state, payDetailState: apiState.failed(action.payload) };

    case START_FETCH_PAY_QR_DETAIL:
      return { ...state, payQrDetailState: apiState.started() };

    case SUCCESS_FETCH_PAY_QR_DETAIL:
      return { ...state, payQrDetailState: apiState.completed(action.payload) };

    case FAIL_FETCH_PAY_QR_DETAIL:
      return { ...state, payQrDetailState: apiState.failed(action.payload) };

    case START_FETCH_GOP_DETAIL:
      return { ...state, gopDetailState: apiState.started() };

    case SUCCESS_FETCH_GOP_DETAIL:
      return { ...state, gopDetailState: apiState.completed(action.payload) };

    case FAIL_FETCH_GOP_DETAIL:
      return { ...state, gopDetailState: apiState.failed(action.payload) };

    case START_FETCH_PAYMENT_CALENDAR:
      return { ...state, paymentCalendarState: apiState.started() };

    case SUCCESS_FETCH_PAYMENT_CALENDAR:
      return { ...state, paymentCalendarState: apiState.completed(action.payload) };

    case FAIL_FETCH_PAYMENT_CALENDAR:
      return { ...state, paymentCalendarState: apiState.failed(action.payload) };

    case START_FETCH_TRANSFER_INFO_DETAIL:
      return { ...state, PaymentTransferInfoDetailState: apiState.started() };

    case SUCCESS_FETCH_TRANSFER_INFO_DETAIL:
      return { ...state, PaymentTransferInfoDetailState: apiState.completed(action.payload) };

    case FAIL_FETCH_TRANSFER_INFO_DETAIL:
      return { ...state, PaymentTransferInfoDetailState: apiState.failed(action.payload) };

    default:
      return state;
  }
};

export default reducer;
