// 訴求共通コンポーネントの1番目のヘッダー(○○とは)

import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { lightBlue } from '../../../../constants/colors';
import { AppealModal } from '../AppealModal';
import { PRODUCT_TYPE } from '../../../../constants/appealModal';
import { ProductDescriptionContent } from './ProductDescriptionContent';

type Props = ComponentProps<typeof AppealModal>;

export const ProductDescription = (
  props: Pick<Props, 'productType' | 'tracker' | 'isRegiUse' | 'akrCode' | 'from'>
) => {
  const { productType, tracker, isRegiUse, akrCode, from } = props;
  switch (productType) {
    case PRODUCT_TYPE.MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_MODAL_SHIFT:
    case PRODUCT_TYPE.DAILYREPORT_INPUT_MODAL_SHIFT:
    case PRODUCT_TYPE.PRODUCTLIST_SHIFT:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_SHIFT:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_SHIFT_SUMMARY:
    case PRODUCT_TYPE.LABORCOSTANALYSIS_SHIFT:
    case PRODUCT_TYPE.DAILYREPORT_SHIFT:
    case PRODUCT_TYPE.REALTIME_SHIFT:
    case PRODUCT_TYPE.STORE_MONTHLY_INDICES_SHIFT:
    case PRODUCT_TYPE.STORE_INDICES_SHIFT:
    case PRODUCT_TYPE.DAILYDETAILPAST_SHIFT:
    case PRODUCT_TYPE.DAILYDETAILFUTURE_SHIFT:
      return (
        <React.Fragment>
          <ProductDescriptionHeader>「Airシフト」とは</ProductDescriptionHeader>
          <ProductDescriptionContent productType={productType} />
        </React.Fragment>
      );
    case PRODUCT_TYPE.DAILYREPORT_CARD:
    case PRODUCT_TYPE.PRODUCTLIST_CARD:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CARD:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CARD_SUMMARY:
      return (
        <React.Fragment>
          <ProductDescriptionHeader>「Airカード」とは</ProductDescriptionHeader>
          <ProductDescriptionContent productType={productType} />
        </React.Fragment>
      );
    case PRODUCT_TYPE.PRODUCTLIST_OES:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_OES:
    case PRODUCT_TYPE.LABORCOSTANALYSIS_OES:
    case PRODUCT_TYPE.REALTIME_OES:
    case PRODUCT_TYPE.STORE_MONTHLY_INDICES_OES:
    case PRODUCT_TYPE.STORE_INDICES_OES:
    case PRODUCT_TYPE.DAILYDETAILPAST_OES:
      return (
        <React.Fragment>
          <ProductDescriptionHeader>「Airレジ オーダー」とは</ProductDescriptionHeader>
          <ProductDescriptionContent productType={productType} />
        </React.Fragment>
      );
    case PRODUCT_TYPE.PRODUCTLIST_RB:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_RB:
    case PRODUCT_TYPE.REALTIME_RB:
    case PRODUCT_TYPE.DAILYDETAILPAST_RB:
    case PRODUCT_TYPE.DAILYDETAILFUTURE_RB:
      return (
        <React.Fragment>
          <ProductDescriptionHeader>「レストランボード」とは</ProductDescriptionHeader>
          <ProductDescriptionContent productType={productType} />
        </React.Fragment>
      );
    case PRODUCT_TYPE.PRODUCTLIST_REGI:
    case PRODUCT_TYPE.MENU_ANALYSIS_REGI:
    case PRODUCT_TYPE.DAILYDETAILPAST_REGI:
    case PRODUCT_TYPE.DAILYREPORT_EMPTYSTATE_REGI:
    case PRODUCT_TYPE.MONTHLYLOOKBACK_EMPTYSTATE_REGI:
      return (
        <React.Fragment>
          <ProductDescriptionHeader>「Airレジ」とは</ProductDescriptionHeader>
          <ProductDescriptionContent productType={productType} />
        </React.Fragment>
      );
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_REGI_PAYQR:
      return (
        <StoreCalendarIndicesRegiWrapper>
          <ProductDescriptionContent
            productType={productType}
            tracker={tracker}
            isRegiUse={isRegiUse}
            akrCode={akrCode}
            from={from}
          />
        </StoreCalendarIndicesRegiWrapper>
      );
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CASH:
      return (
        <React.Fragment>
          <ProductDescriptionHeader>「Airキャッシュ」とは</ProductDescriptionHeader>
          <ProductDescriptionContent productType={productType} tracker={tracker} />
        </React.Fragment>
      );
    case PRODUCT_TYPE.DAYOFWEEKHOURLY_REGI_NOT_USE:
    case PRODUCT_TYPE.REALTIME_REGI:
      return (
        <React.Fragment>
          <ProductDescriptionHeader>「Airレジ」とは</ProductDescriptionHeader>
          <ProductDescriptionContent productType={productType} isRegiUse={isRegiUse} tracker={tracker} />
        </React.Fragment>
      );
    case PRODUCT_TYPE.DAYOFWEEKHOURLY_REGI_USE:
      return (
        <DayofweekContentWrapper>
          <ProductDescriptionContent productType={productType} isRegiUse={isRegiUse} tracker={tracker} />
        </DayofweekContentWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_PAY:
      return (
        <React.Fragment>
          <ProductDescriptionHeader>「Airペイ」とは</ProductDescriptionHeader>
          <ProductDescriptionContent productType={productType} tracker={tracker} />
        </React.Fragment>
      );
    case PRODUCT_TYPE.PRODUCTLIST_MPA:
      return (
        <React.Fragment>
          <ProductDescriptionHeader>「Airペイ QR」とは</ProductDescriptionHeader>
          <ProductDescriptionContent productType={productType} tracker={tracker} />
        </React.Fragment>
      );
    case PRODUCT_TYPE.PRODUCTLIST_INVOICE:
      return (
        <React.Fragment>
          <ProductDescriptionHeader>「Airインボイス」とは</ProductDescriptionHeader>
          <ProductDescriptionContent productType={productType} tracker={tracker} />
        </React.Fragment>
      );
    default:
      return null;
  }
};

const ProductDescriptionHeader = styled.h3`
  font-size: 16px;
  font-weight: bold;
  margin: 32px 32px 0;
  padding: 2px 0 0 12px;
  background-color: ${lightBlue};
  font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
`;

const StoreCalendarIndicesRegiWrapper = styled.div`
  padding-bottom: 48px;
`;
const DayofweekContentWrapper = styled.div`
  padding-bottom: 48px;
`;
