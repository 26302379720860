import * as React from 'react';
import styled from 'styled-components';
import { bgGray, black, uploadBorderColor, white } from '../../../constants/colors';
import { track } from '../../../modules/logging';
import { SelectedType, TableProperties } from '../../../typedef/api/AllMenu';
import { genGaLog } from '../../../gaLogger';
import { State as SearchState } from '../../../modules/allMenu/search';

type Props = {
  readonly selectedType: SelectedType;
  readonly changeSelectedType: (selectedType: SelectedType) => void;
  readonly logger: typeof track;
  readonly searchState: SearchState;
  readonly setTableProperties: (tableProperties: TableProperties) => any;
  readonly tempSelectedItem: Set<string>;
};

export const AllMenuToggle = (props: Props) => {
  const { selectedType, changeSelectedType, logger, searchState, setTableProperties, tempSelectedItem } =
    props;
  return (
    <ToggleWrapper>
      <MenuButton
        onClick={() => {
          changeSelectedType('menu');
          localStorage.setItem('selectedTypeForMenuAnalysis', 'menu');
          logger(_genChangeAnalysisType());
          setTableProperties({
            ...searchState.tableProperties,
            filter: Array.from(tempSelectedItem),
          });
        }}
        selectedType={selectedType}
        disabled={selectedType === 'menu'}
      >
        商品別
      </MenuButton>
      <CategoryButton
        onClick={() => {
          changeSelectedType('category');
          localStorage.setItem('selectedTypeForMenuAnalysis', 'category');
          logger(_genChangeAnalysisType());
          setTableProperties({
            ...searchState.tableProperties,
            filter: Array.from(
              new Set(
                searchState.searchCondition.includesUndefinedCategory
                  ? searchState.searchCondition.selectedCategories.concat('カテゴリー未設定')
                  : searchState.searchCondition.selectedCategories
              )
            ),
          });
        }}
        selectedType={selectedType}
        disabled={selectedType === 'category'}
      >
        カテゴリー別
      </CategoryButton>
    </ToggleWrapper>
  );
};

const _genChangeAnalysisType = () => {
  return genGaLog('menu_analysis', 'select_toggle', 'select_analysis_type', {}, {}, 'click');
};

const ToggleWrapper = styled.div`
  width: 224px;
  display: flex;
  background: ${bgGray};
  padding: 4px;
  border-radius: 8px;
  justify-content: space-around;
  margin-top: -20px;
  margin-right: 16px;
`;

const ButtonBase = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  padding: 10px 0;
  background-color: ${white};
  border-radius: 6px;
  border: 1px solid ${uploadBorderColor};
  font-size: 16px;
  color: ${black};
  cursor: pointer;
`;

const MenuButton = styled(ButtonBase)<{ selectedType: SelectedType }>`
  width: 100px;
  ${props =>
    props.selectedType !== 'menu' &&
    `
  border: none;
  background-color: ${bgGray};
  `}
`;

const CategoryButton = styled(ButtonBase)<{ selectedType: SelectedType }>`
  width: 116px;
  ${props =>
    props.selectedType !== 'category' &&
    `
  border: none;
  background-color: ${bgGray};
  `}
`;
