import { take, put, call, select } from 'redux-saga/effects';
import { State as RootState } from '../../modules';
import { types } from '../../modules/allMenu/csvDownload';
import { actions } from '../../modules/allMenu/csvDownload';
import AllMenuAPI from '../../services/allMenuAPI';
import { SearchCondition, SelectedType, TableProperties } from '../../typedef/api/AllMenu';
import { eqs } from '../../helpers/util';
import { ErrorType } from '../../typedef/api/Utility';
import { formatter, parser } from '../../helpers/mclDate';

export function* downloadMenuResults() {
  while (true) {
    yield take(types.START_MENU_DOWNLOAD);
    const searchCondition: SearchCondition = yield select(
      (state: RootState) => state.allMenu.search.searchCondition
    );
    const tableProperties: TableProperties = yield select(
      (state: RootState) => state.allMenu.search.tableProperties
    );
    const selectedType: SelectedType = yield select((state: RootState) => state.allMenu.ui.selectedType);

    // カテゴリー未設定はフラグで送らないといけないので分割する
    const filter = tableProperties.filter.includes('')
      ? tableProperties.filter.filter(item => !eqs(item, ''))
      : tableProperties.filter;
    const {
      error,
    }: {
      readonly error: ErrorType;
    } = yield call(AllMenuAPI.downloadCsv, {
      akrCodes: searchCondition.selectedAkrCodes,
      visitTimeTypes: searchCondition.selectedLunchDinner,
      analysisTagGroup: searchCondition.selectedAnalysisTags,
      categories: searchCondition.selectedCategories,
      businessDateFrom: parser.fromDateObject(searchCondition.businessDateFrom).format(formatter.mapiDate),
      businessDateTo: parser.fromDateObject(searchCondition.businessDateTo).format(formatter.mapiDate),
      includesFreeMenu: searchCondition.includesFreeMenu,
      includesUndefinedCategory: searchCondition.includesUndefinedCategory,
      filterCategories: filter,
      sortItem: (tableProperties.sortBy + 'Rate') as
        | 'totalOrderNumRate'
        | 'totalSalesRate'
        | 'totalGrossProfitRate',
      sortType: tableProperties.sortReverse ? 'asc' : 'desc',
      includesUndefinedCategoryInFilter: tableProperties.filter.includes('カテゴリー未設定'),
      downloadType: selectedType,
    });

    if (error) {
      // TODO: エラーハンドリング
      // yield put(actions.failMenuDownload());
    } else {
      yield put(actions.successMenuDownload());
    }
  }
}
