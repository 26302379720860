/**
 * Realtime InactiveItem
 */
import React from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import {
  RealtimeType,
  REALTIME_TYPE_FORECAST,
  REALTIME_TYPE_SEAT_STATUS,
  REALTIME_TYPE_SHIFTS,
  REALTIME_TYPE_RESERVE_DETAIL,
  REALTIME_TYPE_SP_LEAD,
  APPEAL_MODAL_TYPE,
} from '../../../realtimeConstants';
import { airGray } from '../../../../../../constants/colors';
import TitleLogoWrapper from './TitleLogoWrapper';
import { assertNever } from '../../../../../../helpers/util';
import UnusedHandyRealtimeImage from '../../../../../../icons/unusedHandyRealtime.svg';
import LogoAirOrder from '../../../../../../icons/oesLogo.svg';
import LogoAirShift from '../../../../../../icons/LogoAirShift.svg';
import LogoRestaurantBoard from '../../../../../../icons/LogoRestaurantBoardOrange.png';
import ArrowRight from '../../../../../../icons/ArrowRightAirBlue.png';
import UnusedShiftRealtimeImage from '../../../../../../icons/unusedShiftRealtime.png';
import UnusedRestaurantBoardRealtimeImage from '../../../../../../icons/unusedRestaurantBoardRealtime.svg';

type Props = {
  readonly contentType: RealtimeType;
  onEnter: (contentType: RealtimeType) => void;
  readonly akrCode: String;
  openModal: (productName: keyof typeof APPEAL_MODAL_TYPE) => void;
};

const InactiveText = ({ contentType, onEnter, akrCode, openModal }: Props) => {
  const _handleClickAutoAhd = () => {
    openModal('oes');
  };

  const _handleClickAutoShift = () => {
    openModal('shift');
  };

  const _handleClickAutoRb = () => {
    openModal('rb');
  };

  switch (contentType) {
    case REALTIME_TYPE_SEAT_STATUS:
      return (
        <Waypoint onEnter={() => onEnter(contentType)} key={contentType + akrCode}>
          <UnusedWrapper width={476} onClick={() => _handleClickAutoAhd()}>
            <UnusedModal width={200}>
              <Text>来店・注文の状況を自動で連携</Text>
              <LogoWrapper>
                <StyledLogoAirOrder />
                <StyledArrowRight src={ArrowRight} />
              </LogoWrapper>
            </UnusedModal>
            <StyledUnusedHandyRealtimeImage />
          </UnusedWrapper>
        </Waypoint>
      );
    case REALTIME_TYPE_SHIFTS:
      return (
        <Waypoint onEnter={() => onEnter(contentType)} key={contentType + akrCode}>
          <UnusedWrapper width={474} onClick={() => _handleClickAutoShift()}>
            <UnusedModal width={180}>
              <Text>シフト・勤怠を自動で連携</Text>
              <LogoWrapper>
                <StyledLogoAirShift />
                <StyledArrowRight src={ArrowRight} />
              </LogoWrapper>
            </UnusedModal>
            <StyledUnusedShiftRealtimeImage src={UnusedShiftRealtimeImage} />
          </UnusedWrapper>
        </Waypoint>
      );
    case REALTIME_TYPE_RESERVE_DETAIL:
      return (
        <Waypoint onEnter={() => onEnter(contentType)} key={contentType + akrCode}>
          <UnusedWrapper width={474} onClick={() => _handleClickAutoRb()}>
            <UnusedModal width={180}>
              <Text>予約コースを自動で連携</Text>
              <LogoWrapper>
                <StyledLogoRestaurantBoard src={LogoRestaurantBoard} />
                <StyledArrowRight src={ArrowRight} />
              </LogoWrapper>
            </UnusedModal>
            <StyledUnusedRestaurantBoardRealtimeImage />
          </UnusedWrapper>
        </Waypoint>
      );
    case REALTIME_TYPE_FORECAST:
    case REALTIME_TYPE_SP_LEAD:
      return null;
    default:
      return assertNever(contentType);
  }
};

const InactiveItem = (props: Props) => {
  const { contentType, onEnter, akrCode, openModal } = props;

  return (
    <TitleLogoWrapper contentType={contentType}>
      <InactiveText contentType={contentType} onEnter={onEnter} akrCode={akrCode} openModal={openModal} />
    </TitleLogoWrapper>
  );
};

const UnusedWrapper = styled.div<{ width: number }>`
  width: 100%;
  margin: 0 auto;
  max-width: ${props => props.width}px;
  position: relative;
  text-align: center;
  cursor: pointer;
`;

const UnusedModal = styled.div<{ width: number }>`
  width: ${props => props.width}px;
  height: 68px;
  background: white;
  border: 1px solid ${airGray};
  border-radius: 5px;
  box-shadow: ${airGray} 0px 1px 6px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 12px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const StyledUnusedHandyRealtimeImage = styled(UnusedHandyRealtimeImage)`
  width: 100%;
  max-width: 476px;
`;

const StyledUnusedShiftRealtimeImage = styled.img`
  width: 100%;
  max-width: 474px;
  max-height: 242px;
`;

const StyledUnusedRestaurantBoardRealtimeImage = styled(UnusedRestaurantBoardRealtimeImage)`
  width: 100%;
  max-width: 474px;
`;

const StyledLogoAirOrder = styled(LogoAirOrder)`
  width: 107px;
  height: 14px;
`;

const StyledLogoAirShift = styled(LogoAirShift)`
  width: 77px;
  height: 14px;
`;

export const StyledLogoRestaurantBoard = styled.img`
  width: 81px;
  height: 26px;
`;

const StyledArrowRight = styled.img`
  cursor: pointer;
  margin-left: 10px;
  width: 7px;
  height: 12px;
`;

export default InactiveItem;
