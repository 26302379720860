import { call, take, put, select, all } from 'redux-saga/effects';
import { State as RootState } from '../../modules';
import {
  START_SEARCH_MENU,
  actions as searchActions,
  INITIAL_SEARCH_MENU,
} from '../../modules/allMenu/search';
import { SUCCESS_GET_TAG_GROUP, startGetTagGroup } from '../../modules/allMenu/analysisTagGroup';
import { SUCCESS_GET_CATEGORIES, startGetCategories } from '../../modules/allMenu/categories';
import AllMenuAPI from '../../services/allMenuAPI';
import { MenuTotalizationResponse, TableProperties } from '../../typedef/api/AllMenu';
import { StoresData, FETCH_USER_INFO_SUCCESS } from '../../modules/user';
import { selectStores } from '../../modules/allMenu/form';
import { FETCH_ASSIGNED_STORES_FINISH } from '../../modules/assignedStores';
import { ErrorType } from '../../typedef/api/Utility';
import { assignedStoresSelector } from '../../selectors/userDataSelector';
import { LocalDateObj, formatter, parser } from '../../helpers/mclDate';
import { AC } from '../../constants/requestParameter';

export function* searchSaga() {
  while (true) {
    yield take(START_SEARCH_MENU);
    yield call(executeSearch);
  }
}

export function* initialSearchSaga() {
  while (true) {
    yield all([take(INITIAL_SEARCH_MENU), take(FETCH_USER_INFO_SUCCESS), take(FETCH_ASSIGNED_STORES_FINISH)]);

    const stores: ReadonlyArray<StoresData> = yield select((state: RootState) =>
      assignedStoresSelector(state)
    );

    // 初期は全ての店舗を選択している状態にする
    const defaultStores = stores.map(store => store.akrCode);
    /**
     * 下記の場合、クエリパラメータacに付与されているAKRコードの店舗を初期表示に設定する
     * ・クエリパラメータacが付与されており、正常に取得できる
     * ・acのAKRコードが店舗一覧のAKRコードに含まれている
     */
    const acAkrCode: Set<string> = new Set();
    const url: URLSearchParams = new URLSearchParams(window.location.search);
    const hasAc: boolean = url.has(AC);
    const acParam: string | null = url.get(AC);
    const isAcParamInAkrCodes: boolean = defaultStores.some(akrCode => akrCode === acParam);
    if (hasAc && acParam != null && isAcParamInAkrCodes) {
      acAkrCode.add(acParam);
      yield put(selectStores(acAkrCode));
      yield put(startGetCategories(acAkrCode));
    } else {
      yield put(selectStores(new Set(defaultStores)));
      yield put(startGetCategories(new Set(defaultStores)));
    }
    yield put(startGetTagGroup());
    yield all([take(SUCCESS_GET_TAG_GROUP), take(SUCCESS_GET_CATEGORIES)]);
    yield call(executeSearch);
  }
}

function* executeSearch() {
  const akrCodes: Set<string> = yield select((state: RootState) => state.allMenu.form.selectedStores);
  const lunchDinner: Set<string> = yield select((state: RootState) => state.allMenu.form.selectedLunchDinner);
  const businessDateFrom: LocalDateObj = yield select(
    (state: RootState) => state.allMenu.form.businessDateFrom
  );
  const businessDateTo: LocalDateObj = yield select((state: RootState) => state.allMenu.form.businessDateTo);
  const analysisTags: Set<string> = yield select(
    (state: RootState) => state.allMenu.form.selectedAnalysisTags
  );
  const displayCategories: Set<string> = yield select(
    (state: RootState) => state.allMenu.form.selectedDisplayCategories
  );
  const hiddenCategories: Set<string> = yield select(
    (state: RootState) => state.allMenu.form.selectedHiddenCategories
  );
  const includesFreeMenu: boolean = yield select((state: RootState) => state.allMenu.form.includesFreeMenu);
  const includesUndefinedCategory: boolean = yield select(
    (state: RootState) => state.allMenu.form.includesUndefinedCategory
  );
  const tableProperties: TableProperties = yield select(
    (state: RootState) => state.allMenu.search.tableProperties
  );
  const tablePropertiesByCategory: TableProperties = yield select(
    (state: RootState) => state.allMenu.search.tablePropertiesByCategory
  );

  /**
   * core/v1/menu/analysis:POSTで対象の分析タグが全て/一部を判定する
   * true: すべての場合(検索フォーム上「すべて」と表記される状態のこと)
   * false: 一部の場合
   */
  const isSearchedAllAnalysisTags: boolean = yield select(
    (state: RootState) =>
      state.allMenu.tagGroup.loadStatus.type === 'loaded' &&
      state.allMenu.tagGroup.loadStatus.payload.analysisTagGroup.length === analysisTags.size
  );

  /* searchSaga()経由時にはパラメータ(akrCodes,analysisTags)が空のためAllMenuAPIを呼ばない */
  if (Array.from(akrCodes).length !== 0 || Array.from(analysisTags).length !== 0) {
    const {
      payload,
      error,
    }: {
      readonly payload: MenuTotalizationResponse;
      readonly error: ErrorType;
    } = yield call(AllMenuAPI.getDetail, {
      akrCodes: Array.from(akrCodes),
      visitTimeTypes: Array.from(lunchDinner),
      analysisTagGroup: Array.from(analysisTags),
      categories: [...displayCategories, ...hiddenCategories],
      businessDateFrom: parser.fromDateObject(businessDateFrom).format(formatter.mapiDate),
      businessDateTo: parser.fromDateObject(businessDateTo).format(formatter.mapiDate),
      includesFreeMenu,
      includesUndefinedCategory,
    });

    if (error) {
      // TODO: エラーハンドリング
      // yield put(searchActions.failSearchMenu(error));
    } else if (payload) {
      yield put(
        searchActions.successSearchMenu(
          payload,
          {
            selectedAkrCodes: Array.from(akrCodes),
            selectedLunchDinner: Array.from(lunchDinner),
            selectedAnalysisTags: Array.from(analysisTags),
            selectedCategories: [...displayCategories, ...hiddenCategories],
            businessDateFrom,
            businessDateTo,
            includesFreeMenu,
            includesUndefinedCategory,
            isSearchedAllAnalysisTags,
          },
          {
            ...tableProperties,
            sortBy: 'totalOrderNum',
            sortReverse: false,
            // カテゴリー未設定を追加する
            filter: includesUndefinedCategory
              ? [...displayCategories, ...hiddenCategories, 'カテゴリー未設定']
              : [...displayCategories, ...hiddenCategories],
          },
          {
            ...tablePropertiesByCategory,
            sortBy: 'totalOrderNum',
            sortReverse: false,
            // カテゴリー未設定を追加する
            filter: includesUndefinedCategory
              ? [...displayCategories, ...hiddenCategories, 'カテゴリー未設定']
              : [...displayCategories, ...hiddenCategories],
          }
        )
      );
    }
  }
}
