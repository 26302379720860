/**
 * 入金情報サマリテーブル
 */
import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import {
  gray,
  attractSecond,
  rowRed,
  lightgray,
  textLinkColor,
  black,
  disabledTextColor,
  white,
  lightOrange,
  orangeBorder,
  airGray,
  uploadBorderColor,
} from '../../../../constants/colors';
import {
  PaymentSummary,
  TransferInfoSummary,
  GopTransferInfoSummary,
} from '../../../../typedef/api/Payment/PaymentSummary';
import {
  baseFontSize,
  LocaleInteger,
  prefixUnit,
  redBlack0,
} from '../../../../components/common/atoms/Number';
import { StoresData } from '../../../../modules/user';
import { PRODUCT_CODE, TRANSFER_STATUS, GOP_TRANSFER_STATUS } from '../transferConstants';
import Tooltip from '../../../../components/common/molecules/Tooltip';
import { genGaLog } from '../../../../gaLogger';
import { track } from '../../../../modules/logging';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';

type Props = {
  readonly storesWithTransferInfoSummary: ReadonlyArray<
    (TransferInfoSummary | GopTransferInfoSummary) & StoresData
  >;
  readonly paymentSummary: PaymentSummary;
  readonly logging: typeof track;
  readonly onClickPayDetail: (
    transferDate: string,
    termStartDate: string,
    termEndDate: string,
    akrCode: string,
    deadlineDate: string,
    productManagementId: string,
    productCode: string
  ) => void;
  readonly isSingleStore: boolean;
};

const FormatYen = baseFontSize(16)(redBlack0(prefixUnit('¥')(LocaleInteger)));
const FormatYenSmall = baseFontSize(12)(prefixUnit('¥')(LocaleInteger));

const SummaryTable = (props: Props) => {
  const { storesWithTransferInfoSummary, paymentSummary, logging, onClickPayDetail, isSingleStore } = props;
  const { totalTransferPrice, totalSales, totalCommission, waitingTotalSales } =
    paymentSummary.transferInfoSummary;
  const data = _.orderBy(storesWithTransferInfoSummary, ['transferDate'], ['asc']);
  const isNoData = data.length === 0;
  return (
    <TableWrapper>
      <Table isNoData={isNoData}>
        <thead>
          <Tr>
            <Th>入金日</Th>
            <Th>
              <ThWrapper>
                入金金額
                <StyledUpperRightTooltip
                  viewName={'transfer'}
                  feature={'paymentDate_list'}
                  name={'depositAmount'}
                >
                  「入金金額 = 合計決済金額 - 手数料」です。
                  <br />
                  <br />
                  Airキャッシュを利用している場合は、「入金金額 = 合計決済金額 - 手数料 -
                  自動引き落とし分」となります。
                </StyledUpperRightTooltip>
              </ThWrapper>
            </Th>
            <Th>
              <ThWrapper>
                合計決済金額
                <StyledUpperRightTooltip
                  viewName={'transfer'}
                  feature={'paymentDate_list'}
                  name={'totalPaymentAmount'}
                >
                  決済金額の合計です。
                </StyledUpperRightTooltip>
              </ThWrapper>
            </Th>
            <Th>
              <ThWrapper>
                手数料
                <StyledUpperRightTooltip
                  viewName={'transfer'}
                  feature={'paymentDate_list'}
                  name={'commission'}
                >
                  【Airペイ／Airペイ QR】
                  <br />
                  「手数料 = 決済手数料 (合計決済金額 × 手数料率) + 手数料消費税」です。
                  <br />
                  <br />
                  手数料率はAirペイやAirペイ QRの契約情報をご確認ください。
                  <br />
                  <br />
                  手数料消費税は、決済方法により0円（非課税）の場合もあります。
                  <br />
                  <br />
                  【オンライン決済】
                  <br />
                  「手数料 = 決済手数料 (合計決済金額 × 手数料率) + 振込手数料」です。
                </StyledUpperRightTooltip>
              </ThWrapper>
            </Th>
            <Th width={150}>決済期間</Th>
            {!isSingleStore && <Th width={200}>店舗</Th>}
          </Tr>
        </thead>
        <tbody>
          {isNoData ? (
            <Tr>
              <NoData colSpan={6}>データがありません。</NoData>
            </Tr>
          ) : (
            data.map((d, i) => {
              return (
                <Tr key={i}>
                  <Td
                    leftAlign
                    isTransferDate
                    isFontBold
                    isPointer
                    onClick={() => {
                      const productCode = d.productCode != null ? d.productCode : 'gop';
                      onClickPayDetail(
                        d.transferDate,
                        d.termStartDate,
                        d.termEndDate,
                        d.akrCode,
                        d.deadlineDate != null ? d.deadlineDate : '',
                        d.productManagementId,
                        productCode
                      );
                      logging(_genOpenDetailLog(productCode, d.transferDate, d.akrCode, d.transferStatus));
                    }}
                  >
                    {d.transferDate.replace(/-/g, '/')}
                    <Tag>{d.productCode != null ? PRODUCT_CODE[d.productCode] : PRODUCT_CODE['gop']}</Tag>
                  </Td>
                  <Td isFontBold>
                    <TdWrapper>
                      {/* 振込状況が入金済み以外は振込状況を表示する */}
                      {d.productCode != null ? (
                        <React.Fragment>
                          {d.transferStatus !== TRANSFER_STATUS.paid.key && (
                            <TransferStatusWrapper transferStatus={d.transferStatus}>
                              {getTransferStatusName(d.transferStatus)}
                            </TransferStatusWrapper>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {d.transferStatus !== GOP_TRANSFER_STATUS.paid.key && (
                            <GopTransferStatusWrapper transferStatus={d.transferStatus}>
                              {getGopTransferStatusName(d.transferStatus)}
                            </GopTransferStatusWrapper>
                          )}
                        </React.Fragment>
                      )}
                      <TransferPriceWrapper>
                        {/* GOP(=productCodeがnull) かつ transferStatusが1か2なら「連携待ち」表示 */}
                        {d.productCode != null ? (
                          <React.Fragment>
                            {d.transferPrice != null ? <FormatYen value={d.transferPrice} /> : null}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {d.transferStatus === GOP_TRANSFER_STATUS.beforeFileReceive.key ||
                            d.transferStatus === GOP_TRANSFER_STATUS.paymentPlan.key ? (
                              <WaitingCooperate>連携待ち</WaitingCooperate>
                            ) : d.transferPrice != null ? (
                              <FormatYen value={d.transferPrice} />
                            ) : null}
                          </React.Fragment>
                        )}
                      </TransferPriceWrapper>
                    </TdWrapper>
                  </Td>
                  <Td>
                    {/* GOP(=productCodeがnull) かつ transferStatusが1か2なら丸カッコ付きで金額を表示 */}
                    {d.productCode != null ? (
                      <React.Fragment>
                        <FormatYen value={d.sales} />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {d.sales != null ? (
                          <React.Fragment>
                            {d.transferStatus === GOP_TRANSFER_STATUS.beforeFileReceive.key ||
                            d.transferStatus === GOP_TRANSFER_STATUS.paymentPlan.key ? (
                              <FormatYenSmallWrapper>
                                &#40;
                                <FormatYenSmall value={d.sales} />
                                &#41;
                              </FormatYenSmallWrapper>
                            ) : (
                              <FormatYen value={d.sales} />
                            )}
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    )}
                  </Td>
                  <Td>
                    {/* GOP(=productCodeがnull) かつ transferStatusが1か2なら「連携待ち」表示 */}
                    {d.productCode != null ? (
                      <React.Fragment>
                        {d.commission != null ? <FormatYen value={d.commission} /> : null}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {d.transferStatus === GOP_TRANSFER_STATUS.beforeFileReceive.key ||
                        d.transferStatus === GOP_TRANSFER_STATUS.paymentPlan.key ? (
                          <WaitingCooperate>連携待ち</WaitingCooperate>
                        ) : d.commission != null ? (
                          <FormatYen value={d.commission} />
                        ) : null}
                      </React.Fragment>
                    )}
                  </Td>
                  <Td centerAlign>
                    {mclDayjs(d.termStartDate, formatter.mapiDate).format(formatter.monthDayFixedWidth)}〜
                    {mclDayjs(d.termEndDate, formatter.mapiDate).format(formatter.monthDayFixedWidth)}
                  </Td>
                  {!isSingleStore && (
                    <Td leftAlign fontSize={14}>
                      {d.storeName}
                    </Td>
                  )}
                </Tr>
              );
            })
          )}
          {!isNoData && (
            <Tr>
              <Td centerAlign>合計</Td>
              <Td isFontBold>
                <FormatYen value={totalTransferPrice} />
              </Td>
              <Td>
                {totalSales != null ? <FormatYen value={totalSales} /> : <FormatYen value={0} />}
                {waitingTotalSales != null && (
                  <FormatYenSmallWrapper>
                    &#40;
                    <FormatYenSmall value={waitingTotalSales} />
                    &#41;
                  </FormatYenSmallWrapper>
                )}
              </Td>
              <Td>
                <FormatYen value={totalCommission} />
              </Td>
              <Td />
              {!isSingleStore && <Td />}
            </Tr>
          )}
        </tbody>
      </Table>
    </TableWrapper>
  );
};

const getTransferStatusName = (transferStatus: number): string => {
  switch (transferStatus) {
    case TRANSFER_STATUS.paid.key:
      return TRANSFER_STATUS.paid.fieldName;
    case TRANSFER_STATUS.paymentPlan.key:
      return TRANSFER_STATUS.paymentPlan.fieldName;
    case TRANSFER_STATUS.unfixed.key:
      return TRANSFER_STATUS.unfixed.fieldName;
    case TRANSFER_STATUS.transferStop.key:
      return TRANSFER_STATUS.transferStop.fieldName;
    default:
      return '';
  }
};

const getGopTransferStatusName = (transferStatus: number): string => {
  switch (transferStatus) {
    case GOP_TRANSFER_STATUS.paid.key:
      return GOP_TRANSFER_STATUS.paid.fieldName;
    case GOP_TRANSFER_STATUS.beforeFileReceive.key:
      return GOP_TRANSFER_STATUS.beforeFileReceive.fieldName;
    case GOP_TRANSFER_STATUS.paymentPlan.key:
      return GOP_TRANSFER_STATUS.paymentPlan.fieldName;
    case GOP_TRANSFER_STATUS.paymentDuration.key:
      return GOP_TRANSFER_STATUS.paymentDuration.fieldName;
    case GOP_TRANSFER_STATUS.transferStop.key:
      return GOP_TRANSFER_STATUS.transferStop.fieldName;
    default:
      return '';
  }
};

const _genOpenDetailLog = (
  productCode: string,
  transferDate: string, // yyyy-MM-dd
  akrCode: string,
  transferStatus: number
) => {
  let transferStatusLog: string;
  if (productCode === 'gop') {
    if (transferStatus === GOP_TRANSFER_STATUS.paid.key) {
      transferStatusLog = 'linked';
    } else if (transferStatus === GOP_TRANSFER_STATUS.beforeFileReceive.key) {
      transferStatusLog = 'deposit_day';
    } else if (transferStatus === GOP_TRANSFER_STATUS.paymentPlan.key) {
      transferStatusLog = 'planned';
    } else if (transferStatus === GOP_TRANSFER_STATUS.paymentDuration.key) {
      transferStatusLog = 'not_confirmed';
    } else {
      transferStatusLog = 'stopped';
    }
  } else {
    if (transferStatus === TRANSFER_STATUS.paid.key) {
      transferStatusLog = 'transferred';
    } else if (transferStatus === TRANSFER_STATUS.paymentPlan.key) {
      transferStatusLog = 'planned';
    } else if (transferStatus === TRANSFER_STATUS.unfixed.key) {
      transferStatusLog = 'not_confirmed';
    } else {
      transferStatusLog = 'stopped';
    }
  }
  return genGaLog(
    'transfer',
    'open_transfer_detail',
    'open',
    {},
    {
      product: productCode,
      date: transferDate,
      akr_code: akrCode,
      status: transferStatusLog,
    },
    'click'
  );
};

const TableWrapper = styled.div`
  overflow: auto;
  margin-top: 20px;
  max-height: calc(100% - 9px);
`;
const Table = styled.table<{ isNoData: boolean }>`
  border-bottom: ${props => (props.isNoData ? `solid 1px ${lightgray}` : '0')};
  width: 100%;
  border-spacing: 0;
`;
const Th = styled.th<{ width?: number }>`
  font-size: 14px;
  text-align: center;
  position: sticky;
  top: 0;
  padding: 14px 12px;
  background-color: ${gray};
  white-space: nowrap;
  min-width: ${props => (props.width != null ? `${props.width}px` : 'auto')};
  :first-child {
    border-right: 1px solid ${lightgray};
    left: 0;
    z-index: 2;
  }
  :last-child {
    width: 100%;
  }
  color: ${black};
`;
const Tr = styled.tr`
  padding: 12px;
`;
const Td = styled.td<{
  centerAlign?: boolean;
  leftAlign?: boolean;
  avsFlag?: number;
  costRateOnthedeskFlag?: number;
  isTransferDate?: boolean;
  isFontBold?: boolean;
  isPointer?: boolean;
  fontSize?: number;
}>`
  :first-child {
    border-right: 1px solid ${lightgray};
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: ${white};
  }
  :not(:last-child) {
    white-space: nowrap;
  }
  text-align: ${props => (props.centerAlign ? 'center' : props.leftAlign ? 'left' : 'right')};
  padding: 12px;
  border-bottom: 0.1rem solid ${lightgray};
  font-size: ${props => (props.fontSize != null ? `${props.fontSize}px` : '16px')};
  ${props => props.isTransferDate && `color: ${textLinkColor}`};
  ${props => props.isFontBold && 'font-weight: 600'};
  ${props => props.isPointer && 'cursor:pointer'};
  ${props => {
    switch (props.avsFlag) {
      case 1:
        return `background-color: ${rowRed}`;

      case 2:
        return `background-color: ${attractSecond}`;

      default:
        break;
    }

    switch (props.costRateOnthedeskFlag) {
      case 1:
        return `background-color: ${rowRed}`;

      case 2:
        return `background-color: ${attractSecond}`;

      default:
        break;
    }
    return;
  }};
`;

const NoData = styled.td`
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  height: 56px;
`;

const Tag = styled.div`
  display: inline;
  border-radius: 4px;
  border: 1px solid ${disabledTextColor};
  background: ${white};
  font-size: 12px;
  font-weight: 400;
  color: ${black};
  padding: 2px 6px;
  margin-left: 6px;
`;

const StyledUpperRightTooltip = styled(Tooltip.UpperRightPortal)`
  margin: -1px 4px 0;
`;

const ThWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TransferStatusWrapper = styled.div<{ transferStatus?: number }>`
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 12px;
  ${props =>
    props.transferStatus === TRANSFER_STATUS.paymentPlan.key &&
    `background: ${lightOrange}; color: ${orangeBorder}; min-width: 32px;`}

  ${props =>
    props.transferStatus === TRANSFER_STATUS.unfixed.key &&
    `background: ${airGray}; color: ${disabledTextColor}; min-width: 44px;`}

  ${props =>
    props.transferStatus === TRANSFER_STATUS.transferStop.key &&
    `background: ${white}; color: ${disabledTextColor};border: 1px solid ${uploadBorderColor}; min-width: 58px;`}
`;

const GopTransferStatusWrapper = styled.div<{ transferStatus?: number }>`
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 12px;
  ${props =>
    props.transferStatus === GOP_TRANSFER_STATUS.paymentPlan.key &&
    `background: ${lightOrange}; color: ${orangeBorder}; min-width: 32px;`}

  ${props =>
    props.transferStatus === GOP_TRANSFER_STATUS.paymentDuration.key &&
    `background: ${airGray}; color: ${disabledTextColor}; min-width: 44px;`}

  ${props =>
    props.transferStatus === GOP_TRANSFER_STATUS.transferStop.key &&
    `background: ${white}; color: ${disabledTextColor};border: 1px solid ${uploadBorderColor}; min-width: 58px;`}
`;

const TdWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TransferPriceWrapper = styled.div`
  margin-left: auto;
`;

const FormatYenSmallWrapper = styled.p`
  font-size: 12px;
`;

const WaitingCooperate = styled.span`
  color: ${disabledTextColor};
  font-weight: 500;
`;

export default SummaryTable;
