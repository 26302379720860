// 他プロダクトからメイトに遷移して来た場合の店舗情報を受け取る為のKEY
export const AC = 'ac';

// 年始サマリの年度を受け取る為のKEY
export const YEAR = 'year';

// メイトにどこから遷移してきたかを受け取るKEY
export const FROM = 'from';

export const FROM_VALUE = {
  ADJ_MONTHLY_SALES_PREDICTED: 'adj_monthlySalesPredicted',
  // メルパミ
  MP: 'mp',
};

export const FROM_VALUE_FOR_MENU_ANALYSIS = {
  TO_MATE_MAIL_FOR_MENU_ANALYSIS: 'to_mate_mail_for_menu_analysis',
  TO_ARG_MAIL_FOR_MENU_ANALYSIS: 'to_arg_mail_for_menu_analysis',
  TO_MATE_NOTICE_FOR_MENU_ANALYSIS: 'to_mate_notice_for_menu_analysis',
  TO_ARG_NOTICE_FOR_MENU_ANALYSIS: 'to_arg_notice_for_menu_analysis',
};
