// 認証失敗時にアクセスしたURLを格納する為のKEY
export const REDIRECT_URL_KEY_NAME = 'redirectUrl';
// 成績画面の表示タイプを格納する為のKEY
export const SELECTED_INDICES_TYPE_KEY_NAME = 'selectedIndicesType';
// グロナビ最大化・最小化の履歴を残す為のKEY
export const GLOBAL_NAVIGATION_KEY_NAME = 'globalNavigation';

// Airカード連携コスト管理画面の検索用カード番号を格納する為のKEY
export const SELECTED_CARDS_TYPE_KEY_NAME = 'selectedCards';

// バッジの獲得状況を格納する為のKEY
export const BADGE_CHECKED_INFO_KEY_NAME = 'badgeCheckedInfo';

// バッジ獲得一覧モーダルでバッジ全獲得モーダルを表示したかどうかを格納するKEY
export const COMPLETE_BADGE_MODAL_CHECKED_KEY_NAME = 'completeBadgeModalChecked';

// 日報の説明ボックスを閉じているか確認する為のKEY
export const DAILY_REPORT_LIST_CLOSE_INFO_KEY_NAME = 'dailyReportListCloseInfo';

// リアルタイムの説明ボックスを閉じているか確認する為のKEY
export const REALTIME_CLOSE_INFO_KEY_NAME = 'realtimeCloseInfo';

// オンスタの非表示ボタンのためのKEY
export const TUTORIAL_GOAL_STARTED_ID = 'tutorial_goal_started_id';

// AKRコードを保存するためのKEY
export const SELECTED_AKRCODE = 'selectedAkrcode';

// 「自動表示するか否か」でデータがない場合で且つ初回表示ではない場にshowIntroするためのKEY
export const ONB_DISABLE_INTRO_AUTO_DISPLAY = 'onb_disable_intro_auto_display';

// 店舗一覧の表示・非表示状態を保存するKEY
export const IS_DISPLAYED_STORE_LIST = 'isDisplayedStoreList';

// デバッグで選択している開発環境を保存するためのKEY
export const ENV = 'env';

// 成績カレンダーのレジポップアップを閉じているか確認するためのKEY
export const STORE_CALENDER_INDICES_POPUP_CLOSE_INFO_KEY_NAME = 'storeCalenderIndicesPopupCloseInfo'; // 旧キー
export const STORE_CALENDAR_INDICES_POPUP_CLOSE_INFO_KEY_NAME = 'storeCalendarIndicesPopupCloseInfo'; // 新キー

// 人件費分析訴求非表示日付を格納する為のKEY
export const LABOR_COST_ANALYSIS_APPEAL_CLOSE_INFO_DATE_KEY_NAME = 'laborCostAnalysisAppealCloseInfoDate';

// Airインボイス連携コスト管理画面の検索用店舗一覧を格納する為のKEY
export const SELECTED_INVOICE_TYPE_KEY_NAME = 'selectedInvoices';

// 成績画面で税込表示喚起バナー表出パラメータを格納するためのKEY
export const TAX_NOTICE = 'taxNotice';

// 入金情報画面の表示タイプを格納する為のKEY
export const PAYMENT_VIEW_TYPE_KEY_NAME = 'paymentViewType';
// 人件費分析のカレンダーポップアップを初回表示したか確認するためのKEY
export const LABOR_COST_CALENDAR_POPUP = 'laborCostCalendarPopup';

// 人件費分析のシフト訴求バナーを初回表示したか確認するためのKEY
export const LABOR_COST_SHIFT_BANNER = 'laborCostShiftBanner';

// 入金情報画面を初回表示したか確認するためのKEY
export const TRANSFER_FIRST_VIEW = 'transferFirstView';
