// 入金情報
import React from 'react';
import { bindActionCreators, Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import { State as ReduxState } from '../../../modules';
import { track } from '../../../modules/logging';
import styled from 'styled-components';
import { StoresData } from '../../../modules/user';
import {
  changeCalendarDetailDate,
  changePeriod,
  changeViewType,
  initialFetch,
  actions as paymentUiActions,
  selectStore,
} from '../../../modules/payment/ui';
import {
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  ApiState,
} from '../../../typedef/api/Utility';
import { BatchProcessedDate } from '../../../typedef/BatchProcessedDate';
import { PaymentMaster } from '../../../typedef/api/Payment/PaymentMaster';
import Templates from '../../../components/common/templates';
import { ActivityIndicator } from '../../../components/common';
import { assertNever } from '../../../helpers/util';
import ApiError from '../../../components/common/templates/ApiError';
import { black, textLinkColor } from '../../../constants/colors';
import {
  PaymentSummary,
  TransferInfoSummary,
  GopTransferInfoSummary,
} from '../../../typedef/api/Payment/PaymentSummary';
import {
  actions as paymentApiActions,
  startFetchPaymentSummary,
  startFetchPayDetail,
  startFetchPayQrDetail,
  startFetchGopDetail,
  startFetchPaymentTransferInfoDetail,
  startFetchPaymentCalendar,
} from '../../../modules/payment/api';
import { storesWithTransferInfoSummary, transferYearMonthList } from '../../../selectors/paymentSelectors';
import { airCashUrl } from '../../../constants/externalLink';
import { TRANSFER_TOP_BANNER_ACT_BTN_LID_PARAMETER } from '../../../constants/externalLinkParameter';
import { PAYMENT_VIEW_TYPE } from './transferConstants';
import { assignedStoresSelector } from '../../../selectors/userDataSelector';
import { formatter, LocalYearMonthObj, parser } from '../../../helpers/mclDate';
import CalendarContents from './components/CalendarContents';
import AirCashLogo from '../../../icons/airCashMiniLogo.svg';
import OpenLinkBlue from '../../../icons/openLinkBlue.svg';
import PayCalendarDetailModal from './components/PayCalendarDetailModal';
import { MonthlyTransferInfo } from '../../../typedef/api/Payment/Calendar';
import { Waypoint } from 'react-waypoint';
import { genGaLog } from '../../../gaLogger';

type DispatchProps = {
  readonly logging: typeof track;
  readonly selectStore: typeof selectStore;
  readonly initialFetch: typeof initialFetch;
  readonly changePeriod: typeof changePeriod;
  readonly startFetchPaymentSummary: typeof startFetchPaymentSummary;
  readonly fetchPayDetail: typeof startFetchPayDetail;
  readonly fetchPayQrDetail: typeof startFetchPayQrDetail;
  readonly fetchGopDetail: typeof startFetchGopDetail;
  readonly changeViewType: typeof changeViewType;
  readonly fetchPaymentTransferInfoDetail: typeof startFetchPaymentTransferInfoDetail;
  readonly fetchPaymentCalendar: typeof startFetchPaymentCalendar;
  readonly changeCalendarDetailDate: typeof changeCalendarDetailDate;
};
type StateProps = {
  readonly stores: ReadonlyArray<StoresData>;
  readonly selectedStores: Set<string>;
  readonly paymentMasterState: ApiState<PaymentMaster>;
  readonly yearMonthList?: ReadonlyArray<string> | undefined;
  readonly period: {
    year: string;
    month: string;
  };
  readonly yearMonth: LocalYearMonthObj;
  readonly paymentSummaryState: ApiState<PaymentSummary>;
  readonly storesWithTransferInfoSummary: ReadonlyArray<
    (TransferInfoSummary | GopTransferInfoSummary) & StoresData
  >;
  readonly batchProcessedDate: BatchProcessedDate;
  readonly isSpUse?: boolean;
  readonly selectedViewType: keyof typeof PAYMENT_VIEW_TYPE;
  readonly paymentCalendarState: ApiState<MonthlyTransferInfo>;
};

type ThroughProps = {
  isAirPayUse: boolean;
};

type Props = StateProps & DispatchProps & ThroughProps;

type State = {
  readonly isShowDetailModal: boolean;
};

class TransferCalendarContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isShowDetailModal: false,
    };
  }

  componentDidMount(): void {
    const { yearMonth, selectedStores, fetchPaymentCalendar } = this.props;
    if (yearMonth != null) {
      fetchPaymentCalendar(parser.fromYearMonthObject(yearMonth).format(formatter.mapiYearMonth), [
        ...selectedStores,
      ]);
    }
  }

  render() {
    const {
      isAirPayUse,
      paymentCalendarState,
      selectedStores,
      fetchPaymentTransferInfoDetail,
      changeCalendarDetailDate,
      logging,
    } = this.props;
    const { isShowDetailModal } = this.state;
    switch (paymentCalendarState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      case API_STATE_COMPLETED:
        const { targetMonthInfo, nextMonthInfo, monthAfterNextInfo } =
          paymentCalendarState.payload.monthlyTransferInfo;
        return (
          <React.Fragment>
            {isAirPayUse && (
              <Waypoint
                onEnter={() => {
                  logging(_genAirCashLog('impression', 'impression'));
                }}
              >
                <AirCashLink>
                  <p>資金調達</p>
                  <StyledAirCashLogo />
                  <a
                    href={`${airCashUrl}?lid=${TRANSFER_TOP_BANNER_ACT_BTN_LID_PARAMETER}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      logging(_genAirCashLog('click', 'open'));
                    }}
                  >
                    Airペイの将来の売上を今のお金にかえる
                  </a>
                  <OpenLinkBlue />
                </AirCashLink>
              </Waypoint>
            )}
            <CalendarContents
              yearMonth={targetMonthInfo.yearMonth}
              transferInfoList={targetMonthInfo.transferList}
              selectedStores={selectedStores}
              showModal={() => {
                this.setState({ isShowDetailModal: true });
              }}
              fetchPaymentTransferInfoDetail={fetchPaymentTransferInfoDetail}
              changeCalendarDetailDate={changeCalendarDetailDate}
            />
            <CalendarContents
              yearMonth={nextMonthInfo.yearMonth}
              transferInfoList={nextMonthInfo.transferList}
              selectedStores={selectedStores}
              showModal={() => {
                this.setState({ isShowDetailModal: true });
              }}
              fetchPaymentTransferInfoDetail={fetchPaymentTransferInfoDetail}
              changeCalendarDetailDate={changeCalendarDetailDate}
            />
            <CalendarContents
              yearMonth={monthAfterNextInfo.yearMonth}
              transferInfoList={monthAfterNextInfo.transferList}
              selectedStores={selectedStores}
              showModal={() => {
                this.setState({ isShowDetailModal: true });
              }}
              fetchPaymentTransferInfoDetail={fetchPaymentTransferInfoDetail}
              changeCalendarDetailDate={changeCalendarDetailDate}
            />
            <Lead>※入金金額に「〜」がつく場合は未確定の入金があります。</Lead>
            {isShowDetailModal && (
              <PayCalendarDetailModal
                onClose={() => {
                  this.setState({ isShowDetailModal: false });
                }}
                logging={logging}
              />
            )}
          </React.Fragment>
        );
      case API_STATE_FAILED:
        return (
          <Templates.Center>
            <ApiError />
          </Templates.Center>
        );
      default:
        return assertNever(paymentCalendarState);
    }
  }
}

const mapStateToProps = (state: ReduxState): StateProps => ({
  stores: assignedStoresSelector(state),
  selectedStores: state.payment.ui.selectedStores,
  paymentMasterState: state.payment.payment.paymentMasterState,
  yearMonthList: transferYearMonthList(state),
  period: state.payment.ui.period,
  yearMonth: state.payment.ui.yearMonth,
  paymentSummaryState: state.payment.payment.paymentSummaryState,
  storesWithTransferInfoSummary: storesWithTransferInfoSummary(state),
  batchProcessedDate: state.uiConfig.batchProcessedDate,
  isSpUse: state.user.data?.isSpUse,
  selectedViewType: state.payment.ui.selectedViewType,
  paymentCalendarState: state.payment.payment.paymentCalendarState,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        logging: track,
        selectStore: paymentUiActions.selectStore,
        initialFetch: paymentUiActions.initialFetch,
        changePeriod: paymentUiActions.changePeriod,
        startFetchPaymentSummary: paymentApiActions.startFetchPaymentSummary,
        fetchPayDetail: paymentApiActions.startFetchPayDetail,
        fetchPayQrDetail: paymentApiActions.startFetchPayQrDetail,
        fetchGopDetail: paymentApiActions.startFetchGopDetail,
        changeViewType: paymentUiActions.changeViewType,
        fetchPaymentTransferInfoDetail: paymentApiActions.startFetchPaymentTransferInfoDetail,
        fetchPaymentCalendar: paymentApiActions.startFetchPaymentCalendar,
        changeCalendarDetailDate: paymentUiActions.changeCalendarDetailDate,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferCalendarContainer);

const _genAirCashLog = (event, func) => {
  return genGaLog(
    'transfer',
    'acs_crossuse_panel',
    func,
    {},
    { type: ['acs'], selected_transfer_type: 'calendar' },
    event
  );
};

const AirCashLink = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  font-size: 12px;
  color: ${black};
  font-weight: 600;
  width: 777px;
  margin-top: 24px;
  margin-bottom: -20px;
  a {
    color: ${textLinkColor};
    font-weight: 500;
    margin-right: 4px;
  }
`;

const StyledAirCashLogo = styled(AirCashLogo)`
  margin: 0 9px 0 12px;
  width: 66px;
`;

const Lead = styled.p`
  color: ${black};
  font-size: 14px;
  font-weight: 400;
  margin-top: 44px;
`;
