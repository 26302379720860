import * as React from 'react';
import { Dispatch, bindActionCreators, Action } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../components/common/molecules/Airkit/AirButton';
import Checkbox from '../../../components/common/molecules/Airkit/AirCheckbox';
import _ from 'lodash';
import AllMenuCalendar from './AllMenuCalendar';
import SelectCategory from './SelectCategory';
import Tooltip from '../../../components/common/molecules/Tooltip';
import SelectBox from '../../../components/common/atoms/SelectBox';
import BalloonModal from '../../../components/common/BalloonModal';
import { Milligram } from '../../../components/common';
import { startSearchMenu } from '../../../modules/allMenu/search';
import { actions as menuFormAction } from '../../../modules/allMenu/form';
import { State as ReduxState } from '../../../modules';
import { StoresData } from '../../../modules/user';
import { startGetCategories } from '../../../modules/allMenu/categories';
import { State as TagGroupState } from '../../../modules/allMenu/analysisTagGroup';
import { State as CategoriesState } from '../../../modules/allMenu/categories';
import { track } from '../../../modules/logging';
import { genGaLog } from '../../../gaLogger';
import { containsGourmetStores, assignedStoresSelector } from '../../../selectors/userDataSelector';
import { StyleSheet, css } from 'aphrodite';
import { LocalDateObj, MclDayjs, parser } from '../../../helpers/mclDate';

type DispatchProps = Readonly<{
  logger: typeof track;
  startSearchMenu: typeof startSearchMenu;
  startGetCategories: typeof startGetCategories;
  selectStores: typeof menuFormAction.selectStores;
  selectLunchDinner: typeof menuFormAction.selectLunchDinner;
  selectAnalysisTags: typeof menuFormAction.selectAnalysisTags;
  selectCategories: typeof menuFormAction.selectCategories;
  selectBusinessDate: typeof menuFormAction.selectBusinessDate;
  selectIncludesFreeMenu: typeof menuFormAction.selectIncludesFreeMenu;
}>;

type StateProps = {
  readonly menuTableLoadStatus: 'idle' | 'loading' | 'loaded' | 'error';
  readonly stores: ReadonlyArray<StoresData>;
  readonly tagGroup: TagGroupState;
  readonly categories: CategoriesState;
  readonly selectedStores: Set<string>;
  readonly selectedLunchDinner: Set<string>;
  readonly selectedAnalysisTags: Set<string>;
  readonly selectedDisplayCategories: Set<string>;
  readonly selectedHiddenCategories: Set<string>;
  readonly businessDateFrom: LocalDateObj;
  readonly businessDateTo: LocalDateObj;
  readonly includesFreeMenu: boolean;
  readonly includesUndefinedCategory: boolean;
  readonly tableLength: number;
  readonly containsGourmetStores: boolean;
};

type Props = Readonly<{} & DispatchProps & StateProps>;

class MenuSearchForm extends React.PureComponent<Props> {
  render() {
    const { menuTableLoadStatus } = this.props;
    const {
      selectedStores,
      selectedLunchDinner,
      selectedAnalysisTags,
      selectedDisplayCategories,
      selectedHiddenCategories,
      categories,
      includesFreeMenu,
      includesUndefinedCategory,
    } = this.props;

    return (
      <Wrapper id="menu_analysis_input_form">
        {this.props.containsGourmetStores ? (
          <React.Fragment>
            <SearchFormRow>
              <CustomColVariable percentage={33.3}>
                {storeColumn(this.props.stores, selectedStores, this.props)}
              </CustomColVariable>
              <CustomColVariable percentage={40}>
                {periodColumn(this.props.businessDateFrom, this.props.businessDateTo, this.props)}
              </CustomColVariable>
              <CustomColVariable percentage={26.7}>
                {lunchDinnerColumn(this.props.selectedLunchDinner, this.props)}
              </CustomColVariable>
            </SearchFormRow>
            <SearchFormRow>
              <CustomColVariable percentage={73.3}>{categoryColumn(this.props)}</CustomColVariable>
              <CustomColVariable percentage={26.7}>{analysisTagGroupColumn(this.props)}</CustomColVariable>
            </SearchFormRow>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SearchFormRow>
              <CustomColVariable percentage={60}>
                {storeColumn(this.props.stores, selectedStores, this.props)}
              </CustomColVariable>
              <CustomColVariable percentage={40}>
                {periodColumn(this.props.businessDateFrom, this.props.businessDateTo, this.props)}
              </CustomColVariable>
            </SearchFormRow>
            <SearchFormRow>
              <CustomColVariable percentage={100}>{categoryColumn(this.props)}</CustomColVariable>
            </SearchFormRow>
          </React.Fragment>
        )}

        <SearchFormRow>
          <CustomColVariable percentage={33}> </CustomColVariable>
          <CustomColVariable percentage={33}>
            <SearchFormRowCustom>
              <CustomColVariable percentage={80}>
                <ButtonCustom
                  onClick={() => {
                    this.props.logger(
                      _genLog(
                        {
                          lunchDinner: Array.from(selectedLunchDinner),
                          includesUndefinedCategory,
                          includesFreeMenu,
                        },
                        'show_report'
                      )
                    );
                    this.props.startSearchMenu();
                  }}
                  primary={true}
                  disabled={
                    categories.loadStatus.type === 'loading' ||
                    menuTableLoadStatus === 'loading' ||
                    selectedStores.size === 0 ||
                    selectedLunchDinner.size === 0 ||
                    selectedAnalysisTags.size === 0 ||
                    (selectedDisplayCategories.size === 0 &&
                      selectedHiddenCategories.size === 0 &&
                      !includesUndefinedCategory) ||
                    categories.loadStatus.type === 'error'
                  }
                >
                  表示する
                </ButtonCustom>
              </CustomColVariable>
            </SearchFormRowCustom>
          </CustomColVariable>
          <CustomColVariable percentage={33}>
            <CheckBoxContainer>
              <Checkbox
                label="0円商品を含む"
                isChecked={includesFreeMenu}
                onClick={() => {
                  this.props.selectIncludesFreeMenu(!includesFreeMenu);
                  this.props.logger(_genClickIncludesFreeMenu());
                }}
                className={css(styles.checkBox)}
              />
              <CustomTooltipRight viewName="menu_analysis" feature="all_menu_form" name="include_free_menu">
                客層分析用の項目（男女・年代）、飲食店の飲み放題ドリンクやおしぼりなど、0円で設定している商品も分析結果に表示します。
              </CustomTooltipRight>
            </CheckBoxContainer>
          </CustomColVariable>
        </SearchFormRow>
      </Wrapper>
    );
  }
}

const storeColumn = (
  stores: ReadonlyArray<StoresData>,
  selectedStores: Set<string>,
  props: Readonly<DispatchProps>
): React.ReactNode => {
  const sortedStores: Array<StoresData> = _.sortBy(stores, ['storeName']);
  const isSingleStore = stores.length === 1;
  return (
    <React.Fragment>
      <SearchTitile>店舗</SearchTitile>
      <SelectBox.multiple
        required={true}
        options={
          new Map(
            sortedStores.map(store => {
              return [store.akrCode, store.storeName];
            })
          )
        }
        selectedItems={selectedStores}
        onChange={items => {
          props.selectStores(items);
        }}
        onClose={() => {
          props.startGetCategories(selectedStores);
        }}
        customPlaceHolder={isSingleStore ? () => stores[0].storeName : undefined}
        onClick={() => props.logger(_genPullDownLog('store'))}
      />
    </React.Fragment>
  );
};
const lunchDinnerColumn = (
  selectedLunchDinner: Set<string>,
  props: Readonly<DispatchProps>
): React.ReactNode => {
  const LUNCH_DINNER = [
    {
      key: 'lunch',
      name: 'ランチ',
    },
    {
      key: 'dinner',
      name: 'ディナー',
    },
  ];
  return (
    <React.Fragment>
      <SearchTitile>ランチ・ディナー</SearchTitile>
      <SelectBox.multiple
        required={true}
        isBalloonPositionRight={true}
        options={
          new Map(
            LUNCH_DINNER.map(ld => {
              return [ld.key, ld.name];
            })
          )
        }
        selectedItems={selectedLunchDinner}
        onChange={items => {
          props.selectLunchDinner(items);
        }}
        onClick={() => props.logger(_genPullDownLog('lunch_dinner'))}
      />
    </React.Fragment>
  );
};
const periodColumn = (
  businessDateFrom: LocalDateObj,
  businessDateTo: LocalDateObj,
  props: Readonly<DispatchProps>
): React.ReactNode => {
  return (
    <React.Fragment>
      <SearchTitile>期間</SearchTitile>
      <AllMenuCalendar
        logger={props.logger}
        startDate={parser.fromDateObject(businessDateFrom)}
        endDate={parser.fromDateObject(businessDateTo)}
        selectDate={(from: MclDayjs, to: MclDayjs) => {
          props.selectBusinessDate({
            from: from.toLocalDateObj(),
            to: to.toLocalDateObj(),
          });
        }}
        onClick={() => props.logger(_genPullDownLog('select_period'))}
      />
    </React.Fragment>
  );
};
const analysisTagGroupColumn = (props: Props): React.ReactNode => {
  return (
    <React.Fragment>
      <SearchTitile>
        分析タググループ
        <CustomTooltipRight viewName="menu_analysis" feature="all_menu_form" name="analysis_tag_group">
          Airレジで設定する分析タグを元に作成しています。
          <br />
          ドリンク：分析タグが「アルコールドリンク」「ソフトドリンク」に設定された商品
          <br />
          コース：分析タグが「コース」「放題プラン」に設定された商品
          <br />
          <br />
          分析タグが設定されていない場合、商品名から自動で予測して設定します。その場合、商品名横に「予測」のマークがつきます。
        </CustomTooltipRight>
      </SearchTitile>
      {(() => {
        if (props.tagGroup.loadStatus.type === 'loaded') {
          const payload = props.tagGroup.loadStatus.payload;
          return (
            <SelectBox.multiple
              required={true}
              isBalloonPositionRight={true}
              options={
                new Map(
                  payload.analysisTagGroup.map(tag => {
                    return [tag, tag];
                  })
                )
              }
              selectedItems={props.selectedAnalysisTags}
              onChange={items => {
                props.selectAnalysisTags(items);
              }}
              onClick={() => props.logger(_genPullDownLog('analysis'))}
            />
          );
        }
        return <BalloonModal disabled={true} placeholderText="すべて" />;
      })()}
    </React.Fragment>
  );
};
const categoryColumn = (props: Props): React.ReactNode => {
  return (
    <React.Fragment>
      <SearchTitile>
        カテゴリー
        <CustomTooltipLeft viewName="menu_analysis" feature="all_menu_form" name="categories">
          Airレジで設定されたカテゴリーです。
          <br />
          「カテゴリー未設定」を選択した場合、カテゴリー別表示の際は結果に反映されません。
        </CustomTooltipLeft>
      </SearchTitile>
      {(() => {
        if (props.categories.loadStatus.type === 'loaded') {
          const payload = props.categories.loadStatus.payload;
          return (
            <SelectCategory
              options={
                new Map(
                  payload.dispCategories.map(x => {
                    return [x, x];
                  })
                )
              }
              hiddenOptions={
                new Map(
                  payload.nodispCategories.map(x => {
                    return [x, x];
                  })
                )
              }
              selectedDisplayItems={props.selectedDisplayCategories}
              selectedHiddenItems={props.selectedHiddenCategories}
              includesUndefinedCategory={props.includesUndefinedCategory}
              onDisplayChange={(items, undefinedCategory) => {
                props.selectCategories(items, props.selectedHiddenCategories, undefinedCategory);
              }}
              onHiddenChange={(items, undefinedCategory) => {
                props.selectCategories(props.selectedDisplayCategories, items, undefinedCategory);
              }}
              onUndefinedCategoryChange={items => {
                props.selectCategories(
                  props.selectedDisplayCategories,
                  props.selectedHiddenCategories,
                  items
                );
              }}
              onClick={() => props.logger(_genPullDownLog('category'))}
            />
          );
        }

        return <BalloonModal disabled={true} placeholderText="すべて" />;
      })()}
    </React.Fragment>
  );
};

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    menuTableLoadStatus: state.allMenu.search.loadStatus.type,
    stores: assignedStoresSelector(state),
    tagGroup: state.allMenu.tagGroup,
    categories: state.allMenu.categories,
    selectedStores: state.allMenu.form.selectedStores,
    selectedLunchDinner: state.allMenu.form.selectedLunchDinner,
    selectedAnalysisTags: state.allMenu.form.selectedAnalysisTags,
    selectedDisplayCategories: state.allMenu.form.selectedDisplayCategories,
    selectedHiddenCategories: state.allMenu.form.selectedHiddenCategories,
    businessDateFrom: state.allMenu.form.businessDateFrom,
    businessDateTo: state.allMenu.form.businessDateTo,
    includesFreeMenu: state.allMenu.form.includesFreeMenu,
    includesUndefinedCategory: state.allMenu.form.includesUndefinedCategory,
    tableLength: state.allMenu.search.tableProperties.tableLength,
    containsGourmetStores: containsGourmetStores(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    ...bindActionCreators(
      {
        startSearchMenu,
        startGetCategories,
        selectStores: menuFormAction.selectStores,
        selectLunchDinner: menuFormAction.selectLunchDinner,
        selectAnalysisTags: menuFormAction.selectAnalysisTags,
        selectCategories: menuFormAction.selectCategories,
        selectBusinessDate: menuFormAction.selectBusinessDate,
        selectIncludesFreeMenu: menuFormAction.selectIncludesFreeMenu,
        logger: track,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSearchForm);

const _genLog = (params: object, func: string) => {
  return genGaLog('menu_analysis', 'all_menu_form', func, params, {}, 'click');
};

type PullDownFuncType = 'store' | 'select_period' | 'lunch_dinner' | 'category' | 'analysis';
const _genPullDownLog = (funcName: PullDownFuncType) => {
  return genGaLog('menu_analysis', 'all_menu_pulldown', funcName, {}, {}, 'click');
};

const _genClickIncludesFreeMenu = () => {
  return genGaLog('menu_analysis', 'all_menu_form', 'check_include_free_menu', {}, {}, 'click');
};
const Wrapper = styled.div``;

const SearchFormRow = styled(Milligram.Row)`
  width: 100%;
  margin-left: 0;
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
`;

const SearchFormRowCustom = styled(SearchFormRow)`
  padding: 0;
`;

const SearchTitile = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const ButtonCustom = styled(Button)`
  width: 100%;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CustomTooltipLeft = styled(Tooltip.UpperLeftPortal)`
  margin-left: 4px;
`;

const CustomTooltipRight = styled(Tooltip.UpperRightPortal)`
  margin-left: 4px;
`;

const CustomColVariable = styled(Milligram.ColVariable)`
  :first-child {
    padding-left: 0;
  }
  :last-child {
    padding-right: 0;
  }
`;

const styles = StyleSheet.create({
  checkBox: {
    fontSize: '14px',
  },
});
