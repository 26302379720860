// 訴求共通コンポーネントの2番目のヘッダー(ご利用の流れ)の中身

import React, { ComponentProps } from 'react';
import styled from 'styled-components';
import { airblue, black, uploadBorderColor } from '../../../../constants/colors';
import ArrowGrayRight from '../../../../icons/arrow_gray.svg';
import { AppealModal } from '../AppealModal';
import { PRODUCT_TYPE } from '../../../../constants/appealModal';

type Props = ComponentProps<typeof AppealModal>;

export const ServiceFlowContent = (props: Pick<Props, 'productType'>) => {
  const { productType } = props;
  switch (productType) {
    case PRODUCT_TYPE.MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_MODAL_SHIFT:
    case PRODUCT_TYPE.DAILYREPORT_INPUT_MODAL_SHIFT:
    case PRODUCT_TYPE.PRODUCTLIST_SHIFT:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_SHIFT:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_SHIFT_SUMMARY:
    case PRODUCT_TYPE.LABORCOSTANALYSIS_SHIFT:
    case PRODUCT_TYPE.DAILYREPORT_SHIFT:
    case PRODUCT_TYPE.REALTIME_SHIFT:
    case PRODUCT_TYPE.STORE_MONTHLY_INDICES_SHIFT:
    case PRODUCT_TYPE.STORE_INDICES_SHIFT:
    case PRODUCT_TYPE.DAILYDETAILPAST_SHIFT:
    case PRODUCT_TYPE.DAILYDETAILFUTURE_SHIFT:
      return (
        // first,second,thirdはご利用の流れの各枠のwidthを指定する(単位px)
        <ServiceFlowContentWrapper widthSize={{ first: 230, second: 230, third: 230 }}>
          <div>
            <h4>Airシフトにログイン</h4>
            <p>
              下のボタンから、規約に同意してお進みください。
              <br />
              ※同意によりご利用料金などが発生することはございません。
            </p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>Airシフトを無料でお試し</h4>
            <p>スタッフを連携して、便利なシフト作成・勤怠管理をお試しください。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>Airメイトに自動連携</h4>
            <p>データは自動で連携され、設定や作業は不要です。</p>
          </div>
        </ServiceFlowContentWrapper>
      );
    case PRODUCT_TYPE.DAILYREPORT_CARD:
    case PRODUCT_TYPE.PRODUCTLIST_CARD:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CARD:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CARD_SUMMARY:
      return (
        <ServiceFlowContentWrapper widthSize={{ first: 230, second: 215, third: 245 }}>
          <div>
            <h4>申込み</h4>
            <p>下のボタンから進み、必要事項を記入してお申込みください。 ※入会には所定の審査があります。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>利用開始</h4>
            <p>カードが届いたら、仕入や固定費などの各種お支払いにAirカードをご利用ください。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>Airメイトに連携</h4>
            <p>
              連携設定はボタンひとつで完了。利用データが自動連携され、コストに反映されます。
              <br />
              <span className="annotation">※反映にはコスト分類の設定が必要です。</span>
            </p>
          </div>
        </ServiceFlowContentWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_OES:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_OES:
    case PRODUCT_TYPE.LABORCOSTANALYSIS_OES:
    case PRODUCT_TYPE.REALTIME_OES:
    case PRODUCT_TYPE.STORE_MONTHLY_INDICES_OES:
    case PRODUCT_TYPE.STORE_INDICES_OES:
    case PRODUCT_TYPE.DAILYDETAILPAST_OES:
      return (
        <ServiceFlowContentWrapper widthSize={{ first: 230, second: 230, third: 230 }}>
          <div>
            <h4>資料請求・お問い合わせ</h4>
            <p>下のボタンからフォームに進み、お問い合わせください。詳しい資料もダウンロードできます。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>導入のご相談</h4>
            <p>お店の状況やお困りごとを伺い、最適なプランをご提案します。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>ご契約・利用開始</h4>
            <p>ご契約後20〜30営業日で利用開始。Airメイトに自動でデータが連携されます。</p>
          </div>
        </ServiceFlowContentWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_RB:
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_RB:
    case PRODUCT_TYPE.REALTIME_RB:
    case PRODUCT_TYPE.DAILYDETAILPAST_RB:
    case PRODUCT_TYPE.DAILYDETAILFUTURE_RB:
      return (
        <ServiceFlowContentWrapper widthSize={{ first: 230, second: 230, third: 230 }} paddingTopSize={16}>
          <div>
            <h4>
              レストランボードに
              <br />
              ログイン
            </h4>
            <p>AirIDですぐに利用を開始できます。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>アプリをダウンロード</h4>
            <p>iPadまたはiPhoneにレストランボードアプリを無料でダウンロード。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>ご利用開始</h4>
            <p>カンタンな設定だけで開始できます。データはAirメイトに自動連携されます。</p>
          </div>
        </ServiceFlowContentWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_REGI:
    case PRODUCT_TYPE.DAYOFWEEKHOURLY_REGI_NOT_USE:
    case PRODUCT_TYPE.MENU_ANALYSIS_REGI:
    case PRODUCT_TYPE.REALTIME_REGI:
    case PRODUCT_TYPE.DAILYDETAILPAST_REGI:
    case PRODUCT_TYPE.DAILYREPORT_EMPTYSTATE_REGI:
    case PRODUCT_TYPE.MONTHLYLOOKBACK_EMPTYSTATE_REGI:
      return (
        <ServiceFlowContentWrapper widthSize={{ first: 230, second: 230, third: 230 }}>
          <div>
            <h4>Airレジにログイン</h4>
            <p>お使いのAirIDでそのままログインできます。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>初期設定</h4>
            <p>案内に従ってレジの設定や周辺機器をご準備ください。無料導入相談も承っております。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>ご利用開始</h4>
            <p>Airレジで会計業務を行ってください。データはAirメイトに自動連携されます。</p>
          </div>
        </ServiceFlowContentWrapper>
      );
    case PRODUCT_TYPE.STORE_CALENDAR_INDICES_CASH:
      return (
        <ServiceFlowContentWrapper widthSize={{ first: 230, second: 230, third: 230 }} heightSize={182}>
          <div>
            <h4>ログイン</h4>
            <p>
              下のボタンから進み、お手持ちのAirIDでログインしてください。資金調達可能な金額を確認することができます。
            </p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>申込み</h4>
            <p>
              金額と引落率を選ぶだけで簡単に申込みができます。面倒な個人情報の入力や書類提出は一切ありません。
            </p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>入金・精算</h4>
            <p>
              申込みから最短翌日に入金されます。Airペイ振込金額から自動引落としのため、面倒な振込みや残高チェックの手間は一切ありません。
            </p>
          </div>
        </ServiceFlowContentWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_PAY:
      return (
        <ServiceFlowContentWrapper widthSize={{ first: 230, second: 230, third: 230 }} heightSize={152}>
          <div>
            <h4>申込み</h4>
            <p>
              下のボタンから進み、必要事項を記入してお申込みください。
              <br />
              <span className="annotation">※ご利用には所定の審査があります。</span>
            </p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>審査開始</h4>
            <p>審査が完了すると、ご登録いただいた店舗へカードリーダーをお届けします。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>ご利用開始</h4>
            <p>「Airペイ」アプリをダウンロードし、初期設定を行なってください。</p>
          </div>
        </ServiceFlowContentWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_MPA:
      return (
        <ServiceFlowContentWrapper widthSize={{ first: 230, second: 230, third: 230 }} heightSize={152}>
          <div>
            <h4>オンライン申込み</h4>
            <p>
              下のボタンから進み、必要事項を記入してお申込みください。
              <br />
              <span className="annotation">※ご利用には所定の審査があります。</span>
            </p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>審査開始</h4>
            <p>審査通過後、IDとパスワードを発行します。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>ご利用開始</h4>
            <p>「Airペイ QR」アプリをダウンロードしてログインしてください。</p>
          </div>
        </ServiceFlowContentWrapper>
      );
    case PRODUCT_TYPE.PRODUCTLIST_INVOICE:
      return (
        <ServiceFlowContentWrapper
          widthSize={{ first: 230, second: 230, third: 230 }}
          heightSize={220}
          productType={productType}
        >
          <div>
            <h4>アプリダウンロード</h4>
            <p>お手持ちのiPhoneにAirインボイス アプリをダウンロードし、AirIDでログインします。</p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>請求書を撮影</h4>
            <p>
              請求書をスマホのカメラで撮影すると、自動でデータ化されます。請求データは、Airメイトに自動連携され、コストとして反映されます。
            </p>
          </div>
          <ArrowGrayRight />
          <div>
            <h4>振込予約して入金</h4>
            <p>
              アプリから振込予約をして、専用口座に入金すると、振込指定日に自動で振り込みされます。
              <span className="annotation">
                ※Airインボイスで振込機能をご利用になるには、SBペイメントサービス株式会社の支払代行サービスへのお申込みが必要です。
              </span>
            </p>
          </div>
        </ServiceFlowContentWrapper>
      );
    default:
      return null;
  }
};

const ServiceFlowContentWrapper = styled.div<{
  widthSize: { first: number; second?: number; third?: number };
  heightSize?: number;
  paddingTopSize?: number;
  productType?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 28px 38px 37px;
  > div {
    width: ${props => `${props.widthSize.first}px`};
    height: ${props => (props.heightSize != null ? `${props.heightSize}px` : '162px')};
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 230px;
    border: 1px solid ${uploadBorderColor};
    border-radius: 8px;
    padding-top: 23px;
    > h4 {
      font-size: 18px;
      font-weight: 600;
      color: ${airblue};
      text-align: center;
    }
    > p {
      width: 87%;
      font-size: 14px;
      color: ${black};
      line-height: 1.5;
      margin-top: 12px;
      font-family: 'Hiragino Kaku Gothic Pro', sans-serif;
      > .annotation {
        font-size: 11px;
        ${props =>
          props.productType === PRODUCT_TYPE.PRODUCTLIST_INVOICE &&
          `
           display: inline-block;
           margin-top: 6px;
           line-height: 1.4;
           `}
      }
    }
  }
  > div:nth-of-type(1) {
    ${props => props.paddingTopSize != null && `padding-top: ${props.paddingTopSize}px`};
  }
  > div:nth-of-type(2) {
    ${props => props.widthSize.second != null && `width: ${props.widthSize.second}px`};
  }
  > div:nth-of-type(3) {
    ${props => props.widthSize.second != null && `width: ${props.widthSize.third}px`};
  }
`;
