import * as React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { connect } from 'react-redux';
import { State as ReduxState } from '../../../../modules';
import { StoresData } from '../../../../modules/user';
import { assertNotNull, assertUnreachable } from '../../../../helpers/util';
import { textLinkColor, black, lightgray } from '../../../../constants/colors';
import ZIndex from '../../../../constants/z-index';
import PayCalendarDetailContents from './PayCalendarDetailContents';
import {
  API_STATE_COMPLETED,
  API_STATE_FAILED,
  API_STATE_INITIAL,
  API_STATE_STARTED,
  ApiState,
} from '../../../../typedef/api/Utility';
import { TransferInfoDetail } from '../../../../typedef/api/Payment/TransferInfoDetail';
import Templates from '../../../../components/common/templates';
import { ActivityIndicator } from '../../../../components/common';
import ApiError from '../../../../components/common/templates/ApiError';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';
import { track } from '../../../../modules/logging';
import { airPayPaymentStopStoreList } from '../../../../selectors/paymentSelectors';

const modalRoot: HTMLElement = assertNotNull(document.getElementById('dialog-modal-root'));

type StateProps = {
  readonly stores: ReadonlyArray<StoresData>;
  readonly PaymentTransferInfoDetailState: ApiState<TransferInfoDetail>;
  readonly selectedCalendarDetailDate: string | null;
  readonly airPayStopStores: Array<StoresData>;
};

type PassedProps = {
  readonly onClose: () => void;
  readonly logging?: typeof track;
};

type Props = StateProps & PassedProps;

/**
 * 入金カレンダー詳細モーダル
 */
export class PayCalendarDetailModal extends React.PureComponent<Props> {
  el: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    if (modalRoot != null && modalRoot.hasChildNodes()) {
      modalRoot.removeChild(this.el);
    }
  }

  _renderDetailContents = () => {
    const { PaymentTransferInfoDetailState, stores, selectedCalendarDetailDate, logging, airPayStopStores } =
      this.props;
    switch (PaymentTransferInfoDetailState.type) {
      case API_STATE_INITIAL:
      case API_STATE_STARTED:
        return (
          <Templates.Center>
            <ActivityIndicator />
          </Templates.Center>
        );
      case API_STATE_FAILED:
        return <ApiError />;
      case API_STATE_COMPLETED:
        return (
          <PayCalendarDetailContents
            transferInfoDetail={PaymentTransferInfoDetailState.payload}
            stores={stores}
            logging={logging}
            selectedCalendarDetailDate={selectedCalendarDetailDate}
            airPayStopStores={airPayStopStores}
          />
        );
      default:
        assertUnreachable();
        return <React.Fragment />;
    }
  };

  _renderModal = () => {
    const { onClose, selectedCalendarDetailDate } = this.props;

    return (
      <BackGround>
        <div>
          <Overlay />
        </div>
        <ModalWrapper>
          <Header>
            <StyledClose onClick={onClose}>閉じる</StyledClose>
            <Title>
              {mclDayjs(selectedCalendarDetailDate, formatter.mapiDate).format(formatter.dateFixedWidth)}
              &nbsp;入金分
            </Title>
          </Header>
          <Content>
            <ModalContentWrapper>
              <ContentWrapper>{this._renderDetailContents()}</ContentWrapper>
            </ModalContentWrapper>
          </Content>
        </ModalWrapper>
      </BackGround>
    );
  };

  render() {
    return ReactDOM.createPortal(this._renderModal(), this.el);
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    stores: state.user.data != null ? state.user.data.stores : [],
    PaymentTransferInfoDetailState: state.payment.payment.PaymentTransferInfoDetailState,
    selectedCalendarDetailDate: state.payment.ui.selectedCalendarDetailDate,
    airPayStopStores: airPayPaymentStopStoreList(state),
  };
};

export default connect(mapStateToProps)(PayCalendarDetailModal);

const StyledClose = styled.div`
  cursor: pointer;
  left: 20px;
  position: absolute;
  color: ${textLinkColor};
`;

const Header = styled.div`
  background: white;
  align-items: center;
  border-bottom: 1px solid ${lightgray};
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-shrink: 0;
  height: 51px;
  width: 100%;
  justify-content: center;
  position: relative;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const Overlay = styled.div`
  animation: ${fadeIn} 0.25s forwards ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

const Title = styled.h2`
  color: ${black};
  font-size: 20px;
  font-weight: 600;
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const BackGround = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex: 1;
  justify-content: center;
  left: 0;
  min-height: 200px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${ZIndex.modalPortal};
`;

const ContentWrapper = styled.div`
  padding-bottom: 20px;
  height: calc(100% - 102px);
  position: relative;
  flex: 1;
  overflow-y: auto;
`;

const ModalWrapper = styled.div`
  background: white;
  animation: ${fadeIn} 0.5s forwards ease-in-out;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
`;

const Content = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  flex: 1;
  overflow-y: auto;
  position: relative;
`;

const ModalContentWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;
