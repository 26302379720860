import * as React from 'react';
import styled from 'styled-components';
import { attractFirst, attractSecond } from '../../../constants/colors';
import { formatNum, formatYen } from '../../../helpers/stringHelper';
import { MenuDetailResponseRanking } from './MenuTable';
import { uploadBorderColor } from '../../../constants/colors';
import firstPlace from '../../../icons/firstPlace.png';
import secondPlace from '../../../icons/secondPlace.png';
import thirdPlace from '../../../icons/thirdPlace.png';
import { SelectedType } from '../../../typedef/api/AllMenu';

type Props = {
  readonly menu?: MenuDetailResponseRanking;
  readonly index?: number;
  readonly isHiddenPredictTag: boolean;
  readonly style: any;
  readonly selectedType: SelectedType;
};

const rankByColor = {
  A: attractFirst,
  B: attractSecond,
  C: 'white',
};

const MenuTableRow = (props: Props) => {
  const { menu, index, style, isHiddenPredictTag, selectedType } = props;
  return menu != null && index != null ? (
    <Wrapper style={style}>
      <RankingColumn ranking={menu.ranking}></RankingColumn>
      <StyledTd width={selectedType !== 'category' ? 18 : 53}>
        <CategoryWrapper>
          <CategoryBox type={menu.colorCd} />
          <CategoryName>{menu.categoryName}</CategoryName>
        </CategoryWrapper>
      </StyledTd>
      {selectedType !== 'category' && (
        <StyledTd width={35}>
          <FlexWrapper>
            <MenuNameWrapper>{'menuName' in menu && menu.menuName}</MenuNameWrapper>
            {'isPredictAnalysisTagName' in menu && menu.isPredictAnalysisTagName && !isHiddenPredictTag && (
              <PredictIcon>予測</PredictIcon>
            )}
          </FlexWrapper>
        </StyledTd>
      )}
      <ThAnalysis background={rankByColor[menu.totalOrderAbc]}>
        {formatNum(menu.totalOrderNumRate, 1)}%<Sub>({menu.totalOrderNum})</Sub>
      </ThAnalysis>
      <ThAnalysis background={rankByColor[menu.totalSalesAbc]}>
        {formatNum(menu.totalSalesRate, 1)}%<Sub>({formatYen(menu.totalSales)})</Sub>
      </ThAnalysis>
      <ThAnalysis background={rankByColor[menu.totalGrossProfitAbc]}>
        {formatNum(menu.totalGrossProfitRate, 1)}%<Sub>({formatYen(menu.totalGrossProfit)})</Sub>
      </ThAnalysis>
    </Wrapper>
  ) : null;
};

export default MenuTableRow;

// ランキング1,2,3はアイコン, その他は数字を表示する
const RankingColumn = ({ ranking }: { ranking: number }) => {
  const Detail = () => {
    if (ranking === 1) {
      return <RankingIcon src={firstPlace} alt={'ランキング1位'} />;
    } else if (ranking === 2) {
      return <RankingIcon src={secondPlace} alt={'ランキング2位'} />;
    } else if (ranking === 3) {
      return <RankingIcon src={thirdPlace} alt={'ランキング3位'} />;
    } else {
      return <React.Fragment> {ranking}</React.Fragment>;
    }
  };
  return (
    <Ranking>
      <Detail />
    </Ranking>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const StyledTd = styled.div<{ width?: number }>`
  padding: 6px 12px;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid white;
  border-right: 1px solid white;
  ${props => props.width != null && `width:${props.width}%;`}
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Ranking = styled(StyledTd)`
  text-align: center;
  padding: 0px;
  width: 5%;
`;

const ThAnalysis = styled(StyledTd)<{ background?: string; isSum?: boolean }>`
  text-align: right;
  width: 14%;
  display: block;
  background-color: ${props => (props.background ? props.background : '#e6e6e6')};
  ${props =>
    props.isSum
      ? `
  border-left: none;
  border-right: none;
  `
      : ''};
`;

const Sub = styled.p`
  font-size: 12px;
`;

const CategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const CategoryBox = styled.div<{ type: string }>`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-color: #${props => props.type};
`;

const CategoryName = styled.p`
  vertical-align: middle;
  font-size: 14px;
  width: calc(100% - 15px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
`;

const FlexWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const PredictIcon = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: 9px;
  padding: 2px 6px;
  font-size: 9px;
  border: solid 1px ${uploadBorderColor};
  border-radius: 4px;
`;

const MenuNameWrapper = styled.p`
  max-width: calc(100% - 28px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 18px;
  word-break: break-all;
`;

const RankingIcon = styled.img`
  width: 32px;
  height: 32px;
`;
