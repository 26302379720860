import React from 'react';
import styled from 'styled-components';
import Modal from '../../../components/common/molecules/Airkit/AirModal';
import {
  airblue,
  airblueBorder,
  black,
  verylightgray,
  verylightgrayBorder,
  white,
} from '../../../constants/colors';
import MonthlySalesPredictedImage from '../../../icons/MonthlySalesPredictedImage.png';

type Props = {
  onClickShow: () => void;
  onClickNotShow: () => void;
};

const MonthlySalesPredictedModal: React.FC<Props> = props => {
  const { onClickShow, onClickNotShow } = props;

  const CustomButton = () => {
    return (
      <React.Fragment>
        <StyledButton isAirBlue={false} onClick={onClickNotShow}>
          今は設定しない
        </StyledButton>
        <Span />
        <StyledButton isAirBlue={true} onClick={onClickShow}>
          売上予測を表示する
        </StyledButton>
      </React.Fragment>
    );
  };

  return (
    <Modal
      title="売上の予測を表示しますか？"
      customFooter={{
        right: <CustomButton />,
      }}
    >
      <ModalWrapper>
        <ModalLead>全店舗一覧で月末売上予測が確認できるようになりました。</ModalLead>
        <Text>
          月末売上予測は、過去の売上から予測した今月末時点の月間売上です。
          <br />
          現時点の売上と比較することで、月の途中でも対策をとることができます。
        </Text>
        <img src={MonthlySalesPredictedImage} alt={''} width={640} />
        <SubText>※右上の「表の項目を編集」で後から変更することもできます。</SubText>
      </ModalWrapper>
    </Modal>
  );
};

const ModalLead = styled.div`
  font-size: 18px;
  color: ${black};
  margin: 32px 0 10px;
  font-weight: 600;
`;

const ModalWrapper = styled.div`
  padding: 0 80px 24px;
`;

const Text = styled.p`
  font-size: 18px;
  margin-bottom: 17px;
  font-weight: 300;
  line-height: 31px;
`;

const SubText = styled.p`
  font-size: 14px;
  color: ${black};
  font-weight: 300;
`;

const StyledButton = styled.button<{ isAirBlue: boolean }>`
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  font-size: 16px;
  height: 40px;
  min-width: 70px;
  margin: auto 0;
  padding: 0 16px;
  white-space: nowrap;
  background: ${props => (props.isAirBlue ? airblue : verylightgray)};
  box-shadow: inset 0 1px 0 0 ${props => (props.isAirBlue ? airblueBorder : verylightgrayBorder)},
    inset -1px 0 0 0 ${props => (props.isAirBlue ? airblueBorder : verylightgrayBorder)},
    inset 1px 0 0 0 ${props => (props.isAirBlue ? airblueBorder : verylightgrayBorder)},
    inset 0 -2px 0 0 ${props => (props.isAirBlue ? airblueBorder : verylightgrayBorder)};
  color: ${props => (props.isAirBlue ? white : black)};
  &:hover {
    cursor: pointer;
  }
`;

const Span = styled.span`
  margin-right: 8px;
`;

export default MonthlySalesPredictedModal;
