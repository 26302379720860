import * as React from 'react';
import styled from 'styled-components';
import { bgGray, black, lightgray, white } from '../../../../constants/colors';
import { PAYMENT_VIEW_TYPE } from '../transferConstants';

type Props = {
  readonly selectedViewType: keyof typeof PAYMENT_VIEW_TYPE;
  readonly changeListViewType: () => void;
  readonly changeCalendarViewType: () => void;
};

class Toggle extends React.Component<Props> {
  render() {
    const { selectedViewType, changeListViewType, changeCalendarViewType } = this.props;
    return (
      <ToggleStateless
        selectedViewType={selectedViewType}
        changeListViewType={changeListViewType}
        changeCalendarViewType={changeCalendarViewType}
      />
    );
  }
}

class ToggleStateless extends React.Component<Props> {
  render() {
    const { selectedViewType, changeListViewType, changeCalendarViewType } = this.props;
    return (
      <Row>
        <Item
          onClick={() => {
            if (selectedViewType !== 'list') {
              changeListViewType();
            }
          }}
          isSelected={selectedViewType === 'list'}
        >
          <Text>{PAYMENT_VIEW_TYPE.list}</Text>
        </Item>
        <Item
          onClick={() => {
            if (selectedViewType !== 'calendar') {
              changeCalendarViewType();
            }
          }}
          isSelected={selectedViewType === 'calendar'}
        >
          <Text>{PAYMENT_VIEW_TYPE.calendar}</Text>
        </Item>
      </Row>
    );
  }
}

const Row = styled.div`
  height: 44px;
  padding: 4px;
  background: ${bgGray};
  border-radius: 4px;
  display: flex;
  align-items: center;
`;
const Item = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-flow: column;
  padding: 4px;
  height: 36px;
  width: 108px;
  cursor: pointer;
  background-color: ${props => (props.isSelected ? white : bgGray)};
  border: ${props => (props.isSelected ? `solid 1px ${lightgray}` : white)};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: ${black};
`;

export default Toggle;
