import * as React from 'react';
import styled from 'styled-components';
import { baseFontSize, prefixUnit, LocaleInteger } from '../../../../components/common/atoms/Number';
import {
  uploadBorderColor,
  blueBg,
  textLinkColor,
  white,
  disabledTextColor,
  black,
} from '../../../../constants/colors';
import { formatter, mclDayjs } from '../../../../helpers/mclDate';
import { startFetchPaymentTransferInfoDetail } from '../../../../modules/payment/api';
import { TransferList } from '../../../../typedef/api/Payment/Calendar';
import { changeCalendarDetailDate } from '../../../../modules/payment/ui';

type Props = {
  readonly yearMonth: string;
  readonly transferInfoList: ReadonlyArray<TransferList>;
  readonly selectedStores: Set<string>;
  readonly showModal: () => void;
  readonly fetchPaymentTransferInfoDetail: typeof startFetchPaymentTransferInfoDetail;
  readonly changeCalendarDetailDate: typeof changeCalendarDetailDate;
};

const FormatYen = baseFontSize(14)(prefixUnit('¥')(LocaleInteger));

class CalendarContents extends React.Component<Props> {
  render() {
    const {
      transferInfoList,
      yearMonth,
      selectedStores,
      showModal,
      fetchPaymentTransferInfoDetail,
      changeCalendarDetailDate,
    } = this.props;
    let yearMonthDate = mclDayjs(yearMonth, formatter.mapiYearMonth);
    yearMonthDate = yearMonthDate.isValid() ? yearMonthDate : mclDayjs();

    const beginOfMonth = yearMonthDate.startOf('month');
    const endOfMonth = yearMonthDate.endOf('month');
    const firstRowCount = beginOfMonth.weekDay() - 1;
    const LastRowCount = 7 - endOfMonth.weekDay();

    const copyDataList: Array<
      | {
          transferDate: string;
          totalTransferPrice?: number | null;
          isPending: boolean;
          isWaiting: boolean;
          isPartiallySuspended: boolean;
          isStopped: boolean;
        }
      | undefined
    > = [...transferInfoList];

    for (let i = 0; i < firstRowCount; i++) {
      copyDataList.unshift(undefined);
    }

    for (let i = 0; i < LastRowCount; i++) {
      copyDataList.push(undefined);
    }

    return (
      <Wrapper>
        <TopPane>
          <TopPaneTitle>{yearMonthDate.format(formatter.yearMonthShortMonth)}</TopPaneTitle>
        </TopPane>
        <Calendar>
          <DaysCell>月</DaysCell>
          <DaysCell>火</DaysCell>
          <DaysCell>水</DaysCell>
          <DaysCell>木</DaysCell>
          <DaysCell>金</DaysCell>
          <DaysCell>土</DaysCell>
          <DaysCell>日</DaysCell>
          {copyDataList.map((item, index) => {
            if (item == null) {
              return <Cell key={`key_${index}`} hasTransferPrice={false} />;
            }
            const hasTransferDataFlag = item.isPending || item.isWaiting;
            return (
              <Cell
                key={item.transferDate}
                hasTransferPrice={item.totalTransferPrice != null ? true : hasTransferDataFlag}
                onClick={() => {
                  // 入金金額あり
                  if (item.totalTransferPrice != null) {
                    showModal();
                    fetchPaymentTransferInfoDetail(item.transferDate, [...selectedStores]);
                    changeCalendarDetailDate(item.transferDate);
                    // 詳細を確認
                  } else if (hasTransferDataFlag) {
                    showModal();
                    fetchPaymentTransferInfoDetail(item.transferDate, [...selectedStores]);
                    changeCalendarDetailDate(item.transferDate);
                  }
                }}
              >
                <Day hasTransferPrice={item.totalTransferPrice != null ? true : hasTransferDataFlag}>
                  {mclDayjs(item.transferDate, formatter.mapiDate).date()}
                </Day>
                <div>
                  {item.totalTransferPrice != null ? (
                    <React.Fragment>
                      <FormatYen value={item.totalTransferPrice} />
                      {hasTransferDataFlag && <span>~</span>}
                    </React.Fragment>
                  ) : (
                    hasTransferDataFlag && <DetailButton>詳細を確認</DetailButton>
                  )}
                  {item.isPartiallySuspended && (
                    <TransferStatusWrapper isStopped={false}>一部振込停止</TransferStatusWrapper>
                  )}
                  {item.isStopped && <TransferStatusWrapper isStopped={true}>振込停止</TransferStatusWrapper>}
                </div>
              </Cell>
            );
          })}
        </Calendar>
      </Wrapper>
    );
  }
}

export default CalendarContents;

const Wrapper = styled.div`
  margin-top: 24px;
  width: 100%;
`;

const TopPane = styled.div`
  display: flex;
  align-items: center;
  width: 777px;
`;

const TopPaneTitle = styled.p`
  margin-right: 25px;
  font-size: 16px;
  font-weight: 600;
`;

const Calendar = styled.div`
  width: 777px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const DaysCell = styled.div`
  width: calc(777px / 7);
  font-size: 12px;
  text-align: center;
  border-bottom: solid 1px ${uploadBorderColor};
`;

const Day = styled.p<{ hasTransferPrice: boolean }>`
  text-align: left;
  line-height: 1;
  font-weight: normal;
  font-size: 14px;
  ${props => props.hasTransferPrice && 'text-decoration: underline; font-weight: 600;'};
  position: absolute;
  top: 6px;
  left: 6px;
`;

const Cell = styled(DaysCell)<{ hasTransferPrice: boolean }>`
  position: relative;
  min-height: 54px;
  padding: 20px 8px 6px;
  font-size: 16px;
  font-weight: 600;
  border-left: solid 1px ${uploadBorderColor};
  color: ${props => (props.hasTransferPrice ? textLinkColor : black)};
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.hasTransferPrice && 'cursor: pointer;'}
  :nth-child(7n) {
    border-right: solid 1px ${uploadBorderColor};
  }
  :hover {
    ${props => props.hasTransferPrice && `background-color: ${blueBg};`}
  }
`;

const TransferStatusWrapper = styled.div<{ isStopped: boolean }>`
  width: ${props => (props.isStopped ? 56 : 80)}px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  background: ${white};
  color: ${disabledTextColor};
  border: 1px solid ${uploadBorderColor};
  margin: 0 auto;
`;

const DetailButton = styled.button`
  font-size: 14px;
  font-weight: 400;
  margin: 0 auto;
  border: none;
  background: none;
  color: ${textLinkColor};
  cursor: pointer;
`;
