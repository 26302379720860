/**
 * 商品分析画面のテーブルエリア
 */
import * as React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { AutoSizer, Table, WindowScroller, Column } from '../../../components/common/atoms/ReactVirtualized';
import 'react-virtualized/styles.css';
import './_virtualized.css';
import MenuTableRow from './MenuTableRow';
import FilterModal from './FilterModal';
import { State as SearchState } from '../../../modules/allMenu/search';
import {
  MenuCategoryDetailResponse,
  MenuDetailResponse,
  SelectedType,
  Summary,
  TableProperties,
} from '../../../typedef/api/AllMenu';
import { Logger } from '../../../typedef/logger';
import { genGaLog } from '../../../gaLogger';
import { eqs, eqn } from '../../../helpers/util';
import { gray } from '../../../constants/colors';
import zIndex from '../../../constants/z-index';
import { formatNum, formatYen } from '../../../helpers/stringHelper';
import ArrowDownIcon from '../../../icons/arrow_down_blue.svg';
import { State as ReduxState } from '../../../modules';
import { connect } from 'react-redux';

type StateProps = {
  readonly isSearchedAllAnalysisTags: boolean;
  readonly sortBy: 'totalOrderNum' | 'totalSales' | 'totalGrossProfit';
};

type Props = Readonly<
  {
    readonly result: ReadonlyArray<MenuDetailResponse | MenuCategoryDetailResponse>;
    readonly searchState: SearchState;
    readonly setTableProperties: (tableProperties: TableProperties) => any;
    readonly logger: (logger: Logger) => any;
    readonly selectedType: SelectedType;
    readonly setTempSelectedItem: (items: Set<string>) => void;
  } & StateProps
>;

// ランキング付与後の型
export type MenuDetailResponseRanking = (MenuDetailResponse | MenuCategoryDetailResponse) & {
  ranking: number;
};
type MenuDetailResponseRankingList = MenuDetailResponseRanking[];

class MenuTable extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.setTempSelectedItem(new Set(this.props.searchState.tableProperties.filter));
  }

  // search table headerのsort
  _onSortChange = (sortBy: 'totalOrderNum' | 'totalSales' | 'totalGrossProfit') => {
    const { searchState, setTableProperties } = this.props;

    if (eqs(sortBy, searchState.tableProperties.sortBy)) {
      setTableProperties({
        ...searchState.tableProperties,
        sortReverse: !searchState.tableProperties.sortReverse,
      });
    } else {
      setTableProperties({ ...searchState.tableProperties, sortReverse: false, sortBy });
    }

    this.props.logger(_genSortLog(sortBy));
  };

  // ソート済みのデータから選択中のソートキーを元にランキングを算出する
  _calcRanking = (
    sortedTableData: ReadonlyArray<MenuDetailResponse | MenuCategoryDetailResponse>
  ): MenuDetailResponseRankingList => {
    const { sortBy } = this.props;
    const result: MenuDetailResponseRankingList = [];
    sortedTableData.forEach((item, index) => {
      if (result.length >= 1 && result[index - 1][sortBy] === item[sortBy]) {
        result.push({ ...item, ranking: result[index - 1].ranking });
      } else {
        result.push({ ...item, ranking: index + 1 });
      }
    });
    return result;
  };

  render() {
    const {
      result,
      setTableProperties,
      searchState,
      isSearchedAllAnalysisTags,
      selectedType,
      setTempSelectedItem,
    } = this.props;
    const { sortBy, sortReverse, filter, sumTotalGrossProfit, sumTotalOrderNum, sumTotalSales } =
      searchState.tableProperties;

    const tableArray: Array<MenuDetailResponse | MenuCategoryDetailResponse> = _.orderBy(
      result.filter(record => {
        return filter.includes(record.categoryName);
      }),
      [sortBy, 'menuName', 'categoryName', 'analysisTagName'],
      [
        sortReverse ? 'asc' : 'desc',
        sortReverse ? 'desc' : 'asc',
        sortReverse ? 'desc' : 'asc',
        sortReverse ? 'desc' : 'asc',
      ]
    );

    // テーブルデータにランキングを付与
    const rankingTableArray: MenuDetailResponseRankingList = this._calcRanking(tableArray);

    let summary: Summary = _.reduce(
      tableArray,
      (sum, record) => {
        return {
          ...sum,
          totalOrderNum: sum.totalOrderNum + record.totalOrderNum,
          totalSales: sum.totalSales + record.totalSales,
          totalGrossProfit: sum.totalGrossProfit + record.totalGrossProfit,
        };
      },
      {
        totalOrderNumRate: 100,
        totalOrderNum: 0,
        totalSalesRate: 100,
        totalSales: 0,
        totalGrossProfitRate: 100,
        totalGrossProfit: 0,
      }
    );

    // フィルターがかかっている場合、構成比は元の合計値から計算する
    if (!eqn(tableArray.length, result.length)) {
      summary = {
        ...summary,
        totalOrderNumRate: (summary.totalOrderNum / sumTotalOrderNum) * 100,
        totalSalesRate: (summary.totalSales / sumTotalSales) * 100,
        totalGrossProfitRate: (summary.totalGrossProfit / sumTotalGrossProfit) * 100,
      };
    }

    return (
      <WindowScroller scrollElement={document.getElementById('scrollable') as Element}>
        {({ height, scrollTop, onChildScroll, registerChild, isScrolling }) =>
          height != null && (
            <TableContainer id="table_wrapper">
              <AutoSizer disableHeight>
                {({ width }) => (
                  <div
                    style={{ width: '100%', minWidth: '100%' }}
                    ref={registerChild as React.LegacyRef<HTMLDivElement>}
                  >
                    <Table
                      id="menu_analysis_table"
                      autoHeight
                      width={width}
                      height={height}
                      headerHeight={56}
                      rowGetter={({ index }) => rankingTableArray[index]}
                      rowHeight={60}
                      rowCount={rankingTableArray.length}
                      rowStyle={{ width: '100%' }}
                      scrollTop={scrollTop}
                      onScroll={onChildScroll}
                      isScrolling={isScrolling}
                      rowRenderer={({ rowData, index, style }) => (
                        <MenuTableRow
                          menu={rowData}
                          index={index}
                          key={index}
                          style={style}
                          isHiddenPredictTag={isSearchedAllAnalysisTags}
                          selectedType={selectedType}
                        />
                      )}
                      headerRowRenderer={() => [
                        <div
                          style={{ display: 'flex', position: 'sticky', top: 0, zIndex: zIndex.menuTable }}
                          key="1"
                        >
                          <StyledTh width={5} role="row" />
                          <StyledTh width={selectedType !== 'category' ? 18 : 53} role="row">
                            <Category>カテゴリー</Category>
                            {selectedType !== 'category' && (
                              <FilterModal
                                selectedItems={new Set(filter)}
                                options={
                                  new Map(
                                    (searchState.searchCondition.includesUndefinedCategory
                                      ? searchState.searchCondition.selectedCategories.concat(
                                          'カテゴリー未設定'
                                        )
                                      : searchState.searchCondition.selectedCategories
                                    ).map(categories => {
                                      return [categories, categories];
                                    })
                                  )
                                }
                                onChange={items => {
                                  setTableProperties({
                                    ...searchState.tableProperties,
                                    filter: Array.from(items),
                                  });
                                  this.props.logger(_genFilterLog());
                                }}
                                setTempSelectedItem={setTempSelectedItem}
                              />
                            )}
                          </StyledTh>
                          {selectedType !== 'category' && (
                            <StyledTh width={35} role="row">
                              商品名
                            </StyledTh>
                          )}
                          <SortableTh onClick={() => this._onSortChange('totalOrderNum')} role="row">
                            <SortableThInner>
                              <p>
                                出数構成比
                                <Sub>(出数合計)</Sub>
                              </p>
                              <IconWrapper reverse={sortReverse}>
                                {sortBy === 'totalOrderNum' ? <ArrowDownIcon /> : null}
                              </IconWrapper>
                            </SortableThInner>
                          </SortableTh>
                          <SortableTh
                            onClick={() => this._onSortChange('totalSales')}
                            role="row"
                            id="menu_analysis_header_total_sales"
                          >
                            <SortableThInner>
                              <p>
                                売上構成比
                                <Sub>(売上合計)</Sub>
                              </p>
                              <IconWrapper reverse={sortReverse}>
                                {sortBy === 'totalSales' ? <ArrowDownIcon /> : null}
                              </IconWrapper>
                            </SortableThInner>
                          </SortableTh>
                          <SortableTh onClick={() => this._onSortChange('totalGrossProfit')} role="row">
                            <SortableThInner>
                              <p>
                                粗利構成比
                                <Sub>(粗利合計)</Sub>
                              </p>
                              <IconWrapper reverse={sortReverse}>
                                {sortBy === 'totalGrossProfit' ? <ArrowDownIcon /> : null}
                              </IconWrapper>
                            </SortableThInner>
                          </SortableTh>
                        </div>,
                      ]}
                    >
                      <Column width={5} dataKey="index" />
                      <Column width={18} dataKey="categoryName" />
                      <Column width={35} dataKey="menuName" />
                      <Column width={14} dataKey="totalOrderNum" />
                      <Column width={14} dataKey="totalSales" />
                      <Column width={14} dataKey="totalGrossProfit" />
                    </Table>
                  </div>
                )}
              </AutoSizer>
              {tableArray.length < 1 ? (
                <NoData>
                  該当するデータがありません。
                  <br />
                  条件を変更してください。
                </NoData>
              ) : (
                <div style={{ width: '100%', display: 'flex' }}>
                  <StyledSum width={58}>合計</StyledSum>
                  <StyledSum width={14}>
                    {formatNum(summary.totalOrderNumRate, 1)}%<Sub>({summary.totalOrderNum})</Sub>
                  </StyledSum>
                  <StyledSum width={14}>
                    {formatNum(summary.totalSalesRate, 1)}%<Sub>({formatYen(summary.totalSales)})</Sub>
                  </StyledSum>
                  <StyledSum width={14}>
                    {formatNum(summary.totalGrossProfitRate, 1)}%
                    <Sub>({formatYen(summary.totalGrossProfit)})</Sub>
                  </StyledSum>
                </div>
              )}
            </TableContainer>
          )
        }
      </WindowScroller>
    );
  }
}

const mapStateToProps = (state: ReduxState): StateProps => {
  return {
    isSearchedAllAnalysisTags: state.allMenu.search.searchCondition.isSearchedAllAnalysisTags,
    sortBy: state.allMenu.search.tableProperties.sortBy,
  };
};

export default connect(mapStateToProps)(MenuTable);

const _genSortLog = (sortItem: string) => {
  return genGaLog(
    'menu_analysis',
    'all_menu_search',
    'sort_table',
    { sortItem: sortItem + 'Rate' },
    {},
    'click'
  );
};

const _genFilterLog = () => {
  return genGaLog('menu_analysis', 'all_menu_search', 'flter_categories', {}, {}, 'click');
};

const TableContainer = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const StyledTh = styled.div<{ width?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 5px;
  background-color: ${gray};
  font-weight: 600;
  ${props => props.width != null && `width:${props.width}%`}
`;

const SortableTh = styled(StyledTh)`
  color: #0892c7;
  cursor: pointer;
  padding: 10px 0;
  width: 14%;
`;

const SortableThInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Sub = styled.span`
  font-size: 10px;
  display: block;
`;

const IconWrapper = styled.div<{ reverse: boolean }>`
  width: 12px;
  margin-left: 5px;
  ${props => (props.reverse ? 'transform:rotate(180deg);' : '')}
`;

const NoData = styled.div`
  text-align: center;
  margin: 80px 0;
`;

const Category = styled.p`
  display: inline-block;
  line-height: 22px;
  vertical-align: middle;
`;

const StyledSum = styled.div<{ background?: string; width?: number }>`
  padding: 6px 12px;
  border-bottom: 1px solid #ddd;
  ${props => props.width != null && `width:${props.width}%;`}
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => (props.background ? props.background : '#e6e6e6')};
`;
