// 訴求モーダルで使用する定数

// 各プロダクトを判別する定数(画面名_プロダクト名)
export const PRODUCT_TYPE = {
  MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_MODAL_SHIFT: 'MONTHLY_LOOKBACK_LABOR_COST_ANALYSIS_MODAL_SHIFT',
  DAILYREPORT_INPUT_MODAL_SHIFT: 'DAILYREPORT_INPUT_MODAL_SHIFT',
  MENU_ANALYSIS_REGI: 'MENU_ANALYSIS_REGI',
  LABORCOSTANALYSIS_SHIFT: 'LABORCOSTANALYSIS_SHIFT',
  LABORCOSTANALYSIS_OES: 'LABORCOSTANALYSIS_OES',
  DAILYREPORT_CARD: 'DAILYREPORT_CARD',
  DAILYREPORT_SHIFT: 'DAILYREPORT_SHIFT',
  PRODUCTLIST_CARD: 'PRODUCTLIST_CARD',
  PRODUCTLIST_SHIFT: 'PRODUCTLIST_SHIFT',
  PRODUCTLIST_OES: 'PRODUCTLIST_OES',
  PRODUCTLIST_RB: 'PRODUCTLIST_RB',
  PRODUCTLIST_REGI: 'PRODUCTLIST_REGI',
  PRODUCTLIST_INVOICE: 'PRODUCTLIST_INVOICE',
  PRODUCTLIST_PAY: 'PRODUCTLIST_PAY',
  PRODUCTLIST_MPA: 'PRODUCTLIST_MPA',
  DAYOFWEEKHOURLY_REGI_USE: 'DAYOFWEEKHOURLY_REGI_USE',
  DAYOFWEEKHOURLY_REGI_NOT_USE: 'DAYOFWEEKHOURLY_REGI_NOT_USE',
  STORE_CALENDAR_INDICES_CARD: 'STORE_CALENDAR_INDICES_CARD',
  STORE_CALENDAR_INDICES_SHIFT: 'STORE_CALENDAR_INDICES_SHIFT',
  STORE_CALENDAR_INDICES_OES: 'STORE_CALENDAR_INDICES_OES',
  STORE_CALENDAR_INDICES_RB: 'STORE_CALENDAR_INDICES_RB',
  STORE_CALENDAR_INDICES_REGI: 'STORE_CALENDAR_INDICES_REGI',
  STORE_CALENDAR_INDICES_REGI_PAYQR: 'STORE_CALENDAR_INDICES_REGI_PAYQR',
  // カレンダーのサマリーに表示される導線のモーダル
  STORE_CALENDAR_INDICES_SHIFT_SUMMARY: 'STORE_CALENDAR_INDICES_SHIFT_SUMMARY',
  STORE_CALENDAR_INDICES_CARD_SUMMARY: 'STORE_CALENDAR_INDICES_CARD_SUMMARY',
  STORE_CALENDAR_INDICES_CASH: 'STORE_CALENDAR_INDICES_CASH',
  REALTIME_REGI: 'REALTIME_REGI',
  REALTIME_OES: 'REALTIME_OES',
  REALTIME_SHIFT: 'REALTIME_SHIFT',
  REALTIME_RB: 'REALTIME_RB',
  STORE_MONTHLY_INDICES_SHIFT: 'STORE_MONTHLY_INDICES_SHIFT',
  STORE_MONTHLY_INDICES_OES: 'STORE_MONTHLY_INDICES_OES',
  STORE_INDICES_SHIFT: 'STORE_INDICES_SHIFT',
  STORE_INDICES_OES: 'STORE_INDICES_OES',
  DAILYDETAILPAST_REGI: 'DAILYDETAILPAST_REGI',
  DAILYDETAILPAST_OES: 'DAILYDETAILPAST_OES',
  DAILYDETAILPAST_SHIFT: 'DAILYDETAILPAST_SHIFT',
  DAILYDETAILPAST_RB: 'DAILYDETAILPAST_RB',
  DAILYDETAILFUTURE_SHIFT: 'DAILYDETAILFUTURE_SHIFT',
  DAILYDETAILFUTURE_RB: 'DAILYDETAILFUTURE_RB',
  DAILYREPORT_EMPTYSTATE_REGI: 'DAILYREPORT_EMPTYSTATE_REGI',
  MONTHLYLOOKBACK_EMPTYSTATE_REGI: 'MONTHLYLOOKBACK_EMPTYSTATE_REGI',
};
