import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { attentionRed, navigationHoverColor, navigationSelectedColor } from '../../constants/colors';
import { Logger } from '../../typedef/logger';
import SvgModule from '../../helpers/svgModule';
import Blank from '../../icons/blank.svg';
import { genSideBarLogger } from '../../gaLogger';
type Class<T> = new (...args: any) => T;
type Props = {
  readonly title: string;
  readonly id: string;
  readonly folded: boolean;
  readonly img?: Class<SvgModule>;
  readonly linkTo?: string;
  readonly selected?: boolean;
  readonly logging: (a: Logger) => any;
  readonly onClick?: () => void;
  readonly onMouseEnter?: () => void;
  readonly onMouseLeave?: () => void;
  readonly unreadCount?: number;
  readonly isLastNavItem?: boolean;
};

const SideNavItem = (props: Props) => {
  const { title, id, logging, unreadCount, onClick, linkTo, folded, isLastNavItem } = props;
  const Img = props.img;
  return (
    <React.Fragment>
      {linkTo != null ? (
        <Link
          to={linkTo}
          onClick={() => {
            const log = genSideBarLogger(title);

            logging(log);
            onClick && onClick();
          }}
        >
          <Wrapper
            selected={props.selected}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            noImage={!Img}
            folded={folded}
            id={id}
            isLastNavItem={isLastNavItem}
          >
            {Img != null ? (
              <Img
                style={{
                  verticalAlign: 'middle',
                  margin: '0 12px',
                  flexShrink: '0',
                }}
                height={24}
                width={24}
              />
            ) : (
              <Blank
                style={{
                  verticalAlign: 'middle',
                  margin: '0 12px',
                  flexShrink: '0',
                }}
                height={24}
                width={24}
              />
            )}
            {folded ? <FormatText title={title} /> : <Text>{title}</Text>}
            {unreadCount != null && unreadCount > 0 && !folded && <Badge>{unreadCount}</Badge>}
          </Wrapper>
        </Link>
      ) : (
        <Wrapper
          selected={props.selected}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          noImage={!Img}
          folded={props.folded}
          id={id}
          onClick={() => {
            onClick && onClick();
          }}
        >
          {Img != null ? (
            <Img
              style={{
                verticalAlign: 'middle',
                margin: '0 12px',
                flexShrink: '0',
              }}
              height={24}
              width={24}
            />
          ) : (
            <Blank
              style={{
                verticalAlign: 'middle',
                margin: '0 12px',
                flexShrink: '0',
              }}
              height={24}
              width={24}
            />
          )}
          {props.folded ? <FormatText title={props.title} /> : <Text>{props.title}</Text>}
        </Wrapper>
      )}
    </React.Fragment>
  );
};

export const FormatText = ({ title }) => {
  switch (title) {
    case '成績':
      return <FoldText>成績</FoldText>;
    case '全店舗一覧':
      return (
        <FoldText>
          全店舗
          <br />
          一覧
        </FoldText>
      );
    case 'リアルタイム':
      return (
        <FoldText>
          リアル
          <br />
          タイム
        </FoldText>
      );
    case '日報':
      return <FoldText>日報</FoldText>;
    case '収支・入金情報':
      return (
        <FoldText>
          収支・
          <br />
          入金情報
        </FoldText>
      );
    case '月次レポート':
      return (
        <FoldText>
          月次
          <br />
          レポート
        </FoldText>
      );
    case '目標設定':
      return <FoldText>目標設定</FoldText>;
    case 'コスト管理':
      return (
        <FoldText>
          コスト
          <br />
          管理
        </FoldText>
      );
    case 'キャッシュフロー':
      return (
        <FoldText>
          キャッシ
          <br />
          ュフロー
        </FoldText>
      );
    case '分析':
      return <FoldText>分析</FoldText>;
    case '設定':
      return <FoldText>設定</FoldText>;
    case 'データ入出力':
      return (
        <FoldText>
          データ
          <br />
          入出力
        </FoldText>
      );
    case 'アプリ版のご案内':
      return (
        <FoldText>
          アプリ版
          <br />
          のご案内
        </FoldText>
      );
    case '連携サービス':
      return (
        <FoldText>
          連携
          <br />
          サービス
        </FoldText>
      );
    default:
      return <FoldText>nonetitle</FoldText>;
  }
};

const Wrapper = styled.div<{
  selected?: boolean;
  noImage: boolean;
  folded: boolean;
  isLastNavItem?: boolean;
}>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  overflow: hidden;
  color: white;
  cursor: pointer;
  ${props => props.isLastNavItem && 'margin-bottom:30px'};
  ${props => props.folded && 'flex-direction:column; justify-content:center;'};
  height: ${props => (props.folded ? '70' : props.noImage ? '40' : '52')}px;
  font-size: ${props => (props.folded ? '10px' : '16px')};
  line-height: ${props => (props.folded ? '12px' : '52px')};
  ${props => props.folded && 'width:48px'};
  ${props =>
    props.selected
      ? `
    font-weight: 600;
    background-color: ${navigationSelectedColor};
  `
      : ''}
  :hover {
    ${props => (props.selected ? '' : `background-color: ${navigationHoverColor}`)};
  }
`;

const Text = styled.span`
  vertical-align: middle;
  white-space: nowrap;
`;
const Badge = styled.p`
  margin-left: 4px;
  min-width: 18px;
  height: 18px;
  line-height: 18px;
  padding: 0 4px;
  background-color: ${attentionRed};
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
`;

const FoldText = styled.div`
  text-align: center;
  margin-top: 4px;
`;
export default SideNavItem;
